import {
    TOGGLE_NUMBER,
    INCREMENT_NUMBER,
    DECREMENT_NUMBER,
    SET_DEFAULT_NUMBER,
    SET_DEFAULT_VALUE
} from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

export const initialState = {
    id: 'sources',
    name: 'sources',
    label: 'Sources',
    tooltip: 'Specify the number of sources to be used (if any)',
    value: '0',
    error: '',
    max: 100,
    min: 0,
    isRequired: false
}

export default function sourcesReducer(state = initialState, { type, payload }) {
    switch (type) {
        case TOGGLE_NUMBER + '_' + state.id:
            return {
                ...state,
                value: payload
            };
        case INCREMENT_NUMBER + '_' + state.id:
            return {
                ...state,
                value: String(payload)
            };
        case DECREMENT_NUMBER + '_' + state.id:
            return {
                ...state,
                value: String(payload)
            };
        case SET_DEFAULT_NUMBER + '_' + state.id:
            if (state.value === '') {
                return {
                    ...state,
                    value: String(state.min)
                }
            } else {
                return state;
            }
        case SET_DEFAULT_VALUE:
            if (payload) {
                return {
                    ...state,
                    value: payload.sources
                }
            } else {
                return state;
            }
        case CLEAR_ORDERFORM:
            return {
                ...state,
                value: payload.sources
            };
        default:
            return state;
    }
}