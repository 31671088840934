import Axios from 'axios';
import { tokenService } from '../services/tokenService';
import { authService } from '../services/authService';
import { notify } from '../vendor/Notification';

// Axios instance
export const axios = Axios.create({ baseURL: '/api' });

// Interceptor callbacks
function requestHandler(request) {
  const { method, url } = request;
  loggerEnabled && console.log(`%c⚡️Request [${method}] ${url}`, styledLog.request);
  return request;
}

function successHandler(request) {
  loggerEnabled && console.log('%c⚡️Response ' + request.status, styledLog.response, request);
  return request;
}

function errorHandler(error) {
  const { response, config } = error;
  loggerEnabled && console.log('%c⚡️Axios [resp] Failure', styledLog.error, error.message);

  if (!response) {
    console.log('⚡️Got error without response!');
    return error;
  }

  const { status } = response;

  // Catch auth errors
  if (status === 401) {
    console.error('⚡️Got error with 401');

    // 404 On refresh token
    if (axios._refresingToken) {
      console.error('⚡️Still refreshing...');
      tokenService.clearTokens();
    }

    // Preserve 401 error loop. Set flag
    axios._refresingToken = true;

    // Request new accessToken (refresh)
    return authService
      .refresh()

      .then((data) => {
        console.log('⚡️AccessToken refreshed. Retrying last query...', data);
        const { accessToken } = data;

        axios._refresingToken = false;

        // Update Authorization header with new accessToken
        config.headers['Authorization'] = 'Bearer ' + accessToken;

        // Wait for request complete...
        return axios.request(config);
      })

      .catch((error) => {
        console.log('⚡️Failed UPD AccessToken');
        tokenService.clearTokens();
        window.location.reload(); // or redirect to Login page
        return error;
      });
  } else if (status === 422) {
    console.log('⚡️Authorization required');
  } else if (status >= 400) {
    const { message } = error;
    notify.hide();
    notify.error({ title: 'Server error', message });
  }
  return Promise.reject(error);
}

// Logger
const loggerEnabled = true;
const styledLog = {
  request: 'background:yellow;color:black',
  response: 'background:green;color:#fff;',
  error: 'background:darkred;color:#fff;',
};

// Register interceptors
axios.interceptors.request.use(requestHandler, function(error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
