import { SET_DEFAULT_VALUE } from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

import reducerLogic from 'store/orderform/textfield/textFieldReducer';

export const initialState = {
    id: 'credentials',
    type: 'text',
    value: '',
    error: '',
    placeholder: 'Please put your personal and login details here.',
    label: 'Login Details',
    tooltip: 'Please put your personal and login details here.',
    isRequired: false,
    isFocused: false
}

export default function credentialsReducer(state = initialState, action) {

    if (action.type === CLEAR_ORDERFORM) {
        return {
            ...state,
            value: action.payload.credentials
        };

    } else if (action.type === SET_DEFAULT_VALUE) {
        return {
            ...state,
            value: action.payload.credentials || initialState.value
        };

    } else {
        return reducerLogic(state, action);
    }
}