import React from 'react';
import PropTypes from 'prop-types';

import FormField from './FormField';
import Label from '../Label';

/**
 * @name FormGroup
 * @component
 * @category Orderform
 * @subcategory Formfield
 * @hideconstructor
 */
export default function FormGroup({ data, handleChange, handleBlur, handleFocus }) {
    const errorClass = data.error ? 'error' : '';
    const doneClass = data.value && !data.error && !data.isFocused ? 'done' : '';

    return (
        <div className="formField__group">
            <Label
                label={data.label}
                tooltip={data.tooltip}
                error={data.error}
                isRequired={data.isRequired}
            />
            <FormField
                className={`formField__input ${errorClass} ${doneClass}`}
                id={data.id}
                type={data.type}
                name={data.id}
                error=''
                placeholder={data.placeholder}
                value={data.value}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleFocus={handleFocus}
            />
        </div>
    )
}

FormGroup.propTypes = {
    data: PropTypes.shape({
        label: PropTypes.string,
        tooltip: PropTypes.string,
        error: PropTypes.string,
        value: PropTypes.string,
        isFocused: PropTypes.bool,

    })
}