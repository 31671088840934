// Use antd "message" as notifications
// Read more: https://ant.design/components/message/
import { message as _msg, notification as _nt } from 'antd';

// Configure message & notification
_msg.config({ maxCount: 1 });
_nt.config({ duration: 8, top: document.body.clientWidth <= 767 ? 70 : 10 })

/**
 * @typedef {Object} notifyMethods
 * @property {metnod} error
 * @property {metnod} success
 * @property {metnod} warn
 * @property {metnod} info
 * @property {metnod} wait
 * @property {metnod} update
 * @property {metnod} hide
 * @property {metnod} isVisible
 */

/**
 * @function notify
 * @returns {notifyMethods}
 */

// Notify fallback, coz backward compatibility with other products
export const notify = (function () {
  // Cleanup from HTML tags
  const removeTags = (str) => {
    if (Array.isArray(str)) str = str.join(',');
    return str?.replace(/<[^>]*>?/gm, '') || '';
  };



  function error(description, title) {
    console.log('[ERROR!]', description);
    if (typeof window.showErrNotify !== 'undefined') {
      window.showErrNotify(description, title);
    } else {
      console.log('notify_error', description);
      _msg.error(removeTags(description));
    }
  }

  function success(description, title) {
    console.log('[SUCCESS!]', description);
    if (typeof window.showSuccessNotify !== 'undefined') {
      window.showSuccessNotify(description, title);
    } else {
      console.log('notify_success', description);
      _msg.success(removeTags(description));
    }
  }

  function warn(description, title) {
    console.log('[WARN!]', description);
    if (typeof window.showWarnNotify !== 'undefined') {
      window.showWarnNotify(description, title);
    } else {
      console.log('notify_warn', description);
      _msg.warn(removeTags(description));
    }
  }

  function info(description) {
    console.log('[INFO!]', description);
    if (typeof window.showInfoNotify !== 'undefined') {
      window.showInfoNotify(description);
    } else {
      console.log('notify_info', description);
      _msg.info(removeTags(description));
    }
  }

  function wait(description) {
    console.log('[WAIT!]', description);
    if (typeof window.showWaitNotify !== 'undefined') {
      window.showWaitNotify(description);
    } else {
      console.log('notify_wait', description);
      _msg.loading(removeTags(description));
    }
  }

  function update(text) {
    // updNotify(text);
  }

  function hide() {
    if (typeof window.showWaitNotify !== 'undefined') {
      window.hideNotify();
    } else {
      console.log('notify_hide');
      // _msg.close();
    }
  }

  function isVisible() {
    // isNotifyVisible();
  }

  return {
    error,
    success,
    warn,
    info,
    wait,
    update,
    hide,
    isVisible,
  };
})();


// Notifications
export const notification = _nt
