import React from 'react';
import PropTypes from 'prop-types';

import ArrowDown from '../components/img/ArrowDown';
import PaymentwallIcon from '../components/img/PaymentwallIcon';
import PaycoreIcon from '../components/img/PaycoreIcon';
import PaypalIcon from '../components/img/PaypalIcon';
import FondyIcon from '../components/img/FondyIcon';
import StripeIcon from 'dashboard/components/img/StripeIcon';

const icons = {
    paymentwall: PaymentwallIcon,
    paycore: PaycoreIcon,
    paypal: PaypalIcon,
    fondy: FondyIcon,
    stripe: StripeIcon,

}

/**
 * @name PaymentSelect
 * @component
 * @category Dashboard
 * @subcategory PaymentModal
 * @hideconstructor
 * @memberof PaymentModal
 */
export default function PaymentSelect({ id, isOpened, options, value, placeholder, hanleDropdownToggle, handleSlect, isInteract }) {
    const defaultHolder = placeholder ? placeholder : '';
    const Icon = icons[value];

    return (
        <div className={`payment-select__group ${isInteract ? 'is-interact' : ''}`}>
            <div
                className={`payment-select__output ${isOpened ? 'is-opened' : ''}`}
                id={id}
                onClick={hanleDropdownToggle}
            >
                <div className="payment-select__column">
                    {value && <Icon />}
                </div>
                <div className="payment-select__column">
                    <h5>
                        {options.find(item => item.id === value)
                            ? options.find(item => item.id === value).name
                            : defaultHolder
                        }
                    </h5>
                    <span>Continue using debit or credit card</span>
                </div>
                <div className="payment-select__column">
                    <ArrowDown />
                </div>
            </div>

            <ul className={`payment-select__dropdown ${isOpened ? 'is-opened' : ''}`}>
                {options.map(((item, index) => {
                    const OptionIcon = icons[item.id];

                    return (
                        <li
                            id={item.id}
                            className={`payment-select__option ${item.id === value ? 'is-highlighted' : ''}`}
                            key={index}
                            onClick={handleSlect.bind(null, item.id)}
                        >
                            <div className="option__left">
                                <OptionIcon />
                            </div>
                            <div className="option__right">
                                <div className="title">{item.name}</div>
                                <span>Continue using debit or credit card</span>
                            </div>

                        </li>
                    )
                }))}
            </ul>
        </div>
    )
}

PaymentSelect.propTypes = {
    id: PropTypes.string,
    isOpened: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    })),
    value: PropTypes.string,
    hanleDropdownToggle: PropTypes.func,
    handleSlect: PropTypes.func,
    isInteract: PropTypes.bool
}