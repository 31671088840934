import React from 'react'
import PropTypes from 'prop-types'

/**
 * @name -accordion/Toggle
 * @component
 * @category Dashboard
 * @subcategory Components
 * @hideconstructor
 * @memberof Accordion
 */
function Toggle({ index, className, expanded, handleToggle, children }) {
    return (
        <div
            className={`accordion__toggle ${className || ''}`}
            aria-controls={`content-${index + 1}`}
            aria-expanded={expanded}
            onClick={handleToggle.bind(null, index)}
        >
            {children}
        </div>
    )
}

Toggle.propTypes = {
    index: PropTypes.number,
    className: PropTypes.string,
    expanded: PropTypes.bool,
    handleToggle: PropTypes.func
}

Toggle.displayName = "Toggle";

export default Toggle;