import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleModal } from '../../../store/global/globalActions';

/**
 * @component
 * @category Dashboard
 * @subcategory Profile
 * @hideconstructor
 * @memberof PrivacyModal
 */
function PrivacyFooter({ backBtn, toggleModal, setTab }) {
    return (
        <footer className="privacy__footer">
            {backBtn &&
                <button
                    className="privacy__back-btn"
                    onClick={setTab.bind(null, 'Settings')}
                >
                    &lt; back to previous menu
                </button>
            }
            <button
                className="privacy__close-btn"
                onClick={toggleModal}
            >
                Close
            </button>
        </footer>
    )
}

PrivacyFooter.propTypes = {
    backBtn: PropTypes.bool,
    toggleModal: PropTypes.func,
    setTab: PropTypes.func
}

const mapDispatchToProps = {
    toggleModal
}

export default connect(null, mapDispatchToProps)(PrivacyFooter);