import {
    TOGGLE_TOP_WRITERS,
    DISABLE_TOP_WRITER,
    ENABLE_TOP_WRITER,
    SET_DEFAULT_VALUE
} from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

export const initialState = {
    id: 'topwriters',
    value: 1,
    title: 'Premium Editor',
    tooltip: 'Your order will be edited by one of the most qualified 10 specialists in your subject area to get an outstanding result.',
    disabled: false
}

export const TOP_WRITERS = {
    DISABLE: 1,
    ENABLE: 2
}

export default function topWriterReducer(state = initialState, { type, payload }) {

    switch (type) {
        case TOGGLE_TOP_WRITERS:
            return {
                ...state,
                value: state.value === 1 ? TOP_WRITERS.ENABLE : TOP_WRITERS.DISABLE
            };

        case DISABLE_TOP_WRITER:
            return {
                ...state,
                disabled: true,
                value: TOP_WRITERS.DISABLE,
            };

        case ENABLE_TOP_WRITER:
            return {
                ...state,
                disabled: false
            };

        case SET_DEFAULT_VALUE:
            return {
                ...state,
                value: payload.topWriters || state.value
            };

        case CLEAR_ORDERFORM:
            return {
                ...state,
                value: payload.topWriters,
                disabled: false
            };

        default:
            return state;
    }
}
