import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { toggleModal } from '../store/global/globalActions';

const modalRoot = document.getElementById('modal-root');

/**
 * @component
 * @hideconstructor
 */
function Modal({ modalInfo, modalComponent, toggleModal, children }) {
    const el = document.createElement('div');
    el.className = 'modal-wrapper';

    /**
     * @param {Event} e 
     */
    const handleModal = (e) => {
        if (e.target.classList.contains('modal-wrapper')) {
            // Check for orderform url
            const order = window.location.pathname.startsWith('/order') && !window.location.pathname.startsWith('/orderinfo');

            if (order && modalComponent === 'PaymentModal') {
                window.location.assign(`/orderinfo/${modalInfo.orderId}/details`);
            }

            toggleModal();
        }
    }

    /**
     * @param {Event} e 
     */
    const handleKeybord = (e) => {
        if (e.key === 'Escape' || e.code === 'Escape' || e.keyCode === 27) {
            toggleModal();
        }
    }

    useEffect(() => {
        modalRoot.appendChild(el);
        document.addEventListener('click', handleModal);
        document.addEventListener('keydown', handleKeybord);

        const inner = document.querySelector('.modal-inner');

        setTimeout(() => {
            el.classList.add('visible');
            inner.classList.add('visible');

            modalInfo.blockClass && el.classList.add(modalInfo.blockClass);

        }, 200);

        return () => {
            modalRoot.removeChild(el);
            document.removeEventListener('click', handleModal);
            document.removeEventListener('keydown', handleKeybord);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return ReactDOM.createPortal(
        React.createElement(
            'div',
            { className: 'modal-inner' },
            children
        ),
        el,
    );
}

Modal.propTypes = {
    modalComponent: PropTypes.string,
    modalInfo: PropTypes.object,
    toggleModal: PropTypes.func
}

const mapStateToProps = ({ global }) => ({
    modalInfo: global.modalInfo,
    modalComponent: global.modalComponent
});

const mapDispatchToProps = {
    toggleModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);