import React from 'react';

export default function RevisionsIcon() {
    return (
        <svg viewBox="0 0 16 16">
            <g>
                <path d="M16,1v5c0,0.55-0.45,1-1,1h-5C9.45,7,9,6.55,9,6s0.45-1,1-1h3.2C12.16,3.21,10.22,2,8,2C4.69,2,2,4.69,2,8
                    s2.69,6,6,6c1.65,0,3.13-0.66,4.21-1.73l1.26,1.57C12.04,15.17,10.12,16,8,16c-4.42,0-8-3.58-8-8c0-4.42,3.58-8,8-8
                    c2.38,0,4.53,1.04,6,2.7V1c0-0.55,0.45-1,1-1S16,0.45,16,1z"/>
            </g>
        </svg>
    )
}
