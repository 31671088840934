import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {
  LoadingOutlined,
  PoweroffOutlined,
  PlusCircleOutlined, LoginOutlined, FormOutlined
} from "@ant-design/icons";
import {logout} from "store/login/loginActions";
import TabLinksNew from "../tablinks/TabLinksNew";

export const HeaderMenu = () => {
  const dispatch = useDispatch();
  // TODO: Split
  const customer = useSelector(({ dashboard }) => dashboard?.profile?.customer ?? {});
  const links = useSelector(({dashboard})=>dashboard.nav.links)

  // TODO: Split
  const defaultsFailedError = useSelector(({ global }) => global.defaultsFailedError);
  const isAuthorized = useSelector(({global})=>global.isAuthorized)

  const { email } = customer;
  const isLoggedInBefore = localStorage.getItem("accessToken");

  const logoutUser = () => dispatch(logout());

  if (defaultsFailedError) return null; // no spinner
  
  // Verifying user token
  if (isLoggedInBefore && !email) {
    return (
      <span>
        <LoadingOutlined style={{ fontSize: 33 }} />
      </span>
    );
  }

  return (
    <div className="menu">
      <button className="menu__toggle">
        <span></span>
        <span></span>
        <span></span>
      </button>
      {isAuthorized && (
        <nav className="nav">
          <TabLinksNew links={links} baseUrl="" />
        </nav>
      )}
      <ul className="menu__list ">
        {isLoggedInBefore ? (
          <>
            <li className="menu__item username">
              <div className="menu__link" onClick={logoutUser}>
                <PoweroffOutlined />
                <em>Logout</em>
              </div>
            </li>
          </>
        ) : (
          <>
            <li className="menu__item username">
              <NavLink className="menu__link" to="/order">
                <PlusCircleOutlined />
                <em>Create Order</em>
              </NavLink>
            </li>
            <li className="menu__item username">
              <NavLink className="menu__link" to="/login">
                <LoginOutlined/> <em>Login</em>
              </NavLink>
            </li>
            <li className="menu__item username">
              <NavLink className="menu__link" to="/register">
                <FormOutlined /> <em>Register</em>
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};
