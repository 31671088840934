import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HeaderMenu } from "dashboard/components/layout/HeaderMenu";
import { useLocation } from "react-router";

export const MainLayout = ({ children }) => {
  const [isFixed, setFixed] = useState(false);
  const { siteDomain, isAuthorized, footerInfo } = useSelector(({ global }) => global);
  const { pathname } = useLocation();

  const handleScroll = () => {
    setFixed(window.scrollY >= 30);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <header className={`site-header ${isFixed ? "fixed" : ""}`}>
        <div className="site-header__container container">
          <a href={siteDomain}>
            <img className="logo" src={`${process.env.REACT_APP_HOME_URL}/img/logo.svg`} alt="logo" />
          </a>
          <HeaderMenu />
        </div>
      </header>

      <div className={`page is-${pathname.replace("/", "")} is-${isAuthorized ? "loggedin" : "guest"}`}>
        <div className="container">{children}</div>
      </div>

      {footerInfo.enabled && <footer className="site-footer">
        <div className="site-footer__container container">
          {/* <a href={HOME_URL} className="logo__wrapper">
            <img className="logo" src={`${process.env.REACT_APP_HOME_URL}/static/img/logo.svg`} alt="logo" />
          </a> */}
          <div className="site-footer__legal-info" dangerouslySetInnerHTML={{__html: footerInfo.legal}}>
            
          </div>
          <div className='site-footer__contacts'>
            <span>{footerInfo.phone}</span>
            <span><a href="mailto:{footerInfo.email}">{footerInfo.email}</a></span>
          </div>
          <div className='site-footer__copyright'>{footerInfo.copyright}</div>
        </div>
      </footer>}
    </>
  );
};
