import {
    TOGGLE_SELECT_DROPDOWN,
    SELECT_OPTION,
    CLOSE_SELECT_ON_CLICK_OUTSIDE,
    SELECT_SEARCH,
    DISABLE_SELECT_OPTIONS,
    ENABLE_SELECT_OPTIONS
} from 'store/orderform/orderformActionTypes';

export default function reducerLogic(state = {}, { type, payload }) {
    switch (type) {
        case TOGGLE_SELECT_DROPDOWN + '_' + state.id:
            return {
                ...state,
                dropdownOpen: !state.dropdownOpen
            };

        case SELECT_OPTION + '_' + state.id:
            return {
                ...state,
                value: payload,
                searchValue: '',
                filteredOptions: [],
                dropdownOpen: !state.dropdownOpen,
            };

        case CLOSE_SELECT_ON_CLICK_OUTSIDE:
            return {
                ...state,
                searchValue: '',
                filteredOptions: [],
                dropdownOpen: false
            };

        case SELECT_SEARCH + '_' + state.id:
            return {
                ...state,
                searchValue: payload.searchValue,
                filteredOptions: payload.filteredOptions
            };

        case DISABLE_SELECT_OPTIONS + '_' + state.id:
            return {
                ...state,
                disabledOptions: [...new Set([...state.disabledOptions, ...payload])],
                value: (state.id === 'urgency' && state.value < 12) ? 12 : state.value
            };

        case ENABLE_SELECT_OPTIONS + '_' + state.id:
            return {
                ...state,
                disabledOptions: state.disabledOptions.filter(item => !payload.includes(item))
            };

        default:
            return state;
    }
}