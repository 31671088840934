import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PrivacyFooter from './PrivacyFooter';
import Checkbox from '../../../orderform/components/checkbox/Checkbox';

import { setPreferences } from '../../../store/dashboard/profile/profileActions';

/**
 * @component
 * @category Dashboard
 * @subcategory Profile
 * @hideconstructor
 * @memberof PrivacyModal
 */
function Preferences({ profile, setPreferences, setTab }) {
    const list = [
        {
            name: 'cookies',
            field: 'enabledCookiesUsage',
            checked: profile.enabledCookiesUsage,
            label: profile.enabledCookiesUsage ? 'Cookies: Allow all cookies' : 'Cookies: Use vital cookies only',
            text: 'By selecting "Yes" you give us permission to collect all cookies that our website stores on your computer. This will result in a smoother user experience - you will end up clicking less to reach the desired goal. Alternatively, we will only be storing vital cookies that are essential for user authentication, order creation, and payment.'
        },
        {
            name: 'personalInfo',
            field: 'enabledPersonalData',
            checked: profile.enabledPersonalData,
            label: 'Personal Information',
            text: 'By selecting "Yes" you give us permission to collect technical information (first & last name, email, and phone number). Your name is optional though, however without an email & phone number we will not be able to reach you in case we need to clarify something or notify you about the completion of your order. Pro tip: You are in any way obliged to provide your real name, phone number, or primary email. You can use an alias, virtual phone number, and alternative email address! Alternatively, you can choose to delete your account and all your data.'
        },
        {
            name: 'techData',
            field: 'enabledTechnicalData',
            checked: profile.enabledTechnicalData,
            label: 'Technical data',
            text: 'By selecting "Yes" you give us permission to collect technical information (e.g. IP & MAC address, Browser name & version, general device & OS description, etc) needed to provide you with smooth site navigation and general user experience.'
        },
        {
            name: 'location',
            field: 'enabledLocationUsage',
            checked: profile.enabledLocationUsage,
            label: 'Location info',
            text: 'By selecting "Yes" you give us permission to view and collect your approximate location needed to provide you with a better user experience.'
        },
        {
            name: 'sharingInfo',
            field: 'enabledInformationSharing',
            checked: profile.enabledInformationSharing,
            label: 'Information sharing',
            text: 'By selecting "Yes" you give us permission to share some of your information with our supporting service providers (mostly analytical & statistical tools). Information shared is limited to the degree required for smooth and uninterrupted business operation.'
        },
        {
            name: 'emailSubscription',
            field: 'enabledEmailSubscription',
            checked: profile.enabledEmailSubscription,
            label: profile.enabledEmailSubscription ? 'Email subscription: Yes, I want to receive all emails and notifications' : 'Email subscription: No, I want to receive vital emails only',
            text: 'By selecting "Yes" you give us permission to send you miscellaneous emails (promotions, digests, newsletters, etc). Alternatively, you will be only receiving vital notifications regarding your orders.'
        }
    ]

    return (
        <>
            {profile.id &&
                <>
                    <div className="privacy__preferences-wrapper">
                        <h2 className="privacy__heading">Privacy settings &gt; Consent preferences</h2>
                        <ul className="privacy__list scroll">
                            {list.map((item, index) => (
                                <li className="privacy__list-item" key={index}>
                                    <Checkbox
                                        id={item.name}
                                        type="checkbox"
                                        checked={item.checked}
                                        toggleCheck={setPreferences.bind(null, item.field)}
                                        disabled={false}
                                    >
                                        <span>{item.label}</span>
                                    </Checkbox>
                                    <p className="privacy__checkbox-info">
                                        {item.text}
                                    </p>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <PrivacyFooter backBtn={true} setTab={setTab} />
                </>
            }
        </>
    )
}

Preferences.propTypes = {
    profile: PropTypes.object,
    setPreferences: PropTypes.func,
    setTab: PropTypes.func
}

const mapStateToProps = ({ dashboard }) => ({
    profile: dashboard.profile.customer
});

const mapDispatchToProps = {
    setPreferences
}

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);