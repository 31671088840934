import React from 'react';
import PropTypes from 'prop-types';

/**
 * @name Checkbox
 * @component
 * @category Orderform
 * @subcategory Checkbox
 * @hideconstructor
 */
export default function Checkbox({ id, type, checked, toggleCheck, label, disabled, children }) {
    return (
        <div>
            <input
                id={id}
                name={id}
                type={type}
                className="checkbox__input"
                checked={checked}
                onChange={toggleCheck.bind(null, id)}
                disabled={disabled}
                tabIndex="1"
            />
            <label className="checkbox__label" htmlFor={id}>
                {children || label}
                <div className="checkbox__button">
                    <svg className="checkbox__button-arrow" width="21" height="16" viewBox="0 0 21 16">
                        <path d="M20.898 2.999L8 15.899 5.17 13.07.1 7.999 2.927 5.17l5.07 5.07L18.07.172z"></path>
                    </svg>
                </div>
            </label>
        </div>
    );
}

Checkbox.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    toggleCheck: PropTypes.func
}