import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkUser, login } from 'store/login/loginActions';
import { forgotPassword } from 'store/global/globalActions';

// Validations
const validators = {
  email: [
    { required: true, message: 'Email is mandatory' },
    { type: 'email', message: 'Provide valid email address' },
  ],
  password: [{ required: true, message: "Password can't be empty" }],
};

export default function Login() {
  // Store
  const { isAuthorized } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  // State
  const [isProcessing, setIsProcessing] = useState(false); // loading spinner

  // HANDLE
  const handleLogin = (credentials) => {
    setIsProcessing(true); // start spinner
    dispatch(login(credentials)) // login with credentials
      .then(() => dispatch(checkUser()))
      // .then(({firstName}) => notify.success(`Welcome back${firstName||''}!`))
      .catch(setIsProcessing); // stop spinner
  };

  const showForgotPasswordModal = () => dispatch(forgotPassword());

  // Check already logged in
  if (isAuthorized) return <Redirect to="/dashboard" />;

  return (
    <div className="login login__wrapper">
      <h1 className="login__heading">
        {/*<LockOutlined />*/}
        Sign In
      </h1>

      <Form size="large" onFinish={handleLogin} className="login__form" noValidate>
        <Form.Item name="email" rules={validators.email} onChange={(e) => {
          // dispatch(setEmail(e))
        }
          }>
          <Input
            className="login__input"
            type="email"
            placeholder="Your email"
            prefix={true} // dump fix!
          />
        </Form.Item>
        <Form.Item name="password" rules={validators.password}>
          <Input.Password className="login__input" type="password" placeholder="Your password" />
        </Form.Item>
        <Button className="login__btn" htmlType="submit" type="primary" loading={isProcessing} size="large">
          Login
        </Button>
        <div className="login__link">
          <span className="link" onClick={showForgotPasswordModal}>Forgot password?</span>

          {/* <span className="text-muted">Don't have an account?</span> */}
          {/*<br />*/}
          {/*<Link to="/order">Sign up with order</Link> {' or '}*/}
          {/*<Link to="/register">Register</Link>*/}
        </div>
      </Form>
    </div>
  );
}
