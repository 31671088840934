import reducerLogic from 'store/orderform/textfield/textFieldReducer';

const initialState = {
    id: 'name',
    type: 'text',
    value: '',
    error: '',
    placeholder: 'Your First and Last Name',
    label: 'Name',
    tooltip: '',
    isRequired: false,
    isFocused: false
}

export default function nameReducer(state = initialState, action) {
    return reducerLogic(state, action);
}