import { combineReducers } from 'redux';

import priceReducer from 'store/orderform/orderformglobal/priceReducer';
import workTypeReducer from 'store/orderform/controls/worktype/workTypeReducer';
import levelReducer from 'store/orderform/controls/select/level/levelReducer';
import paperFormatReducer from 'store/orderform/controls/select/paperformat/paperFormatReducer';
import paperTypeReducer from 'store/orderform/controls/select/papertype/paperTypeReducer';
import resumeServiceReducer from 'store/orderform/controls/select/resumeservice/resumeServiceReducer';
import subjectReducer from 'store/orderform/controls/select/subject/subjectReducer';
import pagesReducer from 'store/orderform/controls/pages/pagesReducer';
import sourcesReducer from 'store/orderform/controls/sources/sourcesReducer';
import spacingReducer from 'store/orderform/controls/spacing/spacingReducer';
import titleReducer from 'store/orderform/details/title/titleReducer';
import paperDetailsReducer from 'store/orderform/details/paperdetails/paperDetailsReducer';
import credentialsReducer from 'store/orderform/details/credentials/credentialsReducer';
import fileReducer from 'store/orderform/details/file/fileReducer';
import urgencyReducer from 'store/orderform/details/urgency/urgencyReducer';
import topWriterReducer from 'store/orderform/details/topwriters/topWritersReducer';
import preferredWritersReducer from 'store/orderform/details/preferredwriter/preferredWriterReducer';
import extraqualityCheckReducer from 'store/orderform/details/extraqualitycheck/extraqualityCheckReducer';
import qualityFeatureReducer from 'store/orderform/details/qualityfeature/qualityFeatureReducer';
import onePageSummaryReducer from 'store/orderform/details/onepagesummary/onePageSummaryReducer';
import userTypeReducer from 'store/orderform/checkout/usertype/userTypeReducer';
import phoneReducer from 'store/orderform/checkout/phone/phoneReducer';
import emailReducer from 'store/orderform/checkout/email/emailReducer';
import passwordReducer from 'store/orderform/checkout/password/passwordReducer';
import nameReducer from 'store/orderform/checkout/name/nameReducer';
import { termsReducer, callsReducer } from 'store/orderform/checkout/checkbox/checkboxReducer';
import discountReducer from 'store/orderform/discount/discountReducer';

const orderformReducer = combineReducers({
    price: priceReducer,
    workType: workTypeReducer,
    level: levelReducer,
    paperFormat: paperFormatReducer,
    paperType: paperTypeReducer,
    resumeService: resumeServiceReducer,
    subject: subjectReducer,
    pages: pagesReducer,
    sources: sourcesReducer,
    spacing: spacingReducer,
    title: titleReducer,
    paperDetails: paperDetailsReducer,
    credentials: credentialsReducer,
    file: fileReducer,
    urgency: urgencyReducer,
    topWriters: topWriterReducer,
    preferredWriters: preferredWritersReducer,
    extraqualityCheck: extraqualityCheckReducer,
    qualityFeature: qualityFeatureReducer,
    onePageSummary: onePageSummaryReducer,
    userType: userTypeReducer,
    phone: phoneReducer,
    email: emailReducer,
    password: passwordReducer,
    name: nameReducer,
    terms: termsReducer,
    calls: callsReducer,
    discount: discountReducer
});

export default orderformReducer;