import React, { lazy, Suspense, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './shared/PrivateRoute';

import { MainLayout } from 'dashboard/components/layout/MainLayout';
import Modal from './shared/Modal';
import RevisionInfoModal from './dashboard/orderinfo/revisions/InfoModal';
import RevisionCreateModal from './dashboard/orderinfo/revisions/createmodal/CreateModal';
import PrivacyModal from './dashboard/profile/privacymodal/PrivacyModal';
import FeedbackModal from './dashboard/components/rating/FeedbackModal';
import PaymentModal from './dashboard/paymentmodal/PaymentModal';
import ForgotPassModal from './orderform/components/forgotpass/ForgotPassModal';
import ErrorModal from './dashboard/ErrorModal';
import OrderinfoRedirect from './dashboard/OrderinfoRedirect';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import NotFound from './pages/NotFound';
import ServerError from './pages/ServerError';

import { connect } from 'react-redux';
import { history } from './helpers/history.js'
import { checkMagicLogin, checkUser } from './store/login/loginActions';
import { fetchDiscountRules, getReadyDiscountsAmount, stopLoyaltyFetching } from './store/dashboard/loyalty/loyaltyActions';
import { fetchDefaultSettings } from './store/orderform/orderformglobal/globalAction';
import { getOrdersCount } from './store/dashboard/orderlist/ordersActions';

import WS from 'services/ws';
import { GoogleAnalytics } from 'shared/GoogleAnalytics';
import { GTM } from 'shared/GTM';

const Order = (lazy(() => import('./pages/Order')));
const Dashboard = (lazy(() => import('./pages/Dashboard')));
const Profile = (lazy(() => import('./pages/Profile')));
const Orderinfo = (lazy(() => import('./pages/Orderinfo')));

// import { axios } from './helpers/axios';
// import { notify } from './vendor/Notification';
// import { Notification } from './vendor/Notification';

const modalComponents = { RevisionInfoModal, RevisionCreateModal, PrivacyModal, PaymentModal, FeedbackModal, ForgotPassModal, ErrorModal };

const LoadingMessage = () => (<></>);

/**
 * Root App comonent
 * @component
 * @category App
 * @hideconstructor
 */
function App({ isAuthorized, modalOpened, modalComponentName, modalInfo, links, fetchDefaultSettings, fetchDiscountRules, stopLoyaltyFetching, getReadyDiscountsAmount, checkUser, getOrdersCount, mainColor, checkMagicLogin, stepEnabled, ...props }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
        const fetchSiteDefaultSettings = new Promise((resolve) => {
            fetchDefaultSettings(resolve);
        });

        fetchSiteDefaultSettings
            .then(checkMagicLogin)
            .then(checkUser)
            .then(setIsLoading);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty('--main-color', mainColor);
    }, [mainColor]);

    useEffect(() => {
        if (isAuthorized) {
            getOrdersCount();
            WS.connect();

            const getDiscountRules = new Promise((resolve) => {
                fetchDiscountRules(resolve);
            });

            const readyDiscountsAmount = new Promise((resolve) => {
                getReadyDiscountsAmount(resolve);
            });

            Promise.all([getDiscountRules, readyDiscountsAmount])
                .then(() => {
                    // this action was designed for indicating about loaylty date fatching completion (<LoyaltyBar />)
                    stopLoyaltyFetching();
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthorized])

    const InnerModalComponen = modalComponents[modalComponentName];

    if (props.defaultsFailedError){
      return <ServerError />
    }

    return (
      <>
        <Router history={history}>
          <MainLayout>
            <main className="main dashboard">
              <div className="container main-wrapper">
                {!isLoading && (
                  <section>
                    <Suspense fallback={<LoadingMessage />}>
                      <Switch>
                        <Route exact path="/">
                          {stepEnabled ? <Redirect to="/order/controls" /> : <Redirect to="/order" />}
                        </Route>

                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        {/*<PrivateRoute path="/transactions" component={Transactions} />*/}
                        <PrivateRoute path="/profile" component={Profile} />
                        {/*<PrivateRoute path="/loyalty" component={Loyalty} />*/}
                        <PrivateRoute path="/orderinfo/:id" component={Orderinfo} />
                        <PrivateRoute exact path="/orderinfo" component={OrderinfoRedirect} />

                        <Route path="/order" component={Order} />
                        <Route path="/register" component={SignUp} />
                        <Route path="/login" component={Login} />
                        <Route path="/404" component={NotFound} />
                        <Route component={NotFound} />
                      </Switch>
                    </Suspense>
                  </section>
                )}
              </div>
            </main>
            {/* {isAuthorized &&
                    <footer className="footer">
                        <div className="container wrapper">
                            <p>All rights reserved.</p>
                        </div>
                    </footer>
                } */}
          </MainLayout>

          {/* <ZopimChat/> */}

          <GoogleAnalytics/>
          <GTM/>

        </Router>
        {modalOpened && (
          <Modal>
            <InnerModalComponen {...modalInfo} />
          </Modal>
        )}
      </>
    );
}

App.propTypes = {
    /** Authorization flag */
    isAuthorized: PropTypes.bool,
    /** Open modal indicator */
    modalOpened: PropTypes.bool,
    stepEnabled: PropTypes.bool,
    /** Modal name */
    modalComponentName: PropTypes.string,
    /** Modal props */
    modalInfo: PropTypes.object,
    /** Navigation links object */
    links: PropTypes.arrayOf(PropTypes.object),
    /** Get default settings method */
    fetchDefaultSettings: PropTypes.func,
    /** Get discount rules method */
    fetchDiscountRules: PropTypes.func,
    /** Stop fetching loyalty data method */
    stopLoyaltyFetching: PropTypes.func,
    /** Get Ready discount amount method */
    getReadyDiscountsAmount: PropTypes.func
}

const mapStateToProps = ({ dashboard, global }) => ({
    isAuthorized: global.isAuthorized,
    modalOpened: global.modalOpened,
    modalComponentName: global.modalComponent,
    modalInfo: global.modalInfo,
    mainColor: global.mainColor,
    links: dashboard.nav.links,
    stepEnabled: global.stepEnabled, // global.orderform.stepEnabled, <- outdated backend
    defaultsFailedError: global.defaultsFailedError
});

const mapDispatchToProps = {
    fetchDefaultSettings,
    fetchDiscountRules,
    stopLoyaltyFetching,
    getReadyDiscountsAmount,
    checkUser,
    checkMagicLogin,
    getOrdersCount
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
