/**
 * @module OrderinfoActions
 */
import {
    FETCH_ORDER_INFO,
    FETCH_ORDER_INFO_ERROR,
    CLEAR_ORDER_INFO,
} from 'store/actionTypes';

import {
    orderinfoURL,
    // hideOrderURL, 
    // unhideOrderURL 
} from 'store/config';
import http from 'services/http';

/** */
export function fetchOrderinfo(orderId) {
    return function (dispatch) {
        return http
            .get(orderinfoURL(orderId))
            .then(data => {
                if (data.hidden === 'YES') {
                    dispatch({
                        type: FETCH_ORDER_INFO_ERROR,
                        payload: data
                    });
                    console.log('Order is hidden');

                } else {
                    dispatch({
                        type: FETCH_ORDER_INFO,
                        payload: data
                    });
                }
            })
            .catch(({ message, body }) => {
                if (body) {
                    dispatch({
                        type: FETCH_ORDER_INFO_ERROR,
                        payload: body
                    });
                    console.log('Fetch order fail >>>>', body);

                } else {
                    console.log(message);
                }
            })

        /*return fetch(orderinfoURL(orderId))
            .then(response => response.json().then(data => ({ status: response.status, data: data })))
            .then(({ status, data }) => {
                if (status !== 200) {
                    dispatch({
                        type: FETCH_ORDER_INFO_ERROR,
                        payload: data
                    });
                    console.log('Fetch order fail >>>>', data);

                } else if (data.hidden === 'YES') {
                    dispatch({
                        type: FETCH_ORDER_INFO_ERROR,
                        payload: data
                    });
                    console.log('Order is hidden');

                } else {
                    dispatch({
                        type: FETCH_ORDER_INFO,
                        payload: data
                    });
                }

            }).catch(err => console.log(err));*/
    }
}

/** */
export function clearOrderinfo() {
    return function (dispatch) {
        dispatch({ type: CLEAR_ORDER_INFO })
    }
}

// hideOrder doesn't use
/** */
/*export function hideOrder(orderId) {
    return function (dispatch) {

        fetch(hideOrderURL(orderId), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id_order: orderId })
        })
            .then(response => response.json())
            .then(data => {

                // dispatch({ type: HIDE_ORDER, payload: data.id });

            }, err => {
                console.error('Fail fetch [hideOrder]: ', err);
            })
    }
}*/

// unhideOrder doesn't use
/** */
/*export function unhideOrder(orderId) {
    return function (dispatch) {

        fetch(unhideOrderURL(orderId), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id_order: orderId })
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);

                // dispatch({ type: UNHIDE_ORDER, payload: data.id });
            }, err => {
                console.error('Fail fetch [unhideOrder]: ', err);
            })
    }
}*/