import {
    FETCH_WRITERS_FILES,
    FETCH_CUSTOMERS_FILES,
    CLEAR_FILES_INFO,
    ADD_FILE,
    CLEAR_DROPZONE_FILES,
    REMOVE_FILE
} from "store/actionTypes"

export const initialState = {
    customersFiles: [],
    writersFiles: [],
    validTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif', '.pdf', '.doc', '.docx', '.rtf', '.xls', '.xlsx', '.txt', '.ppt', '.pptx', '.csv', '.zip', '.rar', '.zipx', '.7z', '.gz', '.gzip', '.tar', '.tar-gz', '.html'],
    uploadFiles: []
}

export default function filesReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_CUSTOMERS_FILES:
            return {
                ...state,
                customersFiles: payload
            }

        case FETCH_WRITERS_FILES:
            return {
                ...state,
                writersFiles: payload
            }

        case CLEAR_FILES_INFO:
            return {
                ...state,
                writersFiles: [],
                customersFiles: [],
                uploadFiles: []
            }

        case CLEAR_DROPZONE_FILES:
            return {
                ...state,
                uploadFiles: []
            }

        case ADD_FILE:
            return {
                ...state,
                uploadFiles: [...state.uploadFiles, payload]
            }

        case REMOVE_FILE:
            return {
                ...state,
                customersFiles: state.customersFiles.filter((item) => item.id !== payload)
            }

        default:
            return state;
    }
}
