import {
    FETCH_TRANSACTION_LIST,
    SET_TRANSACTION_LIST_PAGE,
    SET_TRANSACTION_LIST_FILTER
} from 'store/actionTypes';

export const initialState = {
    orderList: {},
    limit: 16,
    page: 1,
    searchValue: '',
    orderStatus: 0,
    isFetching: true,
    idPaymentStatus: 2,
    filterOptions: [{
        "id": 0,
        "name": "All"
    },
    // {
    //     "id": 1,
    //     "name": "Unpaid"
    // },
    {
        "id": 2,
        "name": "Paid"
    },
    {
        "id": 3,
        "name": "In Progress"
    },
    {
        "id": 5,
        "name": "Completed"
    },
    {
        "id": 7,
        "name": "Refunded"
    },
    {
        "id": 8,
        "name": "Canceled"
    },
    {
        "id": 9,
        "name": "On Hold"
    },
    // {
    //     "id": 10,
    //     "name": "Modified"
    // },
    {
        "id": 11,
        "name": "Offered"
    }
    ]
}

export default function transactionsReducer(state = initialState, { type, payload }) {

    switch (type) {
        case FETCH_TRANSACTION_LIST:
            return {
                ...state,
                orderList: payload,
                isFetching: false
            };

        case SET_TRANSACTION_LIST_PAGE:
            return {
                ...state,
                page: payload
            };

        case SET_TRANSACTION_LIST_FILTER:
            return {
                ...state,
                orderStatus: payload,
                page: 1
            };

        default:
            return state;
    }
}