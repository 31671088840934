import React from "react";
import { Button } from "antd";
import { BrowserRouter } from "react-router-dom";
import { MainLayout } from "dashboard/components/layout/MainLayout";
import { history } from "helpers/history";

const styles = {
  wrapper: { display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" },
  heading: { fontWeight: 900, fontSize: 48, color: "red", margin: 0 },
};

export const ServerError = ({ title, children }) => (
  <BrowserRouter history={history}>
    <MainLayout>
      <main className="main dashboard">
        <div className="container main-wrapper" style={styles.wrapper}>
          <img src="/img/error.gif" alt="" width={100} />
          <h1 style={styles.heading}>{title || "Server Error!"}</h1>
          {children || <p>Looks like something went wrong on our end.</p>}
          <Button onClick={() => window.location.reload()} size="large">
            Reload the Page
          </Button>
        </div>
      </main>
    </MainLayout>
  </BrowserRouter>
);

export default ServerError;
