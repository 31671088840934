import React from 'react';

export default function DiscountIcon() {
    return (
        <svg viewBox="0 0 16 16">
            <g>
                <g>
                    <path d="M3.5,2C4.327,2,5,2.673,5,3.5S4.327,5,3.5,5S2,4.327,2,3.5S2.673,2,3.5,2 M3.5,0C1.567,0,0,1.567,0,3.5
                        S1.567,7,3.5,7S7,5.433,7,3.5S5.433,0,3.5,0L3.5,0z"/>
                </g>
                <g>
                    <path d="M12.5,11c0.827,0,1.5,0.673,1.5,1.5S13.327,14,12.5,14S11,13.327,11,12.5S11.673,11,12.5,11 M12.5,9
                        C10.567,9,9,10.567,9,12.5s1.567,3.5,3.5,3.5s3.5-1.567,3.5-3.5S14.433,9,12.5,9L12.5,9z"/>
                </g>
                <g>
                    <rect x="-1.899" y="7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3137 8)" width="19.799" height="2" />
                </g>
            </g>
        </svg>
    )
}
