import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { notify, notification } from '../helpers/notification';
import { signUp } from '../store/login/loginActions';

// Validations
const validdatePassword = () => ({
  validator: (_, password) => {
    if (!password) return Promise.resolve();
    if (!password?.match(/^[a-zA-Z0-9@#$_%^!&+=]*$/g)) {
      return Promise.reject(new Error('Allowed characters a-z0-9@#$_%^!&+='));
    } else return Promise.resolve();
  }
})
const validators = {
  firstName: [
    {
      required: true, message: 'Please enter your name'
    }
  ],
  email: [
    { required: true, message: 'Email is mandatory' },
    { type: 'email', message: 'Provide valid email address' },
    {
      validator: (_, email) => {
        if (email?.match(/[!|~`#$%^&*+=|\\[\]":?><]/)) {
          return Promise.reject(new Error('Invalid character found'));
        }
        if (email?.length > 45 ) {
          return Promise.reject(new Error('Maximum 45 chars'));
        }
        return Promise.resolve()
      },
    }
  ],
  password: [
    validdatePassword,
    { required: true, message: "Password can't be empty" },
    {
      validator: (_, password) => {
        if (password?.length < 6 ) {
          return Promise.reject(new Error('Minimum 6 chars'));
        }
        if (password?.length > 45 ) {
          return Promise.reject(new Error('Maximum 45 chars'));
        }
        return Promise.resolve()
      },
    }
  ],
  confirmPassword: [
    validdatePassword,
    ({ getFieldValue }) => ({
      validator(_, confirmPassword) {
        const password = getFieldValue('password');
        if (!password) return Promise.resolve();
        if ((!confirmPassword && !password) || password === confirmPassword) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Passwords mismatch'));
      },
    }),
  ],
};

export default function SignUp() {
  // Store
  const { isAuthorized } = useSelector((state) => state.global);

  // State
  const [isProcessing, setIsProcessing] = useState(false); // loading spinner

  const handleSignUp = async (values) => {
    setIsProcessing(true); // start spinner

    const firstName = values.email.match(/(.*)@/)[1] || ''; // try get first name
    const requiredFields = { firstName, isEnabledNightCall: false }; // spread required fields

    const user = { ...requiredFields, ...values }; // merge with required fields

    // Signup user
    signUp(user)
      .then(() => {
        notification.success({
          message: 'Your profile has just been created.',
          description: 'Consider adding your phone number for the profile data to be complete.'
        });

        // & Redirect to="/profile"
      })
      .catch(({ message }) => {
        notify.error(message);
        setIsProcessing(); // stop spinner
      });
  };

  // Check already logged in
  if (isAuthorized) return <Redirect to="/profile" />;

  return (
    <div className="login login__wrapper">
      <h1 className="login__heading">
        {/*<UserAddOutlined />*/}
        Sign Up
      </h1>

      <Form size="large" onFinish={handleSignUp} className="login__form" scrollToFirstError noValidate >
        <Form.Item name="firstName" rules={validators.firstName} min={2} max={45} hasFeedback>
          <Input
            className="login__input"
            type="text"
            placeholder="Your name"
            prefix={true} // dump fix!
          />
        </Form.Item>
        <Form.Item name="email" rules={validators.email} min={6} max={45} hasFeedback>
          <Input
            className="login__input"
            type="email"
            placeholder="Your email"
            prefix={true} // dump fix!
          />
        </Form.Item>
        <Form.Item name="password" rules={validators.password} hasFeedback>
          <Input.Password className="login__input" type="password" placeholder="Create password" min={6} max={45} />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={validators.confirmPassword}
          dependencies={['password']}
          hasFeedback
        >
          <Input.Password className="login__input" type="confirmPassword" placeholder="Confirm password" min={6} max={45} />
        </Form.Item>

        <Button className="login__btn" htmlType="submit" type="primary" loading={isProcessing} size="large">
          Register
        </Button>
        {/*<div className="login__link">*/}
        {/*  <span className="link" onClick={() => window.history.back()}>← Sign in</span>*/}
        {/*</div>*/}
      </Form>
    </div>
  );
}
