import React from 'react';

export default function DetailsIcon() {
  return (
    <svg viewBox="64 64 896 896" focusable="false" data-icon="insert-row-below" width="1em" height="1em"
         fill="currentColor" aria-hidden="true">
      <defs>
      </defs>
      <path
        d="M904 768H120c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8zm-25.3-608H145.3c-18.4 0-33.3 14.3-33.3 32v464c0 17.7 14.9 32 33.3 32h733.3c18.4 0 33.3-14.3 33.3-32V192c.1-17.7-14.8-32-33.2-32zM360 616H184V456h176v160zm0-224H184V232h176v160zm240 224H424V456h176v160zm0-224H424V232h176v160zm240 224H664V456h176v160zm0-224H664V232h176v160z"/>
    </svg>
  )
}
