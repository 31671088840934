import {
    TOGGLE_EXTRA_QUALITY_CHECK,
    SET_DEFAULT_VALUE,
    DISABLE_EXTRA_QUALITY_CHECK,
    ENABLE_EXTRA_QUALITY_CHECK,
    DECREMENT_URGENCY,
    INCREMENT_URGENCY
} from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

export const initialState = {
    id: 'extraqualitycheck',
    value: 1,
    title: 'Extra quality check',
    tooltip: 'Before you get your completed work, we\'ll pass it over to another highly qualified expert from the same field of science for proofreading from an extra angle of view. This will make your paper exceptional!',
    disabled: false
}

export const QUALITY_CHECK = {
    DISABLE: 1,
    ENABLE: 2
}

export default function extraqualityCheckReducer(state = initialState, { type, payload }) {

    switch (type) {
        case TOGGLE_EXTRA_QUALITY_CHECK:
            return {
                ...state,
                value: state.value === 1 ? QUALITY_CHECK.ENABLE : QUALITY_CHECK.DISABLE
            };

        case SET_DEFAULT_VALUE:
            return {
                ...state,
                value: payload.extraqualityCheck || state.value
            };

        case DISABLE_EXTRA_QUALITY_CHECK:
            return {
                ...state,
                disabled: true,
                value: QUALITY_CHECK.DISABLE,
            };

        case ENABLE_EXTRA_QUALITY_CHECK:
            return {
                ...state,
                disabled: false
            };

        case DECREMENT_URGENCY:
            return {
                ...state,
                disabled: payload < 24,
                value: (payload < 24) ? QUALITY_CHECK.DISABLE : state.value,
            }

        case INCREMENT_URGENCY:
            return {
                ...state,
                disabled: payload < 24
            }

        case CLEAR_ORDERFORM:
            return {
                ...state,
                value: payload.extraqualityCheck,
                disable: false
            };

        default:
            return state;
    }
}