import { BASE_URL } from 'environmentVars';

export const paperFormatURL = `${BASE_URL}/api/en/orders/paper_formats`;
export const resumeServiceURL = `${BASE_URL}/api/en/orders/resume_services`;
export const subjectURL = `${BASE_URL}/api/en/orders/subjects`;
export const discountURL = `${BASE_URL}/api/en/orders/discounts`;
export const preferredURL = `${BASE_URL}/api/en/writers/preferred_writers`;

export function workTypeURL(categoryId) {
    return `${BASE_URL}/api/en/sites/categories/${categoryId}/orders/work_types`;
}

export function paperTypeURL(categoryId) {
    return `${BASE_URL}/api/en/sites/categories/${categoryId}/orders/assignments`;
}

export function urgencyURL(siteId) {
    return `${BASE_URL}/api/en/sites/${siteId}/orders/urgencies`;
}

export function calcURL(categoryId) {
    return `${BASE_URL}/api/en/sites/categories/${categoryId}/orders/calcs`;
}

export function createOrderURL(categoryId) {
    return `${BASE_URL}/api/en/sites/categories/${categoryId}/orders`;
}

export function fileURL(orderId) {
    return `${BASE_URL}/api/en/orders/${orderId}/customers/files`
}

const essay = {
    workType: true,
    level: true,
    paperFormat: true,
    paperType: true,
    resumeService: false,
    subject: true,
    pages: true,
    sources: true,
    spacing: true,
    title: true,
    details: true,
    credentials: true,
    file: true,
    urgency: true,
    topWriters: false,
    preferredWriters: false,
    extraqualityCheck: false,
    qualityFeature: true,
    onePageSummary: true,
    userType: true,
    phone: true,
    email: true,
    password: true,
    name: true,
    terms: true,
    calls: true,
    discount: true,
    stepEnabled: false,
    levelURL: `${BASE_URL}/api/en/orders/academic_levels`,
    levelFieldName: 'idAcademicLevel',
}

const resume = {
    workType: true,
    level: true,
    paperFormat: false,
    paperType: false,
    resumeService: true, //service
    subject: false,
    pages: true,
    sources: false,
    spacing: true,
    title: true,
    details: true,
    credentials: true,
    file: true,
    urgency: true,
    topWriters: true,
    preferredWriters: true,
    extraqualityCheck: true,
    qualityFeature: true,
    onePageSummary: true,
    userType: true,
    phone: true,
    email: true,
    password: true,
    name: true,
    terms: true,
    calls: true,
    discount: true,
    stepEnabled: true,
    levelURL: `${BASE_URL}/api/en/orders/resume_levels`,
    levelFieldName: 'idResumeLevel',
    pagesMax: 10
}

const thesis = {
    workType: true,
    level: true,
    paperFormat: true,
    paperType: true,
    resumeService: false,
    subject: true,
    pages: true,
    sources: true,
    spacing: true,
    title: true,
    details: true,
    credentials: true,
    file: true,
    urgency: true,
    topWriters: true,
    preferredWriters: true,
    extraqualityCheck: true,
    qualityFeature: true,
    onePageSummary: true,
    userType: true,
    phone: true,
    email: true,
    password: true,
    name: true,
    terms: true,
    calls: true,
    discount: true,
    stepEnabled: true,
    levelURL: `${BASE_URL}/api/en/orders/thesis_levels`,
    levelFieldName: 'idThesisLevel',
}

const content = {
    workType: true,
    level: false,
    paperFormat: false,
    paperType: false,
    resumeService: false,
    subject: false,
    pages: true,
    sources: false,
    spacing: false,
    title: true,
    details: true,
    credentials: false,
    file: true,
    urgency: true,
    topWriters: false,
    preferredWriters: false,
    extraqualityCheck: true,
    qualityFeature: false,
    onePageSummary: true,
    userType: true,
    phone: true,
    email: true,
    password: true,
    name: false,
    terms: true,
    calls: false,
    discount: true,
    stepEnabled: false,
    levelURL: '',
    levelFieldName: '',
}

export function shouldRender(siteId) {
    switch (siteId) {
        case 1:
            return essay;
        case 2:
            return essay;
        case 3:
            return resume;
        case 4:
            return thesis;
        case 5:
            return content;
        default:
            return essay;
    }
}