import React from 'react';
import PropTypes from 'prop-types';

/**
 * @name -accordion/Item
 * @component
 * @category Dashboard
 * @subcategory Components
 * @hideconstructor
 * @memberof Accordion
 */
function Item({ index, className, expanded, handleToggle, children }) {
    return (
        <li className={`accordion__item ${className || ''}`}>
            {React.Children.map(children, child => {
                if (child.type.displayName === 'Toggle') {
                    return React.cloneElement(child, { index, expanded, handleToggle })
                }

                if (child.type.displayName === 'Collapse') {
                    return React.cloneElement(child, { index, expanded })
                }

                return child;
            })}
        </li>
    )
}

Item.propTypes = {
    index: PropTypes.number,
    className: PropTypes.string,
    expanded: PropTypes.bool,
    handleToggle: PropTypes.func
}

Item.displayName = "Item";

export default Item;