import { TOGGLE_CHECKBOX } from "store/orderform/orderformActionTypes";

export const termsState = {
    id: 'terms',
    type: 'checkbox',
    error: '',
    label: '',
    checked: false,
    isRequired: true
}

export const callsState = {
    id: 'calls',
    type: 'checkbox',
    error: '',
    label: '',
    checked: false,
    isRequired: false
}

function logic(state, { type, payload }) {
    switch (type) {
        case TOGGLE_CHECKBOX + '_' + state.id:
            return {
                ...state,
                checked: !state.checked
            };
        default:
            return state;
    }
}

export function termsReducer(state = termsState, action) {

    return logic(state, action);
}

export function callsReducer(state = callsState, action) {

    return logic(state, action);
}