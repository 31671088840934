/**
 * @module TextFieldActions
 */
import {
    SET_TEXTFIELD_VALUE,
    SET_TEXTFIELD_BLUR,
    SET_TEXTFIELD_FOCUS,
    SECOND_STEP_VALIDATION,
    THIRD_STEP_VALIDATION
} from "store/orderform/orderformActionTypes";

import { validateSecondStep, validateThirdStep } from 'orderform/utils/orderformUtils';

/** */
export function setTextFieldValue(e) {
    return function (dispatch, getState) {
        dispatch({
            type: SET_TEXTFIELD_VALUE + '_' + e.target.id,
            payload: e.target.value
        });

        if (e.target.id === 'title') {
            localStorage.setItem('topic', e.target.value);
            validateSecondStep(SECOND_STEP_VALIDATION, dispatch, getState);
        }

        if (e.target.id === 'paperDetails') {
            validateSecondStep(SECOND_STEP_VALIDATION, dispatch, getState);
        }

        if (e.target.id === 'email') {
            localStorage.setItem(e.target.id, e.target.value);
        }

        if (['email', 'password'].includes(e.target.id)) {
            validateThirdStep(THIRD_STEP_VALIDATION, dispatch, getState);
        }
    }
}

/** */
export function textFieldBlur(e) {
    return function (dispatch, getState) {
        const { orderform } = getState();

        let error = '';

        if (e.target.id === 'paperDetails' && orderform.workType.value !== '2') {
            const paperDetailsValue = orderform.paperDetails.value.trim();

            error = (paperDetailsValue.length > 0 && paperDetailsValue.length < 10) ? 'Min: 10 characters' : '';

            if (orderform.file.fileObjects.length) {
                error = '';
            }
        }

        dispatch({
            type: SET_TEXTFIELD_BLUR + '_' + e.target.id,
            payload: error
        });
    }
}

/** */
export function textFieldFocus(e) {
    return function (dispatch) {
        dispatch({
            type: SET_TEXTFIELD_FOCUS + '_' + e.target.id
        });
    }
}