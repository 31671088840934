import React from 'react';

export default function ReferralIcon() {
    return (
        <svg viewBox="0 0 16 16">
            <g>
                <path d="M8,0C3.582,0,0,3.582,0,8s3.582,8,8,8s8-3.582,8-8S12.418,0,8,0z M8,14c-3.308,0-6-2.692-6-6s2.692-6,6-6
                    s6,2.692,6,6S11.308,14,8,14z"/>
                <path d="M11.43,10.06C10.73,11.22,9.46,12,8,12s-2.73-0.78-3.43-1.94l1.72-1.03C6.64,9.61,7.28,10,8,10
                    s1.36-0.39,1.71-0.97L11.43,10.06z"/>
            </g>
        </svg>
    )
}
