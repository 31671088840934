import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalNav from './ModalNav';
import FirstStep from './steps/FirstStep';
import SecondStep from './steps/SecondStep';
import ThirdStep from './steps/ThirdStep';

import { toggleModal } from '../../../../store/global/globalActions';
import { createRevision, fetchRevisionCategories, fetchRevisionLevels } from '../../../../store/dashboard/orderinfo/revisions/revisionActions';

const tabs = {
    FirstStep,
    SecondStep,
    ThirdStep
}

/**
 * @name -revisions/CreateModal
 * @component
 * @category Dashboard
 * @subcategory Orderinfo
 * @hideconstructor
 * @memberof Revisions
 */
function CreateModal({ acceptPolicy, pickedRevisionCategory, revisionDescription, pickedRevisionLevel, toggleModal, createRevision, fetchRevisionCategories, fetchRevisionLevels, locked }) {
    const initialState = {
        activeTab: 'FirstStep',
        firstStepValid: false,
        secondStepValid: false,
        thirdStepValid: false
    }

    const [{ activeTab, firstStepValid, secondStepValid, thirdStepValid }, setState] = useState(initialState);

    /** */
    const goPrev = () => {
        if (activeTab === 'ThirdStep') {
            setState((prevProps) => ({ ...prevProps, activeTab: 'SecondStep' }));

        } else {
            setState((prevProps) => ({ ...prevProps, activeTab: 'FirstStep' }));
        }
    }

    /** */
    const goNext = () => {
        if (activeTab === 'FirstStep' && firstStepValid) {
            setState((prevProps) => ({ ...prevProps, activeTab: 'SecondStep' }));

        } else if (activeTab === 'SecondStep' && secondStepValid) {
            setState((prevProps) => ({ ...prevProps, activeTab: 'ThirdStep' }));
        }
    }

    /**
     * 
     * @param {string} tabName 
     */
    const setActiveTab = (tabName) => {
        setState((prevProps) => ({ ...prevProps, activeTab: tabName }));
    }

    useEffect(() => {
        fetchRevisionCategories();
        fetchRevisionLevels();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (acceptPolicy) {
            setState((prevProps) => ({ ...prevProps, firstStepValid: true }));

        } else {
            setState((prevProps) => ({ ...prevProps, firstStepValid: false }));
        }
    }, [acceptPolicy]);

    useEffect(() => {
        if (pickedRevisionCategory.length && revisionDescription) {
            setState((prevProps) => ({ ...prevProps, secondStepValid: true }));

        } else {
            setState((prevProps) => ({ ...prevProps, secondStepValid: false }));
        }
    }, [pickedRevisionCategory.length, revisionDescription]);

    useEffect(() => {
        if (pickedRevisionLevel) {
            setState((prevProps) => ({ ...prevProps, thirdStepValid: true }));

        } else {
            setState((prevProps) => ({ ...prevProps, thirdStepValid: false }));
        }
    }, [pickedRevisionLevel]);

    const Tab = tabs[activeTab];

    return (
        <div className="revision-create">
            <button
                className="modal__close-btn"
                onClick={toggleModal}
            >
                <svg width="14" height="14" viewBox="0 0 14 14"><g><path d="M.636 11.95L5.586 7 .636 2.05 2.05.636 7 5.586l4.95-4.95 1.414 1.414L8.414 7l4.95 4.95-1.415 1.414L7 8.414l-4.95 4.95z" /></g></svg>
            </button>
            <div className="modal__nav">
                <ModalNav
                    activeTab={activeTab}
                    firstStepValid={firstStepValid}
                    secondStepValid={secondStepValid}
                    thirdStepValid={thirdStepValid}
                    setActiveTab={setActiveTab}
                />
            </div>
            <div className="modal__body">
                <Tab />
            </div>

            <div className="modal__footer">
                {activeTab !== 'FirstStep' &&
                    <button
                        className="modal__prev-btn"
                        onClick={goPrev}
                    >
                        &lt; Previous Step
                    </button>
                }
                {activeTab !== 'ThirdStep' &&
                    <button
                        className={`modal__next-btn ${(activeTab === 'FirstStep' && firstStepValid) || (activeTab === 'SecondStep' && secondStepValid) ? 'active' : ''}`}
                        onClick={goNext}
                    >
                        Next Step &gt;
                    </button>
                }
                {activeTab === 'ThirdStep' &&
                    <button
                        className={`modal__next-btn ${thirdStepValid ? 'active' : ''}`}
                        onClick={createRevision.bind(null, thirdStepValid)}
                        disabled={locked}
                    >
                        Finish
                    </button>
                }

            </div>
        </div>
    )
}

CreateModal.propTypes = {
    acceptPolicy: PropTypes.bool,
    locked: PropTypes.bool,
    pickedRevisionCategory: PropTypes.arrayOf(PropTypes.number),
    revisionDescription: PropTypes.string,
    revisionLevel: PropTypes.number,
    fetchRevisionCategories: PropTypes.func,
    fetchRevisionLevels: PropTypes.func,
    toggleModal: PropTypes.func,
    createRevision: PropTypes.func
}

const mapStateToProps = ({ global, dashboard }) => ({
    locked: global.locked,
    acceptPolicy: dashboard.revisions.acceptPolicy,
    pickedRevisionCategory: dashboard.revisions.pickedRevisionCategory,
    revisionDescription: dashboard.revisions.revisionDescription,
    pickedRevisionLevel: dashboard.revisions.pickedRevisionLevel
});

const mapDispatchToProps = {
    fetchRevisionCategories,
    fetchRevisionLevels,
    toggleModal,
    createRevision
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal);