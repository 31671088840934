import {
    USER_AUTHORIZED,
    SET_PROFILE_PHONE,
    SET_PROFILE_FIRST_NAME,
    TOGGLE_PROFILE_NEWS_LETTERS,
    TOGGLE_PROFILE_NIGHT_CALLS,
    UPDATE_PROFILE,
    SET_PREFERENCES,
    SEND_CONFIRMATION,
    TOGGLE_PROFILE_FB_MESSENGER,
    DISABLED_PROFILE_FB_MESSENGER,
    ENABLED_PROFILE_FB_MESSENGER,
    LOADING_FACEBOOK_SCRIPTS,
    UPDATE_PROFILE_STATUS
} from 'store/actionTypes';

export const initialState = {
    customer: {},
    canUpdate: false,
    facebookLoadingScripts: false,
    profileData: {}
}

export default function profileReducer(state = initialState, { type, payload }) {
    switch (type) {
        case USER_AUTHORIZED:
            return {
                ...state,
                customer: payload,
                profileData: {
                    phone: payload.phone,
                    name: payload.firstName,
                    letters: payload.enabledNewsletters,
                    calls: payload.enabledNightCalls,
                    facebookCkeckbox: payload.enabledFacebookMessenger
                }
            };

        case UPDATE_PROFILE:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    facebookData: payload.facebookData
                },
                profileData: {
                    phone: payload.phone,
                    name: payload.firstName,
                    letters: payload.enabledNewsletters,
                    calls: payload.enabledNightCalls,
                    facebookCkeckbox: payload.enabledFacebookMessenger
                }
            }

        case SET_PROFILE_PHONE:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    phone: payload
                }
            };

        case SET_PROFILE_FIRST_NAME:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    firstName: payload
                }
            };

        case TOGGLE_PROFILE_NEWS_LETTERS:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    enabledNewsletters: !state.customer.enabledNewsletters
                }
            };

        case TOGGLE_PROFILE_NIGHT_CALLS:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    enabledNightCalls: !state.customer.enabledNightCalls
                }
            };

        case TOGGLE_PROFILE_FB_MESSENGER:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    enabledFacebookMessenger: !state.customer.enabledFacebookMessenger
                }
            }

        case DISABLED_PROFILE_FB_MESSENGER:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    enabledFacebookMessenger: false
                }
            }

        case ENABLED_PROFILE_FB_MESSENGER:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    enabledFacebookMessenger: true
                }
            }

        case SET_PREFERENCES:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    [payload]: !state.customer[payload]
                }
            };

        case SEND_CONFIRMATION:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    status: {
                        ...state.customer.status,
                        id: 3,
                        name: 'Awaiting Confirmation'
                    }
                }
            };

        case LOADING_FACEBOOK_SCRIPTS:
            return {
                ...state,
                facebookLoadingScripts: true
            }

        case UPDATE_PROFILE_STATUS:
            return {
                ...state,
                canUpdate: payload
            }

        default:
            return state;
    }
}