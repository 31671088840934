import {
    FETCH_DISCOUNT_RULES,
    FETCH_DISCOUNT_LIST,
    SET_CURRENT_RULE,
    CREATE_DISCOUNT,
    FETCH_REFERRAL_LINK,
    GET_READY_REFERRALS,
    FETCH_REFERRALS,
    REF_DISCOUNT_GENERATION_SUCCESS,
    SET_REFERRAL_FILTER,
    STOP_LOYALTY_FETCHING
} from "store/actionTypes";

export const initialState = {
    links: [{
        id: 'referral',
        name: 'Invite Friends',
        icon: 'ReferralIcon',
        url: '/referral',
        disabled: false
    },
    {
        id: 'discount',
        name: 'Personal Discount',
        icon: 'DiscountIcon',
        url: '/discount',
        disabled: false
    },
    ],
    isFetching: true,
    points: 0,
    rules: [],
    currentRule: {
        index: 0,
        rule: {}
    },
    discountCode: '',
    discountGenerated: false,
    discountValue: null,
    referralLink: '',
    readyReferrals: null,
    referralList: [],
    referralStatus: 0,
    filterOptions: [{
        "id": 0,
        "name": "All"
    },
    {
        "id": 1,
        "name": "New referral"
    },
    {
        "id": 2,
        "name": "In progress"
    },
    {
        "id": 3,
        "name": "Ready to use"
    },
    {
        "id": 4,
        "name": "Discount generated"
    },
    {
        "id": 5,
        "name": "Discount used"
    },
    {
        "id": 6,
        "name": "Discount expired"
    }
    ]
}

export default function loyaltyReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_DISCOUNT_RULES:
            return {
                ...state,
                points: payload.points,
                rules: payload.rules
            }

        case FETCH_DISCOUNT_LIST:
            return {
                ...state,
                discountCode: payload,
                discountGenerated: true
            }

        case STOP_LOYALTY_FETCHING:
            return {
                ...state,
                isFetching: false
            }

        case SET_CURRENT_RULE:
            return {
                ...state,
                currentRule: {
                    ...state.currentRule,
                    index: payload.index,
                    rule: payload.rule
                }
            }

        case CREATE_DISCOUNT:
            return {
                ...state,
                discountCode: payload,
                discountGenerated: true
            }

        case FETCH_REFERRAL_LINK:
            return {
                ...state,
                referralLink: `/order?${payload.referralInfo[0].url}`,
                discountValue: payload.maxAmountDiscount
            }

        case GET_READY_REFERRALS:
            return {
                ...state,
                readyReferrals: payload
            }

        case FETCH_REFERRALS:
            return {
                ...state,
                referralList: payload
            }

        case REF_DISCOUNT_GENERATION_SUCCESS:
            return {
                ...state,
                referralList: state.referralList.map(item => {

                    if (item.id === payload.id) {
                        return payload;

                    } else {
                        return item;
                    }
                })
            }

        case SET_REFERRAL_FILTER:
            return {
                ...state,
                referralStatus: payload
            }

        default:
            return state;
    }
}