import {
    SET_FEEDBACK_COMMENT,
    SET_FEEDBACK_RATING,
    CLEAR_FEEDBACK_INFO
} from 'store/actionTypes';

export const initialState = {
    rating: 0,
    comment: '',
}

export default function feedbackReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_FEEDBACK_COMMENT:
            return {
                ...state,
                comment: payload
            }

        case SET_FEEDBACK_RATING:
            return {
                ...state,
                rating: payload
            }

        case CLEAR_FEEDBACK_INFO:
            return {
                ...state,
                rating: 0,
                comment: ''
            }

        default:
            return state
    }
}