import { SET_TEXTFIELD_BLUR, SET_TEXTFIELD_FOCUS } from 'store/orderform/orderformActionTypes';
import {
    FETCH_REVISION_CATEGORIES,
    FETCH_REVISION_LEVELS,
    FETCH_REVISION_LIST,
    FETCH_REVISION_BY_ID,
    FETCH_REVISION_CUSTOMER_FILES,
    FETCH_REVISION_WRITER_FILES,
    CLEAR_REVISIONS,
    CLEAR_ACTIVE_REVISION,
    ADD_REVISION_FILE,
    ADD_REVISION_FILE_ERROR,
    CLEAR_REVISION_FILE,
    SET_REVISION_LEVEL,
    TOGGLE_MODAL,
    CHECK_REVISION_POLICY,
    SET_REVISION_CATEGORY,
    SET_REVISION_DESCRIPTION
} from 'store/actionTypes';

export const initialState = {
    id: 'description',
    categoriesList: [],
    levelsList: [],
    revisions: {},
    activeRevision: {},
    revisionFiles: [],
    validFileTypes: ['.txt', '.zip', '.doc', '.docx', '.xls', '.xlsx', '.rtf', '.jpeg', '.png', '.gif', '.ppt', '.pptx', '.csv', '.pdf', '.jpg', '.odt', '.rar', '.zipx', '.7z', '.gz', '.gzip', '.tar', '.tar-gz'],
    uploadedFile: {},
    uploadedFileSize: '',
    uploadedFileName: '',
    pickedRevisionLevel: 0,
    acceptPolicy: false,
    pickedRevisionCategory: [],
    revisionDescription: '',
    isFocused: false
}

export default function revisionReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_REVISION_CATEGORIES:
            return {
                ...state,
                categoriesList: payload
            };

        case FETCH_REVISION_LEVELS:
            return {
                ...state,
                levelsList: payload
            };

        case FETCH_REVISION_LIST:
            return {
                ...state,
                revisions: payload
            };

        case FETCH_REVISION_BY_ID:
            return {
                ...state,
                activeRevision: payload
            };

        case FETCH_REVISION_CUSTOMER_FILES:
            return {
                ...state,
                revisionFiles: [...state.revisionFiles, ...payload]
            };

        case FETCH_REVISION_WRITER_FILES:
            return {
                ...state,
                revisionFiles: [...state.revisionFiles, ...payload]
            };

        case CLEAR_REVISIONS:
            return {
                ...state,
                revisions: {},
                activeRevision: {}
            };

        case CLEAR_ACTIVE_REVISION:
            return {
                ...state,
                activeRevision: {},
                revisionFiles: []
            };

        case ADD_REVISION_FILE:
            return {
                ...state,
                uploadedFile: payload.fileObj,
                uploadedFileSize: payload.fileSize,
                uploadedFileName: payload.fileName
            };

        case ADD_REVISION_FILE_ERROR:
            return {
                ...state,
                uploadedFile: {},
                uploadedFileSize: '',
                uploadedFileName: ''
            };

        case CLEAR_REVISION_FILE:
            return {
                ...state,
                uploadedFile: {},
                uploadedFileSize: '',
                uploadedFileName: ''
            };

        case SET_REVISION_LEVEL:
            return {
                ...state,
                pickedRevisionLevel: payload
            };

        case TOGGLE_MODAL:
            return {
                ...state,
                uploadedFile: {},
                uploadedFileSize: '',
                uploadedFileName: '',
                pickedRevisionLevel: 0,
                acceptPolicy: false,
                pickedRevisionCategory: [],
                revisionDescription: ''
            };

        case CHECK_REVISION_POLICY:
            return {
                ...state,
                acceptPolicy: !state.acceptPolicy
            };

        case SET_REVISION_CATEGORY:
            return {
                ...state,
                pickedRevisionCategory: state.pickedRevisionCategory.includes(payload)
                    ? state.pickedRevisionCategory.filter(item => (item !== payload))
                    : [...state.pickedRevisionCategory, payload]
            };

        case SET_REVISION_DESCRIPTION:
            return {
                ...state,
                revisionDescription: payload
            };

        case SET_TEXTFIELD_BLUR + '_' + state.id:
            return {
                ...state,
                isFocused: false
            };

        case SET_TEXTFIELD_FOCUS + '_' + state.id:
            return {
                ...state,
                isFocused: true
            };

        default:
            return state;
    }
}