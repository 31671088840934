import { FETCH_PAPER_TYPE, SET_WORK_TYPE, SET_DEFAULT_VALUE } from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';
import reducerLogic from 'store/orderform/controls/select/selectReducer';

export const initialState = {
    id: 'paperType',
    name: 'paperType',
    label: 'Assignment Nature',
    tooltip: 'Select the type of assignment. If it is not listed, contact us via live chat and we will help.',
    value: 18,
    error: '',
    searchValue: '',
    options: [],
    disabledOptions: [17],
    filteredOptions: [],
    searchDisabled: false,
    dropdownOpen: false,
    selectDisabled: false,
    alreadyFetched: false
}

export default function paperTypeReducer(state = initialState, action) {

    if (action.type === FETCH_PAPER_TYPE) {

        // Fierce crutch
        const fixedPaperTypeOptions = action.payload.map(({id, name}) => ({
            id,
            name: name === 'Essay (Any Type)' ? 'Essay' : name
        }))

        return {
            ...state,
            options: fixedPaperTypeOptions,
            alreadyFetched: true
        };

    } else if (action.type === SET_WORK_TYPE) {

        if (action.payload === '3') {
            return {
                ...state,
                value: 17,
                selectDisabled: true
            }

        } else if (state.value === 17) {
            return {
                ...state,
                value: 1,
                selectDisabled: false
            }

        } else {
            return state;
        }

    } else if (action.type === SET_DEFAULT_VALUE) {
        return {
            ...state,
            value: action.payload.paperType || state.value
        }
    } else if (action.type === CLEAR_ORDERFORM) {
        return {
            ...state,
            value: action.payload.paperType,
            selectDisabled: false
        }
    } else {
        return reducerLogic(state, action);
    }
}