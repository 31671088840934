import React from 'react';

export default function WatchIcon() {
    return (
        <svg viewBox="0 0 16 16">
            <g>
                <path d="M8,0C3.582,0,0,3.582,0,8s3.582,8,8,8s8-3.582,8-8S12.418,0,8,0z M8,14c-3.308,0-6-2.692-6-6s2.692-6,6-6
                    s6,2.692,6,6S11.308,14,8,14z"/>
                <polygon points="9,5 7,5 7,9 10,12 11,10 9,8 	" />
            </g>
        </svg>
    )
}
