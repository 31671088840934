import { GET_ORDERS_COUNT } from "store/actionTypes";

export const initialState = {
    links: [{
        id: 'new-order',
        name: 'New Order',
        icon: 'NewOrderIcon',
        url: '/order',
        counter: false,
        disabled: false
    },
    {
        id: 'orders',
        name: 'All Orders',
        icon: 'OrdersIcon',
        url: '/dashboard',
        counter: false,
        disabled: false
    },
    // {
    //     id: 'transactions',
    //     name: 'Transactions',
    //     icon: 'TransactionsIcon',
    //     url: '/transactions',
    //     counter: false,
    //     disabled: false
    // },
    // {
    //     id: 'bonuses',
    //     name: 'Earn $30',
    //     icon: 'BonusesIcon',
    //     url: '/loyalty',
    //     counter: false,
    //     disabled: false
    // },
    {
        id: 'settings',
        name: 'Settings',
        icon: 'SettingsIcon',
        url: '/profile',
        counter: false,
        disabled: false
    },
    ]
}

export default function navReducer(state = initialState, { type, payload }) {
    switch (type) {
        case GET_ORDERS_COUNT:
            return {
                ...state,
                links: state.links.map(item => {
                    if (item.id === 'orders') {
                        item.counter = payload ? payload : false;
                        return item;

                    } else {
                        return item;
                    }
                })
            };

        default:
            return state;
    }
}