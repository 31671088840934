import React from 'react';

export default function BonusesIcon() {
    return (
        <svg viewBox="0 0 16 16">
            <g>
                <g>
                    <path d="M8,2c3.308,0,6,2.692,6,6s-2.692,6-6,6s-6-2.692-6-6S4.692,2,8,2 M8,0C3.582,0,0,3.582,0,8s3.582,8,8,8
                        s8-3.582,8-8S12.418,0,8,0L8,0z"/>
                </g>
                <path d="M8.532,4.434l0.687,2.113h2.222c0.541,0,0.766,0.693,0.328,1.011L9.971,8.865l0.687,2.113
                    c0.167,0.515-0.422,0.943-0.86,0.625L8,10.297l-1.798,1.306c-0.438,0.318-1.027-0.11-0.86-0.625l0.687-2.113L4.231,7.559
                    C3.793,7.24,4.019,6.548,4.56,6.548h2.222l0.687-2.113C7.636,3.92,8.364,3.92,8.532,4.434z"/>
            </g>
        </svg>
    )
}
