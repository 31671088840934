import {
    TOGGLE_ONE_PAGE_SUMMARY,
    SET_DEFAULT_VALUE,
    DISABLE_ONE_PAGE_SUMMARY,
    ENABLE_ONE_PAGE_SUMMARY,
    SET_WORK_TYPE
} from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

export const initialState = {
    id: 'onepagesummary',
    value: 1,
    title: '1-page summary',
    tooltip: 'One-page summary helps to condense just the most important info from your paper into an easy to view and review format, making it fast for you to read and remember what is important when defending your work.',
    disabled: false
}

export const ONE_PAGE_SUMMARY = {
    DISABLE: 1,
    ENABLE: 2
}

export default function onePageSummaryReducer(state = initialState, { type, payload }) {

    switch (type) {
        case TOGGLE_ONE_PAGE_SUMMARY:
            return {
                ...state,
                value: state.value === 1 ? ONE_PAGE_SUMMARY.ENABLE : ONE_PAGE_SUMMARY.DISABLE
            };

        case SET_DEFAULT_VALUE:
            return {
                ...state,
                value: payload.onePageSummary || state.value
            };

        case DISABLE_ONE_PAGE_SUMMARY:
            return {
                ...state,
                disabled: true,
                value: ONE_PAGE_SUMMARY.DISABLE,
            };

        case ENABLE_ONE_PAGE_SUMMARY:
            return {
                ...state,
                disabled: false
            };

        case SET_WORK_TYPE:
            if (payload === '3') {
                return {
                    ...state,
                    disabled: true,
                    value: ONE_PAGE_SUMMARY.DISABLE
                }
            } else {
                return {
                    ...state,
                    disabled: false
                }
            }

        case CLEAR_ORDERFORM:
            return {
                ...state,
                value: payload.onePageSummary,
                disabled: false
            };

        default:
            return state;
    }
}