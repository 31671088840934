import { axios } from '../helpers/axios';
import { tokenService } from '../services/tokenService';

export const authService = {
  login,
  logout,
  check,
  refresh,
};

function login(username, password) {
  const credentials = { username, password };
  return axios
    .post('/auth/login', credentials)
    .then(({ data: tokens }) => {
      if (!tokens) {
        return Promise.reject(new Error('Bad response from server'));
      }
      tokenService.setTokens(tokens);
      return tokens;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function logout() {
  return axios
    .post('/auth/logout')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function check() {
  return axios
    .get('/auth/check')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

function refresh() {
  console.log('Refreshing Accesss-token');
  tokenService.getRefreshToken();
  return axios
    .get('/auth/refresh')
    .then(({ data }) => {
      console.log('Access token refreshed successfully');
      const { accessToken } = data;
      tokenService.setTokens({ accessToken });
      return data;
    })
    .catch((error) => {
      console.error('CATCHED FAILED', error);
      return Promise.reject(error);
    });
}
