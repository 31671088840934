import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import NewOrderIcon from '../img/NewOrderIcon';
import OrdersIcon from '../img/OrdersIcon';
import TransactionsIcon from '../img/TransactionsIcon';
import SettingsIcon from '../img/SettingsIcon';
import BonusesIcon from '../img/BonusesIcon';
import DetailsIcon from '../img/DetailsIcon';
import FilesIcon from '../img/FilesIcon';
import MessagesIcon from '../img/MessagesIcon';
import RevisionsIcon from '../img/RevisionsIcon';
import DiscountIcon from '../img/DiscountIcon';
import ReferralIcon from '../img/ReferralIcon';

const types = {
    NewOrderIcon,
    OrdersIcon,
    TransactionsIcon,
    SettingsIcon,
    BonusesIcon,
    DetailsIcon,
    FilesIcon,
    MessagesIcon,
    RevisionsIcon,
    DiscountIcon,
    ReferralIcon,
};

/**
 * @name TabLinksNew
 * @component
 * @category Dashboard
 * @subcategory Components
 * @hideconstructor
 */
export default function TabLinksNew({ links, baseUrl, orderId }) {
    //TODO: Поменять иконки
    return (
        <ul className="tablinks-header__list">
            {links.map((link, index) => {
                const ImageHolder = types[link.icon];
                const url = orderId ? `${baseUrl}/${orderId}` : `${baseUrl}`;

                if (link.disabled) {
                    return null;

                } else {
                    return (
                        <li
                            key={index}
                        >
                            <NavLink
                                id={link.id}
                                className="tablinks-header__link"
                                to={`${url}${link.url}`}
                            >
                                <ImageHolder />
                                <span className="tablinks-header__label">{link.name}</span>
                                {link.counter &&
                                    <span
                                        className={`tablinks-header__counter ${link.id}`}
                                        data-tooltip={link.tooltip}
                                    >
                                        [<span className="counter-value">{link.counter}</span>]
                                    </span>
                                }
                            </NavLink>
                        </li>
                    )
                }

            })}
        </ul>
    )
}

TabLinksNew.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        icon: PropTypes.string,
        url: PropTypes.string
    })),
    orderId: PropTypes.string,
    baseUrl: PropTypes.string
}