import React from 'react';

export default function TransactionsIcon() {
    return (
        <svg viewBox="0 0 16 16">
            <g>
                <path d="M8,2c3.308,0,6,2.692,6,6s-2.692,6-6,6s-6-2.692-6-6S4.692,2,8,2 M8,0C3.582,0,0,3.582,0,8s3.582,8,8,8
                s8-3.582,8-8S12.418,0,8,0L8,0z"/>
                <g>
                    <path d="M9.651,5.302c0.255,0.181,0.454,0.382,0.596,0.604c0.143,0.222,0.22,0.437,0.231,0.644
                    c0,0.053-0.018,0.099-0.055,0.138c-0.037,0.039-0.077,0.058-0.122,0.058h-1.1c-0.078,0-0.14-0.01-0.185-0.031
                    C8.973,6.695,8.922,6.658,8.867,6.604c-0.011-0.012-0.048-0.052-0.109-0.12C8.696,6.416,8.599,6.355,8.468,6.302
                    c-0.132-0.053-0.284-0.08-0.458-0.08c-0.241,0-0.438,0.034-0.592,0.102C7.264,6.392,7.187,6.51,7.187,6.676
                    c0,0.119,0.047,0.212,0.143,0.28c0.095,0.068,0.242,0.129,0.441,0.182c0.198,0.053,0.544,0.133,1.037,0.24
                    c0.632,0.136,1.097,0.35,1.394,0.64c0.297,0.291,0.445,0.706,0.445,1.245c0,0.486-0.168,0.893-0.504,1.222
                    c-0.336,0.329-0.787,0.544-1.352,0.644v0.631c0,0.065-0.022,0.122-0.067,0.169C8.679,11.976,8.626,12,8.565,12h-1.1
                    c-0.062,0-0.115-0.024-0.159-0.071c-0.045-0.047-0.067-0.104-0.067-0.169v-0.658c-0.347-0.083-0.653-0.215-0.919-0.395
                    c-0.266-0.181-0.473-0.39-0.621-0.627S5.472,9.603,5.466,9.36c0-0.053,0.018-0.099,0.055-0.138
                    c0.036-0.038,0.077-0.058,0.122-0.058h1.1c0.123,0,0.229,0.042,0.319,0.125c0.017,0.018,0.062,0.069,0.134,0.155
                    C7.268,9.53,7.379,9.608,7.527,9.676c0.148,0.068,0.323,0.102,0.525,0.102c0.694,0,1.041-0.157,1.041-0.471
                    c0-0.101-0.049-0.185-0.147-0.254C8.848,8.985,8.71,8.927,8.531,8.88C8.352,8.833,8.066,8.764,7.674,8.676l-0.378-0.08
                    c-0.571-0.13-0.991-0.348-1.26-0.653C5.769,7.637,5.634,7.23,5.634,6.72c0-0.421,0.144-0.798,0.432-1.133
                    c0.288-0.335,0.678-0.565,1.171-0.689V4.24c0-0.065,0.023-0.122,0.067-0.169C7.35,4.024,7.403,4,7.464,4h1.1
                    c0.061,0,0.115,0.024,0.159,0.071C8.769,4.118,8.791,4.175,8.791,4.24v0.667C9.11,4.99,9.397,5.121,9.651,5.302z"/>
                </g>
            </g>
        </svg>
    )
}
