import { FETCH_DEFAULT_SETTINGS, SET_DEFAULT_VALUE } from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

import reducerLogic from 'store/orderform/textfield/textFieldReducer';

export const initialState = {
    id: 'paperDetails',
    type: 'textarea',
    value: '',
    error: '',
    placeholder: 'Please provide specific and detailed instructions about your order.',
    label: 'Additional Details',
    tooltip: 'Provide the instructions and make sure they are complete and accurate. Upload any necessary files.',
    isRequired: false,
    isFocused: false
}

export default function paperDetailsReducer(state = initialState, action) {

    if (action.type === FETCH_DEFAULT_SETTINGS) {

        if (action.payload.siteCategories[0] === 3) {
            return {
                ...state,
                label: 'Details',
                placeholder: 'Please provide specific and detailed information about your professional background.'
            }

        } else {
            return state;
        }

    } else if (action.type === CLEAR_ORDERFORM) {
        return {
            ...state,
            value: action.payload.paperDetails
        };

    } else if (action.type === SET_DEFAULT_VALUE) {
        return {
            ...state,
            value: action.payload.paperDetails || initialState.value
        };

    } else {
        return reducerLogic(state, action);
    }
}
