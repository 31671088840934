import {
    FETCH_MESSAGES,
    SET_MESSENGER_VIEW,
    CLEAR_MESSAGES_INFO,
    FETCH_MESSAGES_FILES,
    SEND_MESSAGE,
    SET_MESSAGE_VALUE,
    CLEAR_MESSAGE_VALUE,
    FETCH_MESSAGES_STATUS,
    SET_WRITER_MESSAGES_STATUS,
    SET_SUPPORT_MESSAGES_STATUS,
    FETCH_UNREAD_ORDERS,
    INITIAL_FETCH
} from "store/actionTypes";


export const initialState = {
    orderId: '',
    chatViewActive: 'writer',
    isFetching: false,
    initFetching: false,
    supportChat: [],
    writerChat: [],
    files: [],
    messageValue: '',
    supportUnreadCount: 0,
    writerUnreadCount: 0,
    lastReadSupportMessage: 0,
    lastReadWriterMessage: 0,
    ordersWithUnreadMessage: [],
};

export default function messengerReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_MESSAGES:
            return {
                ...state,
                orderId: payload.orderId,
                supportChat: payload.supportChat,
                writerChat: payload.writerChat,
                supportUnreadCount: payload.supportUnreadCount,
                writerUnreadCount: payload.writerUnreadCount,
                lastReadSupportMessage: payload.lastReadSupportMessage,
                lastReadWriterMessage: payload.lastReadWriterMessage
            }

        case FETCH_MESSAGES_STATUS:
            return {
                ...state,
                isFetching: !state.isFetching,
            }

        case INITIAL_FETCH:
            return {
                ...state,
                initFetching: true
            }

        case FETCH_UNREAD_ORDERS:
            return {
                ...state,
                ordersWithUnreadMessage: payload
            }

        case SET_WRITER_MESSAGES_STATUS:
            return {
                ...state,
                writerUnreadCount: 0,
                lastReadWriterMessage: 0,
                ordersWithUnreadMessage: state.ordersWithUnreadMessage.filter(item => item.id_order !== +payload)
            }

        case SET_SUPPORT_MESSAGES_STATUS:
            return {
                ...state,
                supportUnreadCount: 0,
                lastReadSupportMessage: 0,
                ordersWithUnreadMessage: state.ordersWithUnreadMessage.filter(item => item.id_order !== +payload)
            }

        case SET_MESSENGER_VIEW:
            return {
                ...state,
                chatViewActive: payload
            }

        case CLEAR_MESSAGES_INFO:
            return {
                ...state,
                supportChat: [],
                writerChat: [],
                files: [],
                initFetching: false,
                chatViewActive: 'writer'
            }

        case FETCH_MESSAGES_FILES:
            return {
                ...state,
                files: payload
            }

        case SEND_MESSAGE:
            return {
                ...state,
                messageValue: ''
            }

        case SET_MESSAGE_VALUE:
            return {
                ...state,
                messageValue: payload
            }

        case CLEAR_MESSAGE_VALUE:
            return {
                ...state,
                messageValue: ''
            }

        default:
            return state
    }
}