/**
 * @module OrderformFileActions
 */
import {
    ADD_FILE,
    UPLOAD_FILE,
    UPLOAD_FILE_ERROR,
    DELETE_FILE,
    CLEAR_FILES,
    CLICK_ON_FILE_INPUT,
    SECOND_STEP_VALIDATION,
    GET_CUSTOMERS_FILES_ON_EDIT
} from 'store/orderform/orderformActionTypes';

import { validateSecondStep } from 'orderform/utils/orderformUtils';
import { notify } from 'helpers/notification';
import { achieveGoals } from 'store/metricaActions';
import { customersFilesURL } from 'store/config';
import http from 'services/http';

/** */
export function addFile(e) {
    return function (dispatch) {
        e.preventDefault();
        e.target.blur();

        dispatch({
            type: CLEAR_FILES
        })

        dispatch({
            type: ADD_FILE,
            payload: {
                id: `id_${Date.now()}`
            }
        });
    }
}

/** */
export function clickOnFile(id) {
    return function (dispatch) {
        dispatch({
            type: CLICK_ON_FILE_INPUT,
            payload: {
                id: id
            }
        });
    }
}

/** */
export function uploadFile(e) {
    return function (dispatch, getState) {
        const { orderform: { file: { validTypes } } } = getState();
        let fileSize = e.target.files[0].size,
            calcSize;

        function formatSize(size, unit) {
            return (
                calcSize = ((fileSize / size) % 1 === 0) ?
                    `${fileSize / size}${unit}` :
                    `${(fileSize / size).toFixed(1)}${unit}`
            )
        }

        function fileValidation(validTypes, fileName) {
            const extention = fileName && fileName.toLowerCase().substring(fileName.lastIndexOf('.'), fileName.length);
            const result = extention && validTypes.some((item) => item === extention);
            return !!result;
        }

        if (!fileValidation(validTypes, e.target.files[0].name)) {
            dispatch({
                type: UPLOAD_FILE_ERROR,
                payload: { id: e.target.id }
            })

            notify.warn(`${validTypes.join(', ')}`, 'Invalid file format. Acceptable file formats:');
            return;
        }

        if (fileSize > 104857600) {
            dispatch({
                type: UPLOAD_FILE_ERROR,
                payload: { id: e.target.id }
            })

            notify.warn('Maximum file size is 100MB')
            return;
        }

        if (fileSize < 1024) {
            calcSize = `${fileSize}b`;

        } else if (fileSize < 1048576) {
            formatSize(1024, 'kb');

        } else {
            formatSize(1048576, 'mb');
        }

        dispatch(achieveGoals('upload_file'));

        dispatch({
            type: UPLOAD_FILE,
            payload: {
                id: e.target.id,
                fileObj: e.target.files[0],
                fileValue: e.target.value,
                fileName: e.target.files[0].name,
                fileSize: calcSize
            }
        });

        validateSecondStep(SECOND_STEP_VALIDATION, dispatch, getState);
    }
}

/** */
export function deleteFile(id, remote, e) {
    return function (dispatch, getState) {
        dispatch({
            type: DELETE_FILE,
            payload: { id, remote }
        });

        dispatch(achieveGoals('delete_file'));
        validateSecondStep(SECOND_STEP_VALIDATION, dispatch, getState);
    }
}

/** */
export function getCustomersFilesOnEdit(orderId, resolve) {
    return function (dispatch) {
        return http
            .get(customersFilesURL(orderId))
            .then(data => {
                const list = data?.length
                    ? data.reduce((acc, curr) => {
                        return {
                            ...acc,
                            [curr.id]: {
                                id: curr.id.toString(),
                                fileName: curr.name,
                                remote: true
                            }
                        }
                    }, {})
                    : {}

                const fileObjList = Object.values(list).map(item => ({ id: item.id, fileObj: {}, remote: true }))

                dispatch({
                    type: GET_CUSTOMERS_FILES_ON_EDIT,
                    payload: { list, fileObjList }
                })

                resolve && resolve();

            })
            .catch(err => console.log(err))

        // return fetch(customersFilesURL(orderId))
        //     .then(response => response.json())
        //     .then(data => {
        //         const list = data?.length
        //             ? data.reduce((acc, curr) => {
        //                 return {
        //                     ...acc,
        //                     [curr.id]: {
        //                         id: curr.id.toString(),
        //                         fileName: curr.name,
        //                         remote: true
        //                     }
        //                 }
        //             }, {})
        //             : {}

        //         const fileObjList = Object.values(list).map(item => ({ id: item.id, fileObj: {}, remote: true }))

        //         dispatch({
        //             type: GET_CUSTOMERS_FILES_ON_EDIT,
        //             payload: { list, fileObjList }
        //         })

        //         resolve && resolve();

        //     })
        //     .catch(err => console.log(err));
    }
}