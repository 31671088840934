import {
    ENABLE_DISCOUNT,
    DISABLE_DISCOUNT,
    SET_DISCOUNT_CODE,
    TRANSFER_DISCOUNT_CODE,
    CLEAR_DISCOUNT_CODE,
    CHECK_DISCOUNT_SUCCESS,
    CHECK_DISCOUNT_ERROR,
    TOGGLE_DISCOUNT_WITH_TOP_WRITER
} from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

export const initialState = {
    code: '',
    amount: 0,
    percent: 0,
    isApplyed: false,
    discountVisible: false,
    isTopWriterDiscount: false
}

export default function discountReducer(state = initialState, { type, payload }) {
    switch (type) {
        case ENABLE_DISCOUNT:
            return {
                ...state,
                discountVisible: true
            };

        case DISABLE_DISCOUNT:
            return {
                ...state,
                code: '',
                discountVisible: false
            };


        case SET_DISCOUNT_CODE:
            return {
                ...state,
                code: payload
            };

        case CLEAR_DISCOUNT_CODE:
            return {
                ...state,
                code: '',
                amount: 0,
                percent: 0,
                isApplyed: false,
                isTopWriterDiscount: false
            };

        case CHECK_DISCOUNT_SUCCESS:
            return {
                ...state,
                code: payload.code,
                amount: payload.amount,
                percent: payload.percent,
                isApplyed: true
            };

        case CHECK_DISCOUNT_ERROR:
            return {
                ...state,
                code: payload.code,
                amount: payload.amount,
                percent: payload.percent,
                isApplyed: false
            };

        case TRANSFER_DISCOUNT_CODE:
            return {
                ...state,
                code: payload || state.code
            }

        case TOGGLE_DISCOUNT_WITH_TOP_WRITER:
            return {
                ...state,
                isTopWriterDiscount: !state.isTopWriterDiscount
            }

        case CLEAR_ORDERFORM:
            return {
                ...state,
                code: payload.discountCode,
                isApplyed: false,
                percent: 0,
                discountVisible: false
            }

        default:
            return state;
    }
}