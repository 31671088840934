import {
    FETCH_MESSAGE_HISTORY,
    SET_UNREAD_COUNT,
    TOGGLE_CURRENT_CHAT,
    SET_ORDER_MESSAGE_VALUE,
    PUSH_MESSAGE,
    GO_NEW_LINE,
    SET_CURRENT_CHAT,
    RESET_CHAT,
    ADD_FILE_TO_SEND_QUEUE,
    CLEAR_SEND_QUEUE_FILES,
    CLICK_ON_SEND_QUEUE_FILE_INPUT,
    UPLOAD_SEND_QUEUE_FILE,
    UPLOAD_SEND_QUEUE_FILE_ERROR,
    DELETE_SEND_QUEUE_FILE,
    READ_MESSAGES,
    UPDATE_CHAT_HISTORY,
    UPDATE_CHAT,
    APPEND_MESSAGE,
    UPDATE_UNREAD,
    SET_UNREAD_LIST,
    CLEAR_BEFORE_HISTORY_FETCH,
    SET_ONLINE_STATUS,
    LOCK,
    UNLOCK,
    SET_EMOJI,
    TOGGLE_TYPE
} from 'store/actionTypes';

export const initialState = {
    isFetching: true,
    isSending: false,
    isTyping: {
        support: false,
        writer: false
    },
    currentChat: 'writer',
    chatHistory: {
        support: [],
        writer: []
    },
    chat: {
        support: [],
        writer: []
    },
    messageValue: '',
    onlineStatus: {
        support: true,
        writer: false
    },
    canSendMessages: true,
    unreadMessages: {
        support: 0,
        writer: 0
    },
    unreadList: {},
    validTypes: ['.txt', '.zip', '.doc', '.docx', '.xls', '.xlsx', '.rtf', '.jpeg', '.png', '.gif', '.ppt', '.pptx', '.csv', '.pdf', '.Pages', '.jpg', '.odt', '.rar', '.zipx', '.7z', '.gz', '.gzip', '.tar', '.tar-gz'],
    files: {},
    fileObjects: []
}

export default function orderMessengerReducer(state = initialState, { type, payload }) {
    const deepFilesCopy = JSON.parse(JSON.stringify(state.files));

    switch (type) {
        case FETCH_MESSAGE_HISTORY:
            return {
                ...state,
                chatHistory: {
                    ...state.chatHistory,
                    support: payload.supportChat,
                    writer: payload.writerChat
                },
                isFetching: false
            }

        case SET_UNREAD_COUNT:
            return {
                ...state,
                unreadMessages: {
                    ...state.unreadMessages,
                    support: payload.supportCount,
                    writer: payload.writerCount
                }
            }

        case UPDATE_UNREAD:
            return {
                ...state,
                unreadMessages: {
                    ...state.unreadMessages,
                    support: payload.user,
                    writer: payload.writer
                }
            }

        case SET_UNREAD_LIST:
            return {
                ...state,
                unreadList: {
                    ...state.unreadList,
                    ...payload
                }
            }

        case TOGGLE_CURRENT_CHAT:
            return {
                ...state,
                currentChat: state.currentChat === 'writer' ? 'support' : 'writer'
            }

        case SET_CURRENT_CHAT:
            return {
                ...state,
                currentChat: payload || state.currentChat
            }

        case SET_ONLINE_STATUS:
            return {
                ...state,
                onlineStatus: {
                    ...state.onlineStatus,
                    writer: payload
                }
            }

        case SET_ORDER_MESSAGE_VALUE:
            return {
                ...state,
                messageValue: payload
            }

        case PUSH_MESSAGE:
            return {
                ...state,
                messageValue: '',
                files: {},
                fileObjects: []
            }

        case SET_EMOJI:
            return {
                ...state,
                messageValue: state.messageValue + payload
            }

        case LOCK:
            return {
                ...state,
                isSending: true
            }

        case UNLOCK:
            return {
                ...state,
                isSending: false
            }

        case UPDATE_CHAT_HISTORY:
            return {
                ...state,
                chatHistory: {
                    ...state.chatHistory,
                    [payload.incommingChatName]: state.chatHistory[payload.incommingChatName].map(item => {
                        if (item.id === payload.incommingMessageId) {
                            return payload.message;

                        } else {
                            return item;
                        }
                    })
                }
            }

        case UPDATE_CHAT:
            return {
                ...state,
                chat: {
                    ...state.chat,
                    [payload.incommingChatName]: state.chat[payload.incommingChatName].map(item => {
                        if (item.id === payload.incommingMessageId) {
                            return payload.message;

                        } else {
                            return item;
                        }
                    })
                }
            }

        case APPEND_MESSAGE:
            return {
                ...state,
                chat: {
                    ...state.chat,
                    [payload.incommingChatName]: [...state.chat[payload.incommingChatName], payload.message]
                }
            }

        case GO_NEW_LINE:
            return {
                ...state,
                messageValue: state.messageValue + '\n'
            }

        case RESET_CHAT:
            return {
                ...state,
                ...initialState
            }

        case CLEAR_BEFORE_HISTORY_FETCH:
            return {
                ...state,
                chatHistory: {
                    ...state.chatHistory,
                    support: [],
                    writer: []
                },
                chat: {
                    ...state.chat,
                    support: [],
                    writer: []
                }
            }

        case READ_MESSAGES:
            return {
                ...state,
                unreadMessages: {
                    ...state.unreadMessages,
                    [payload.sender]: payload.count
                },
                chatHistory: {
                    ...state.chatHistory,
                    [payload.sender]: state.chatHistory[payload.sender].map(message => {
                        if (payload.readMessagesId.includes(message.id)) {
                            message.state.id = 2;

                            return message;

                        } else {
                            return message;
                        }
                    })
                },
                chat: {
                    ...state.chat,
                    [payload.sender]: state.chat[payload.sender].map(message => {
                        if (payload.readMessagesId.includes(message.id)) {
                            message.state.id = 2;

                            return message;

                        } else {
                            return message;
                        }
                    })
                },
            }

        case TOGGLE_TYPE:
            return {
                ...state,
                isTyping: {
                    ...state.isTyping,
                    [payload.name]: payload.state
                }
            }

        // ============= Files ===========
        case ADD_FILE_TO_SEND_QUEUE:
            return {
                ...state,
                files: {
                    ...state.files,
                    [payload.id]: {
                        id: payload.id,
                    }
                }
            };

        case CLICK_ON_SEND_QUEUE_FILE_INPUT:
            return {
                ...state,
                files: {
                    ...state.files,
                    [payload.id]: {
                        ...state.files[payload.id],
                        isClicked: true
                    }
                }
            };

        case CLEAR_SEND_QUEUE_FILES:
            for (let key in deepFilesCopy) {

                if (!deepFilesCopy[key].fileName) {
                    delete deepFilesCopy[key];
                }
            }

            return {
                ...state,
                files: deepFilesCopy
            };

        case UPLOAD_SEND_QUEUE_FILE:
            return {
                ...state,
                files: {
                    ...state.files,
                    [payload.id]: {
                        ...state.files[payload.id],
                        id: payload.id,
                        fileValue: payload.fileValue,
                        fileName: payload.fileName,
                        fileSize: payload.fileSize
                    }
                },
                fileObjects: [
                    ...state.fileObjects,
                    {
                        id: payload.id,
                        fileObj: payload.fileObj,
                    }
                ]
            };

        case UPLOAD_SEND_QUEUE_FILE_ERROR:
            delete deepFilesCopy[payload.id];

            return {
                ...state,
                files: deepFilesCopy,
                fileObjects: state.fileObjects.filter(item => item.id !== payload.id)
            };

        case DELETE_SEND_QUEUE_FILE:
            delete deepFilesCopy[payload.id];

            return {
                ...state,
                files: deepFilesCopy,
                fileObjects: state.fileObjects.filter(item => item.id !== payload.id)
            };

        // ===============================

        default:
            return state;
    }
}