import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * @name -revisions/ModalNav
 * @component
 * @category Dashboard
 * @subcategory Orderinfo
 * @hideconstructor
 * @memberof Revisions
 */
export default function ModalNav({ activeTab, firstStepValid, secondStepValid, setActiveTab }) {
    const initialState = [
        { id: '1', text: 'Revision Policy', tab: 'FirstStep', visited: true },
        { id: '2', text: 'Nature of revision', tab: 'SecondStep', visited: false },
        { id: '3', text: 'Time-frame', tab: 'ThirdStep', visited: false }
    ]
    const [links, setLinks] = useState(initialState);

    useEffect(() => {
        setLinks(links.map(link => {
            if (activeTab === link.tab) {
                link.visited = true;
                return link;

            } else {
                return link;
            }
        }))

    }, [activeTab]) // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * @param {string} tabName 
     */
    const handleClick = (tabName) => {
        if (tabName === 'SecondStep' && firstStepValid) {
            setActiveTab(tabName);

        } else if (tabName === 'ThirdStep' && secondStepValid) {
            setActiveTab(tabName);

        } else if (tabName === 'FirstStep') {
            setActiveTab(tabName);
        }
    }

    return (
        <ul className="modalnav__list">
            {links.map(link => (
                <li className="modalnav__item" key={link.id}>
                    <button
                        className={`modalnav__link ${link.visited ? 'visited' : ''} ${activeTab === link.tab ? 'active' : ''}`}
                        onClick={handleClick.bind(null, link.tab)}
                        data-link-number={activeTab === link.tab ? link.id : ''}
                    >
                    </button>

                    {activeTab === link.tab &&
                        <span className="modalnav__info-text">{link.text}</span>
                    }
                </li>
            ))}
        </ul>
    )
}

ModalNav.propTypes = {
    activeTab: PropTypes.string,
    firstStepValid: PropTypes.bool,
    secondStepValid: PropTypes.bool,
    setActiveTab: PropTypes.func
}