import React from 'react';

export default function PaypalIcon() {
    return (
        <svg viewBox="0 0 40 31">
            <path fill="#92DBEF" d="M36,31H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v23C40,29.2,38.2,31,36,31z" />
            <g>
                <path fill="#263B80" d="M25,8.4c-0.7-0.8-2.1-1.2-4-1.2h-5.2c-0.3,0-0.6,0.3-0.7,0.6L13,21.4c0,0.3,0.2,0.5,0.4,0.5h3.2l0.8-5.1
                    v0.2c0.1-0.3,0.4-0.6,0.7-0.6h1.6c3,0,5.3-1.2,6-4.7v-0.3c-0.1,0-0.1,0,0,0C25.9,10.1,25.7,9.3,25,8.4"/>
                <path fill="#232C65" d="M25.8,11.8v-0.3c0,0,0,0,0,0c-0.2-0.1-0.4-0.2-0.5-0.3c-0.6-0.3-1.3-0.3-1.6-0.3l0,0h-1.5c0,0,0,0,0,0H19
                    c-0.5,0-0.7,0.5-0.7,0.6l-0.8,5.4V17v0.1c0.1-0.3,0.4-0.6,0.7-0.6h1.6C22.8,16.4,25.1,15.2,25.8,11.8z"/>
                <path fill="#139AD6" d="M26.8,12.7c-0.2-0.6-0.7-1.1-1-1.3c0,0,0,0,0,0v0.3c-0.7,3.4-3,4.7-6,4.7h-1.6c-0.3,0-0.6,0.3-0.7,0.6V17
                    c0,0,0,0.1,0,0.1l-0.6,3.6l-0.2,1.3h0l-0.2,1.5c0,0.2,0.1,0.4,0.4,0.4h2.6c0.5,0,0.7-0.4,0.7-0.6l0-0.1v0l0.5-3.1
                    c0,0,0.1-0.7,0.8-0.7h0.3c2.6,0,4.7-1,5.2-4.2C27,14.2,27,13.4,26.8,12.7z"/>
            </g>
        </svg>
    )
}
