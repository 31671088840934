/**
 * @module DiscountActions
 */
import {
    ENABLE_DISCOUNT,
    DISABLE_DISCOUNT,
    CHECK_DISCOUNT_SUCCESS,
    CHECK_DISCOUNT_ERROR,
    SET_DISCOUNT_CODE,
    CLEAR_DISCOUNT_CODE,
    TOGGLE_DISCOUNT_WITH_TOP_WRITER
} from 'store/orderform/orderformActionTypes';

import { handleCalcPrice } from 'store/orderform/orderformglobal/globalAction';
import { discountURL } from 'orderform/utils/config';

import { notify } from 'helpers/notification';
import { achieveGoals } from 'store/metricaActions';
import http from 'services/http';

const inFocus = (ref) => {
    setTimeout(() => {
        ref.current && ref.current.focus();
    }, 50)
}

/** */
export function openDiscount(ref, e) {
    e.preventDefault();

    return function (dispatch) {
        dispatch({ type: ENABLE_DISCOUNT });
        inFocus(ref);
    }
}

/** */
export function closeDiscount(e) {
    return function (dispatch) {
        if (e.target.value.trim() === '') {
            dispatch({ type: DISABLE_DISCOUNT });
        }
    }
}

/** */
export function checkDiscount(ref, e) {
    e.preventDefault();

    return function (dispatch, getState) {
        const { orderform: { discount, topWriters }, global: { isAuthorized } } = getState();

        if (discount.isApplyed) {
            dispatch({
                type: CLEAR_DISCOUNT_CODE
            });

            inFocus(ref);
            handleCalcPrice(dispatch, getState);
            return;
        }

        if (!discount.code.trim()) {
            notify.warn('Please enter your discount');
            return;
        }

        const discountCode = discount.code.replace(/(<([^>]+)>)/ig, "").trim();
        const formData = new FormData();

        formData.append('code', discountCode);

        if (isAuthorized) {
            return http
                .post(discountURL, { body: formData })
                .then((data) => {
                    if (data.isTopWriterDiscount) {

                        if (topWriters.value === 2) {
                            dispatch({
                                type: CHECK_DISCOUNT_SUCCESS,
                                payload: {
                                    code: data.code,
                                    amount: data.amount,
                                    percent: data.percent
                                }
                            });
                            dispatch({ type: TOGGLE_DISCOUNT_WITH_TOP_WRITER })
                            dispatch(achieveGoals('apply_top_writer_discount_success'));
                        } else {
                            notify.info('Please enable Top Writer feature to use this discount');
                        }

                    } else {

                        dispatch({
                            type: CHECK_DISCOUNT_SUCCESS,
                            payload: {
                                code: data.code,
                                amount: data.amount,
                                percent: data.percent
                            }
                        });
                        dispatch(achieveGoals('apply_discount_success'));
                    }
                    handleCalcPrice(dispatch, getState);
                })
                .catch(({ message, body }) => {
                    if (body) {
                        if (body.messages) {
                            console.log('err:', body.messages);
                            notify.error(body.messages);
                            dispatch(achieveGoals('error_discount_apply'));

                            dispatch({
                                type: CHECK_DISCOUNT_ERROR,
                                payload: {
                                    code: '',
                                    amount: 0,
                                    percent: 0
                                }
                            });

                        } else if (body.errors) {
                            localStorage.removeItem('discountCode');
                            dispatch(achieveGoals('error_discount_apply'));
                            window.history.replaceState({}, document.title, window.location.pathname);

                            notify.error(body.errors.code[0]);
                            console.log('err:', body.errors.code[0]);

                            dispatch({
                                type: CHECK_DISCOUNT_ERROR,
                                payload: {
                                    code: '',
                                    amount: 0,
                                    percent: 0
                                }
                            });

                            dispatch({ type: DISABLE_DISCOUNT });

                        }

                    } else {
                        console.log(message);
                        notify.error('Something went wrong');
                    }
                })

        } else {
            return fetch(discountURL, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json()).then(data => {

                    if (data.messages) {
                        console.log('err:', data.messages);
                        notify.error(data.messages);
                        dispatch(achieveGoals('error_discount_apply'));

                        dispatch({
                            type: CHECK_DISCOUNT_ERROR,
                            payload: {
                                code: '',
                                amount: 0,
                                percent: 0
                            }
                        });

                    } else if (data.errors) {
                        localStorage.removeItem('discountCode');
                        dispatch(achieveGoals('error_discount_apply'));
                        window.history.replaceState({}, document.title, window.location.pathname);

                        notify.error(data.errors.code[0]);
                        console.log('err:', data.errors.code[0]);

                        dispatch({
                            type: CHECK_DISCOUNT_ERROR,
                            payload: {
                                code: '',
                                amount: 0,
                                percent: 0
                            }
                        });

                        dispatch({ type: DISABLE_DISCOUNT });

                    } else if (data.isTopWriterDiscount) {

                        if (topWriters.value === 2) {
                            dispatch({
                                type: CHECK_DISCOUNT_SUCCESS,
                                payload: {
                                    code: data.code,
                                    amount: data.amount,
                                    percent: data.percent
                                }
                            });
                            dispatch({ type: TOGGLE_DISCOUNT_WITH_TOP_WRITER })
                            dispatch(achieveGoals('apply_top_writer_discount_success'));
                        } else {
                            notify.info('Please enable Top Writer feature to use this discount');
                        }

                    } else {

                        dispatch({
                            type: CHECK_DISCOUNT_SUCCESS,
                            payload: {
                                code: data.code,
                                amount: data.amount,
                                percent: data.percent
                            }
                        });
                        dispatch(achieveGoals('apply_discount_success'));
                    }
                    handleCalcPrice(dispatch, getState);

                }).catch(err => {
                    console.log(err);
                    notify.error('Something went wrong');
                });
        }

    }
}

/** */
export function setDiscountCode(e) {
    e.preventDefault();

    return function (dispatch) {
        dispatch({
            type: SET_DISCOUNT_CODE,
            payload: e.target.value
        });
    }
}

/** */
export function silentDiscountCheck() {
    return function (dispatch, getState) {
        const { orderform: { discount, topWriters }, global: { isAuthorized } } = getState();

        const formData = new FormData();
        const discountCode = discount.code.replace(/(<([^>]+)>)/ig, "").trim();

        formData.append('code', discountCode);

        if (isAuthorized) {
            return http
                .post(discountURL, { body: formData })
                .then((data) => {
                    if (data.isTopWriterDiscount) {

                        if (topWriters.value === 2) {

                            dispatch({ type: ENABLE_DISCOUNT });
                            dispatch({
                                type: CHECK_DISCOUNT_SUCCESS,
                                payload: {
                                    code: data.code,
                                    amount: data.amount,
                                    percent: data.percent
                                }
                            });

                            dispatch({ type: TOGGLE_DISCOUNT_WITH_TOP_WRITER })
                            dispatch(achieveGoals('apply_top_writer_discount_success'));

                        } else {
                            notify.info('Please enable Top Writer feature to use this discount');
                        }

                    } else {
                        notify.success('Your discount has been successfully applied');

                        dispatch({ type: ENABLE_DISCOUNT });

                        dispatch({
                            type: CHECK_DISCOUNT_SUCCESS,
                            payload: {
                                code: data.code,
                                amount: data.amount,
                                percent: data.percent
                            }
                        });
                        dispatch(achieveGoals('apply_discount_success'));
                    }

                    handleCalcPrice(dispatch, getState);
                })
                .catch(({ message, body }) => {
                    if (body) {
                        if (body.messages) {
                            console.log('err:', body.messages);
                            notify.error(body.messages);

                            localStorage.removeItem('discountCode');
                            dispatch(achieveGoals('error_discount_apply'));
                            window.history.replaceState({}, document.title, window.location.pathname);

                            dispatch({
                                type: CHECK_DISCOUNT_ERROR,
                                payload: {
                                    code: '',
                                    amount: 0,
                                    percent: 0
                                }
                            });

                            dispatch({ type: DISABLE_DISCOUNT });

                        } else if (body.errors) {

                            localStorage.removeItem('discountCode');
                            dispatch(achieveGoals('error_discount_apply'));
                            window.history.replaceState({}, document.title, window.location.pathname);

                            notify.error(body.errors.code[0]);
                            console.log('err:', body.errors.code[0]);

                            dispatch({
                                type: CHECK_DISCOUNT_ERROR,
                                payload: {
                                    code: '',
                                    amount: 0,
                                    percent: 0
                                }
                            });

                            dispatch({ type: DISABLE_DISCOUNT });

                        }
                    } else {
                        console.log(message);
                        notify.error('Something went wrong');
                    }
                })

        } else {
            return fetch(discountURL, {
                method: 'POST',
                body: formData
            }).then(response => response.json()).then(data => {
                console.log('data >>=', data);

                if (data.messages) {
                    console.log('err:', data.messages);
                    notify.error(data.messages);

                    localStorage.removeItem('discountCode');
                    dispatch(achieveGoals('error_discount_apply'));
                    window.history.replaceState({}, document.title, window.location.pathname);

                    dispatch({
                        type: CHECK_DISCOUNT_ERROR,
                        payload: {
                            code: '',
                            amount: 0,
                            percent: 0
                        }
                    });

                    dispatch({ type: DISABLE_DISCOUNT });

                } else if (data.errors) {

                    localStorage.removeItem('discountCode');
                    dispatch(achieveGoals('error_discount_apply'));
                    window.history.replaceState({}, document.title, window.location.pathname);

                    notify.error(data.errors.code[0]);
                    console.log('err:', data.errors.code[0]);

                    dispatch({
                        type: CHECK_DISCOUNT_ERROR,
                        payload: {
                            code: '',
                            amount: 0,
                            percent: 0
                        }
                    });

                    dispatch({ type: DISABLE_DISCOUNT });

                } else if (data.isTopWriterDiscount) {

                    if (topWriters.value === 2) {

                        dispatch({ type: ENABLE_DISCOUNT });
                        dispatch({
                            type: CHECK_DISCOUNT_SUCCESS,
                            payload: {
                                code: data.code,
                                amount: data.amount,
                                percent: data.percent
                            }
                        });

                        dispatch({ type: TOGGLE_DISCOUNT_WITH_TOP_WRITER })
                        dispatch(achieveGoals('apply_top_writer_discount_success'));

                    } else {
                        notify.info('Please enable Top Writer feature to use this discount');
                    }

                } else {
                    notify.success('Your discount has been successfully applied');

                    dispatch({ type: ENABLE_DISCOUNT });

                    dispatch({
                        type: CHECK_DISCOUNT_SUCCESS,
                        payload: {
                            code: data.code,
                            amount: data.amount,
                            percent: data.percent
                        }
                    });
                    dispatch(achieveGoals('apply_discount_success'));
                }

                handleCalcPrice(dispatch, getState);

            }).catch(err => {
                console.log(err);
                notify.error('Something went wrong');
            });
        }

    }
}