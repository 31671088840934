import React from 'react';
import PropTypes from 'prop-types';

/**
 * @name Label
 * @component
 * @category Orderform
 * @hideconstructor
 */
export default function Label({ label, tooltip, error, isRequired }) {
    return (
        <h5 className="orderform__label">
            <span className="orderform__label-text">{label}</span>

            {/*{tooltip &&*/}
            {/*    <span className="orderform__tooltip left">{tooltip}</span>*/}
            {/*}*/}

            <span className="orderform__label-message">{isRequired && <span> *</span>} {error ? error : ''}</span>
        </h5>
    );
}

Label.propTypes = {
    label: PropTypes.string,
    tooltip: PropTypes.string,
    error: PropTypes.string,
    isRequired: PropTypes.bool
}