import { TOGGLE_SPACING, UPDATE_SPACING_LABEL, SET_WORK_TYPE, SET_DEFAULT_VALUE } from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

export const initialState = {
    id: 'spacing',
    name: 'spacing',
    label: 'Spacing (300 words per page)',
    wordsPerPage: 300,
    value: 2,
    disabled: false,
}

export const SPACING = {
    SINGLE: 1,
    DOUBLE: 2
}

export default function spacingReduser(state = initialState, { type, payload }) {
    switch (type) {
        case TOGGLE_SPACING:
            return {
                ...state,
                value: state.value === 1 ? SPACING.DOUBLE : SPACING.SINGLE
            }

        case UPDATE_SPACING_LABEL:
            return {
                ...state,
                label: payload.label,
                wordsPerPage: payload.wordsPerPage
            }

        case SET_WORK_TYPE:
            if (payload === '3') {
                return {
                    ...state,
                    value: 2,
                    label: 'Spacing',
                    wordsPerPage: 300,
                    disabled: true
                }
            } else {
                return {
                    ...state,
                    label: `Spacing (${state.wordsPerPage} words per page)`,
                    disabled: false
                }
            }

        case SET_DEFAULT_VALUE:
            return {
                ...state,
                value: payload.spacing || state.value
            }

        case CLEAR_ORDERFORM:
            return {
                ...state,
                value: payload.spacing,
                disabled: false
            }

        default:
            return state
    }
}