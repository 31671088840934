import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// Antd styles -> https://ant.design/docs/react/customize-theme
import 'antd/lib/style/themes/default.less';
import 'antd/dist/antd.less'; // Import Ant Design styles by less entry

// Main styles
import './styles/main.scss';

import App from './App';
import rootReducer from './store/rootReducer';
const initialState = {};
const middleware = [thunk];
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) || compose;

export const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
);
try {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root') || document.createElement('div') // for testin purposes
    );
} catch (err) {
    let count = 0;
    const errorInterval = setInterval(() => {
        count++;
        if (window.Sentry) {
            window.Sentry.captureException(`[React] ${err}`);
            console.error(`[React] ${err}`);
            clearInterval(errorInterval);
        }
    }, 200);

    if (count > 5) {
        clearInterval(errorInterval);
    } else {
        console.error(`!Senty is not available [React] ${err}`);
    }
}

// Push service worker registration
/*if ('serviceWorker' in navigator) {
    if (Notification.permission === "granted") {
        run().catch(error => console.error(error));

    } else if (Notification.permission === "denied") {
        // the user has previously denied push. Can't reprompt.

    } else {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                run().catch(error => console.error(error));
            }
        });
    }

} else {
    console.warn('Push messaging is not supported');
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

async function subscribe(registration) {
    const publicVapidKey = 'BM0bPTRhUjLpxn-fmhX0DIH-HoE9twwL6duIre6oDyIZMpxbwz4O5cajbxSaVU5NGn-QIDJHxAakbHVoKehOIeA';

    const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        // The `urlBase64ToUint8Array()` function is the same as in
        // https://www.npmjs.com/package/web-push#using-vapid-key-for-applicationserverkey
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
    });

    await fetch('http://localhost:4000/subscribe', {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
            'content-type': 'application/json'
        }
    });
}

async function run() {
    const registration = await navigator.serviceWorker.register('/serviceWorker.js');
    const ready = await navigator.serviceWorker.ready;

    if (ready.active !== null) {
        subscribe(registration);

    } else {
        const isWorkerActive = window.confirm('Enable push notifications?');

        isWorkerActive && subscribe(registration);
    }
}*/