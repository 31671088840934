import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HeartIcon from '../../components/img/HeartIcon';

import { deleteProfile } from '../../../store/dashboard/profile/profileActions';

/**
 * @component
 * @category Dashboard
 * @subcategory Profile
 * @hideconstructor
 * @memberof PrivacyModal
 */
function WipeModal({ opened, toggleOpen, deleteProfile }) {
    /** 
     * Close on click outside modal 
     * @param {documentElement} target
     */
    const handleCloseOutside = ({ target }) => {
        if (target.id === 'wipe-modal') {
            toggleOpen();
        }
    }

    return (
        <div
            id="wipe-modal"
            className={`wipe__modal ${opened ? 'is-open' : ''}`}
            onClick={handleCloseOutside}
        >
            <div className="wipe__inner-wrapper">
                <HeartIcon />
                <div className="wipe__top-bar">
                    <span>We will miss you...</span>
                    <div className="wipe__close" onClick={toggleOpen}></div>
                </div>
                <h4 className="wipe__heading">
                    Do you really want to delete your account?
                </h4>
                <p className="wipe__text">
                    The account and all collected information will be permanently deleted along with the entire order history, files and messsages.
                </p>
                <footer className="wipe__footer">
                    <div
                        className="wipe__del-btn"
                        onClick={deleteProfile.bind(null, toggleOpen)}
                    >
                        Yes, delete my account
                    </div>
                    <div className="wipe__divider"></div>
                    <div
                        className="wipe__cancel"
                        onClick={toggleOpen}
                    >
                        Nope, I was just curious to clik here
                    </div>
                </footer>
            </div>
        </div>
    )
}

WipeModal.propTypes = {
    opened: PropTypes.bool,
    toggleOpen: PropTypes.func,
    deleteProfile: PropTypes.func
}

const mapDispatchToProps = {
    deleteProfile
}

export default connect(null, mapDispatchToProps)(WipeModal);