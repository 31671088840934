import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleModal, choosePaymentSystem } from '../store/global/globalActions';
import { orderListURL } from '../store/config';
import { notify } from '../helpers/notification';
import http from 'services/http';

/**
 * @component
 * @category Dashboard
 * @hideconstructor
 */
function ErrorModal({ orderUid, pending, siteCategory, multipayment, toggleModal, choosePaymentSystem }) {
    const [orderData, setOrderData] = useState({});

    useEffect(() => {
        if (orderUid && siteCategory) {
            http
                .get(orderListURL(siteCategory, {
                    limit: 1,
                    page: 1,
                    q: orderUid
                }))
                .then(data => {
                    if (data.orders[0]) setOrderData(data.orders[0]);
                })
                .catch(err => {
                    console.log(err);
                    notify.error('Something went wrong while fetching order data');
                })

            // fetch(orderListURL(siteCategory, {
            //     limit: 1,
            //     page: 1,
            //     q: orderUid
            // }))
            //     .then(response => response.json())
            //     .then(data => {

            //         if (data.orders[0]) {
            //             setOrderData(data.orders[0]);

            //         } else {
            //             notify.error('Something went wrong while fetching order data');
            //         }

            //     }).catch(err => {
            //         console.log(err);
            //         notify.error('Something went wrong while fetching order data');
            //     });
        }

    }, [orderUid, siteCategory]);

    /** Close modal */
    const handleClose = () => {
        const newUrl = `${window.location.pathname}?id=${orderUid}`

        window.history.replaceState({}, document.title, newUrl);
        toggleModal();
    }

    /** Open zopim chat */
    const handleZopim = () => {
        handleClose();

        typeof window.zopimToggler !== 'undefined' && window.zopimToggler();
    }

    /** Choose payment method  */
    const handlePaymentMethod = () => {
        handleClose();

        setTimeout(() => {
            choosePaymentSystem(orderData.workType.id, orderData.uid, orderData.finalPrice);
        }, 300);
    }

    return (
        <div id="modal-error" className="modal-error">
            <button
                className="modal-close"
                onClick={handleClose}
            >
                <svg width="14" height="14" viewBox="0 0 14 14"><g><path d="M.636 11.95L5.586 7 .636 2.05 2.05.636 7 5.586l4.95-4.95 1.414 1.414L8.414 7l4.95 4.95-1.415 1.414L7 8.414l-4.95 4.95z" /></g></svg>
            </button>
            <div className="modal-body">
                <div className="modal-body__images">
                    {/* https://qotoqot.com/sad-animations/ */}
                    <img src="/img/error.gif" alt="Error" style={{marginTop: 15}} />
                </div>
                <div className="modal-body__heading">
                    Looks like there’s been a problem with <br/> 
                    your payment for order (<span id="uid">{orderUid}</span>).
                </div>
                <div className="modal-body__content">
                    No worries! You can always choose an alternative payment method or ask our support team for assistance.
                </div>
                <div className="modal-body__buttons">
                    <button
                        className="modal-button__help"
                        onClick={handleZopim}
                    >
                        Contact Support
                    </button>
                    {!pending && multipayment &&
                        <button
                            className="modal-button__method"
                            onClick={handlePaymentMethod}
                        >
                            Choose alternative payment method
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}

ErrorModal.propTypes = {
    siteCategory: PropTypes.number,
    multipayment: PropTypes.bool,
    toggleModal: PropTypes.func,
    choosePaymentSystem: PropTypes.func
}

const mapStateToProps = ({ global }) => ({
    siteCategory: global.siteCategory,
    multipayment: global.multipayment
});

const mapDispatchToProps = {
    toggleModal,
    choosePaymentSystem
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);