/**
 * @module FeedbackActions
 */

import {
    SET_FEEDBACK_COMMENT,
    SET_FEEDBACK_INFO,
    SET_FEEDBACK_RATING,
    CLEAR_FEEDBACK_INFO
} from 'store/actionTypes';

import { openModal, toggleModal } from 'store/global/globalActions';
import { addFeedbackURL } from 'store/config';
import { notify } from 'helpers/notification';
import http from 'services/http';

/** */
export function setFeedbackModal(modal, config, rating) {
    return function (dispatch) {
        // rating is onClick return value

        const setting = {
            id: config.id,
            setFeedbackModal: config.setFeedbackModal, //? unknown property
            blockClass: 'centered',
            rating,
            type: config.type
        };

        openModal(modal, setting)(dispatch);
        setFeedbackRating(rating)(dispatch);
    }
}

/** */
export function setFeedbackRating(rating) {
    return function (dispatch) {
        dispatch({
            type: SET_FEEDBACK_RATING,
            payload: rating
        })
    }
}

/** */
export function setFeedbackComment(e) {
    return function (dispatch) {
        dispatch({
            type: SET_FEEDBACK_COMMENT,
            payload: e.target.value
        })
    }
}

/** */
export function setFeedback(orderId, type) {
    return function (dispatch, getState) {
        const { dashboard: { feedback } } = getState();

        return http
            .post(addFeedbackURL(orderId), {
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ rating: feedback.rating, comment: feedback.comment })
            })
            .then(data => {
                if (data.id) {
                    notify.success('Your review has been added.');

                    if (type === 'list') {
                        const newData = {
                            ...data,
                            id: orderId
                        }
                        dispatch({
                            type: SET_FEEDBACK_INFO + '_LIST',
                            payload: newData
                        })

                    } else if (type === 'info') {
                        dispatch({
                            type: SET_FEEDBACK_INFO + '_INFO',
                            payload: data
                        })
                    }
                }
            })
            .catch(({ message, body }) => {
                if (body) {
                    body.message && notify.warn(body.message);

                } else {
                    console.error('Fail setFeedbackModal', message);
                }
            })
            .finally(toggleModal()(dispatch))

        /*return fetch(addFeedbackURL(orderId), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ rating: feedback.rating, comment: feedback.comment })
        })
            .then(response => response.json())
            .then(data => {
                if (data.id) {
                    notify.success('Your review has been added.');

                    if (type === 'list') {
                        const newData = {
                            ...data,
                            id: orderId
                        }
                        dispatch({
                            type: SET_FEEDBACK_INFO + '_LIST',
                            payload: newData
                        })

                    } else if (type === 'info') {
                        dispatch({
                            type: SET_FEEDBACK_INFO + '_INFO',
                            payload: data
                        })
                    }

                } else {
                    notify.warn(data.message)
                }
            })
            .catch(err => console.error('Fail setFeedbackModal', err))
            .finally(() => {
                toggleModal()(dispatch)
            })*/
    }
}

/** */
export function clearFeedbackInfo() {
    return function (dispatch) {
        dispatch({ type: CLEAR_FEEDBACK_INFO });
    }
}