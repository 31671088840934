import { CALCULATE_PRICE } from 'store/orderform/orderformActionTypes';

export const initialState = {
    basicPrice: 0,
    finalPrice: 0,
    premiumWriterPrice: 0,
    preferredWriterPrice: 0,
    extraqualityPrice: 0,
    onePageSummaryPrice: 0,
    qualityPrice: 0,
    discountPrice: 0
}

export default function priceReducer(state = initialState, { type, payload }) {
    switch (type) {
        case CALCULATE_PRICE:
            return {
                ...state,
                basicPrice: payload.basicPrice,
                finalPrice: payload.finalPrice,
                premiumWriterPrice: payload.pricePremiumWriter,
                preferredWriterPrice: payload.pricePreferedWriter,
                extraqualityPrice: payload.priceExtraQualityCheck,
                qualityPrice: payload.priceQuality,
                onePageSummaryPrice: payload.priceOnePageSummary,
                discountPrice: payload.priceDiscount
            };

        default:
            return state;
    }
}