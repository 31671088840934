import React from "react";

export default function StripeIcon() {
  return (
    <svg width="40" height="31" viewBox="0 0 40 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36 31H4C1.8 31 0 29.2 0 27V4C0 1.8 1.8 0 4 0H36C38.2 0 40 1.8 40 4V27C40 29.2 38.2 31 36 31Z" fill="url(#paint0_linear)" />
      <path d="M37.9325 15.7431C37.9325 13.1771 36.7028 11.1524 34.3524 11.1524C31.9922 11.1524 30.5641 13.1771 30.5641 15.7231C30.5641 18.7401 32.25 20.2637 34.6698 20.2637C35.8499 20.2637 36.7425 19.993 37.4168 19.6121V17.6075C36.7425 17.9483 35.9689 18.1587 34.9871 18.1587C34.0252 18.1587 33.1723 17.818 33.0632 16.6352H37.9127C37.9127 16.5049 37.9325 15.9837 37.9325 15.7431ZM33.0335 14.7909C33.0335 13.6583 33.7177 13.1872 34.3425 13.1872C34.9475 13.1872 35.5921 13.6583 35.5921 14.7909H33.0335Z" fill="white" />
      <path d="M26.7361 11.1524C25.7642 11.1524 25.1394 11.6135 24.7923 11.9342L24.6634 11.3128H22.4816V23L24.9609 22.4688L24.9708 19.6322C25.3278 19.8928 25.8534 20.2637 26.7261 20.2637C28.5013 20.2637 30.1178 18.8203 30.1178 15.6429C30.1079 12.7361 28.4716 11.1524 26.7361 11.1524ZM26.141 18.0585C25.5559 18.0585 25.2088 17.848 24.9708 17.5874L24.9609 13.8687C25.2187 13.5781 25.5757 13.3776 26.141 13.3776C27.0435 13.3776 27.6683 14.4 27.6683 15.713C27.6683 17.0562 27.0534 18.0585 26.141 18.0585Z" fill="white" />
      <path d="M19.0701 10.561L21.5593 10.0197V7.98499L19.0701 8.51622V10.561Z" fill="white" />
      <path d="M21.5593 11.3228H19.0701V20.0932H21.5593V11.3228Z" fill="white" />
      <path d="M16.4024 12.0645L16.2437 11.3228H14.1016V20.0932H16.5809V14.1494C17.166 13.3776 18.1577 13.5179 18.4652 13.6282V11.3228C18.1478 11.2025 16.9875 10.982 16.4024 12.0645Z" fill="white" />
      <path d="M11.4438 9.14771L9.02402 9.66892L9.0141 17.6976C9.0141 19.1811 10.1149 20.2737 11.5826 20.2737C12.3959 20.2737 12.9909 20.1233 13.3181 19.9429V17.9081C13.0008 18.0384 11.4339 18.4995 11.4339 17.0161V13.4578H13.3181V11.3228H11.4339L11.4438 9.14771Z" fill="white" />
      <path d="M4.73981 13.8687C4.73981 13.4778 5.05716 13.3275 5.58277 13.3275C6.33648 13.3275 7.28852 13.558 8.04223 13.969V11.6135C7.2191 11.2827 6.4059 11.1524 5.58277 11.1524C3.56959 11.1524 2.23077 12.2149 2.23077 13.989C2.23077 16.7555 5.99929 16.3144 5.99929 17.5072C5.99929 17.9683 5.60261 18.1187 5.04725 18.1187C4.22412 18.1187 3.1729 17.7779 2.33986 17.3168V19.7023C3.26216 20.1033 4.19437 20.2737 5.04725 20.2737C7.11001 20.2737 8.52817 19.2413 8.52817 17.4471C8.51825 14.4601 4.73981 14.9914 4.73981 13.8687Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear" x1="0" y1="0" x2="40" y2="31" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7CA1FF" />
          <stop offset="1" stopColor="#7E41FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
