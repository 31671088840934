import {
    FETCH_PREFERRED_WRITERS,
    SELECT_PREFERRED_WRITER,
    UNSELECT_PREFERRED_WRITER,
    TOGGLE_CHECKBOX,
    SET_DEFAULT_VALUE
} from 'store/orderform/orderformActionTypes';

import reducerLogic from 'store/orderform/controls/select/selectReducer';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

export const initialState = {
    id: 'preferredwriter',
    name: 'preferredwriter',
    value: 1,
    error: '',
    title: 'Have Preferred Writers?',
    tooltip: 'List the writers who you prefer to deliver this order.',
    searchValue: '',
    options: [],
    preferredWriterList: [],
    preferredWithPremium: [],
    disabledOptions: [],
    filteredOptions: [],
    anotherWriter: true,
    searchDisabled: false,
    dropdownOpen: false,
    selectDisabled: false,
    isRequired: false
}

export default function preferredWritersReducer(state = initialState, action) {

    if (action.type === FETCH_PREFERRED_WRITERS) {
        return {
            ...state,
            options: [
                ...state.options,
                ...action.payload
            ]
        };

    } else if (action.type === SELECT_PREFERRED_WRITER) {
        return {
            ...state,
            value: 2,
            preferredWriterList: [
                ...state.preferredWriterList,
                action.payload.list
            ],
            preferredWithPremium: action.payload.isPremium ? [
                ...state.preferredWithPremium,
                action.payload.list
            ] : [...state.preferredWithPremium]
        };

    } else if (action.type === UNSELECT_PREFERRED_WRITER) {
        return {
            ...state,
            value: action.payload.enabled,
            preferredWriterList: state.preferredWriterList.filter(item => item !== action.payload.list),
            preferredWithPremium: state.preferredWithPremium.filter(item => item !== action.payload.list)
        };

    } else if (action.type === TOGGLE_CHECKBOX + '_' + state.id) {
        localStorage.setItem('anotherWriter', JSON.stringify(!state.anotherWriter));

        return {
            ...state,
            anotherWriter: !state.anotherWriter
        };

    } else if (action.type === SET_DEFAULT_VALUE) {
        return {
            ...state,
            value: (action.payload.preferredWriters?.value) || initialState.value,
            preferredWriterList: (action.payload.preferredWriters?.list) || initialState.preferredWriterList,
            anotherWriter: action.payload.anotherWriter ? (action.payload.anotherWriter === 'true') ? true : false : initialState.anotherWriter
        }

    } else if (action.type === CLEAR_ORDERFORM) {
        return {
            ...state,
            value: action.payload.preferredWriters,
            preferredWriterList: action.payload.preferredWriterList,
            anotherWriter: action.payload.anotherWriter
        }

    } else {
        return reducerLogic(state, action);
    }
}