// GLOBAL
export const USER_SIGN_UP_SUCCESS = '[AUTH] USER_SIGN_UP_SUCCESS';
export const USER_SIGN_IN_SUCCESS = '[AUTH] USER_SIGN_IN_SUCCESS';
export const USER_MAIL_EXISTS = '[AUTH] USER_MAIL_EXISTS';
export const CALCULATE_PRICE = '[ORDER] CALCULATE_PRICE';
export const FIRST_STEP_VALIDATION = '[ORDER] FIRST_STEP_VALIDATION';
export const SECOND_STEP_VALIDATION = '[ORDER] SECOND_STEP_VALIDATION';
export const THIRD_STEP_VALIDATION = '[ORDER] THIRD_STEP_VALIDATION';
export const CREATE_ORDER = '[ORDER] CREATE_ORDER';

// FETCH INIT DATA
export const FETCH_DEFAULT_SETTINGS = 'FETCH_DEFAULT_SETTINGS';
export const FETCH_DEFAULT_SETTINGS_FAILED = 'FETCH_DEFAULT_SETTINGS_FAILED';
export const FETCH_WORK_TYPE = 'FETCH_WORK_TYPE';
export const FETCH_LEVEL = 'FETCH_LEVEL';
export const FETCH_PAPER_FORMAT = 'FETCH_PAPER_FORMAT';
export const FETCH_PAPER_TYPE = 'FETCH_PAPER_TYPE';
export const FETCH_RESUME_SERVICE = 'FETCH_RESUME_SERVICE';
export const FETCH_SUBJECT = 'FETCH_SUBJECT';
export const FETCH_URGENCIES = 'FETCH_URGENCIES';
export const FETCH_PREFERRED_WRITERS = 'FETCH_PREFERRED_WRITERS';

// WORK TYPE
export const SET_WORK_TYPE = '[CONTROLS] SET_WORK_TYPE';

// RESUME SERVICE
// export const SET_RESUME_SERVICE = '[CONTROLS] SET_RESUME_SERVICE';

// SELECT
export const TOGGLE_SELECT_DROPDOWN = '[CONTROLS] TOGGLE_SELECT_DROPDOWN';
export const SELECT_OPTION = '[CONTROLS] SELECT_OPTION';
export const CLOSE_SELECT_ON_CLICK_OUTSIDE = '[CONTROLS] CLOSE_SELECT_ON_CLICK_OUTSIDE';
export const SELECT_SEARCH = '[CONTROLS] SELECT_SEARCH';
export const DISABLE_SELECT_OPTIONS = 'DISABLE_SELECT_OPTIONS';
export const ENABLE_SELECT_OPTIONS = 'ENABLE_SELECT_OPTIONS';

// PAGES && SOURCES
export const TOGGLE_NUMBER = '[CONTROLS] TOGGLE_NUMBER';
export const INCREMENT_NUMBER = '[CONTROLS] INCREMENT_NUMBER';
export const DECREMENT_NUMBER = '[CONTROLS] DECREMENT_NUMBER';
export const SET_DEFAULT_NUMBER = '[CONTROLS] SET_DEFAULT_NUMBER';

// SPACING
export const TOGGLE_SPACING = '[CONTROLS] TOGGLE_SPACING';
export const UPDATE_SPACING_LABEL = '[CONTROLS] UPDATE_SPACING_LABEL'

// TEXT FIELD
export const SET_TEXTFIELD_VALUE = 'SET_TEXTFIELD_VALUE';
export const SET_TEXTFIELD_FOCUS = 'SET_TEXTFIELD_FOCUS';
export const SET_TEXTFIELD_BLUR = 'SET_TEXTFIELD_BLUR';

// FILE
export const ADD_FILE = '[DETAILS] ADD_FILE';
export const UPLOAD_FILE = '[DETAILS] UPLOAD_FILE';
export const UPLOAD_FILE_ERROR = '[DETAILS] UPLOAD_FILE_ERROR';
export const DELETE_FILE = '[DETAILS] DELETE_FILE';
export const CLEAR_FILES = '[DETAILS] CLEAR_FILES';
export const CLICK_ON_FILE_INPUT = '[DETAILS] CLICK_ON_FILE_INPUT';
export const GET_CUSTOMERS_FILES_ON_EDIT = '[DETAILS] GET_CUSTOMERS_FILES_ON_EDIT';

// URGENCY
export const SET_URGENCY = '[DETAILS] SET_URGENCY';
export const DECREMENT_URGENCY = '[DETAILS] DECREMENT_URGENCY';
export const INCREMENT_URGENCY = '[DETAILS] INCREMENT_URGENCY';

// PREFERRED WRITER
export const SELECT_PREFERRED_WRITER = '[DETAILS] SELECT_PREFERRED_WRITER';
export const UNSELECT_PREFERRED_WRITER = '[DETAILS] UNSELECT_PREFERRED_WRITER';

// TOP WRITER
export const TOGGLE_TOP_WRITERS = '[DETAILS] TOGGLE_TOP_WRITERS';
export const DISABLE_TOP_WRITER = '[DETAILS] DISABLE_TOP_WRITER';
export const ENABLE_TOP_WRITER = '[DETAILS] ENABLE_TOP_WRITER';

// EXTRAQUALITY CHECK
export const TOGGLE_EXTRA_QUALITY_CHECK = '[DETAILS] TOGGLE_EXTRA_QUALITY_CHECK';
export const DISABLE_EXTRA_QUALITY_CHECK = '[DETAILS] DISABLE_EXTRA_QUALITY_CHECK';
export const ENABLE_EXTRA_QUALITY_CHECK = '[DETAILS] ENABLE_EXTRA_QUALITY_CHECK';

// ONE PAGE SUMMARY
export const TOGGLE_ONE_PAGE_SUMMARY = '[DETAILS] TOGGLE_ONE_PAGE_SUMMARY';
export const DISABLE_ONE_PAGE_SUMMARY = '[DETAILS] DISABLE_ONE_PAGE_SUMMARY';
export const ENABLE_ONE_PAGE_SUMMARY = '[DETAILS] ENABLE_ONE_PAGE_SUMMARY';

// ORIGINALITY
export const TOGGLE_QUALITY_FEATURE = '[DETAILS] TOGGLE_QUALITY_FEATURE';

//PHONE
export const SET_PHONE = '[CHECKOUT] SET_PHONE';
export const SELECT_COUNTRY_DATA = '[CHECKOUT] SELECT_COUNTRY_DATA';

// USER TYPE
export const SET_USER_TYPE = '[CHECKOUT] SET_USER_TYPE';

// CHECKBOX
export const TOGGLE_CHECKBOX = '[CHECKOUT] TOGGLE_CHECKBOX';

// DISCOUNT
export const ENABLE_DISCOUNT = '[SUMMARY] ENABLE_DISCOUNT';
export const DISABLE_DISCOUNT = '[SUMMARY] DISABLE_DISCOUNT';
export const SET_DISCOUNT_CODE = '[SUMMARY] SET_DISCOUNT_CODE';
export const CLEAR_DISCOUNT_CODE = '[SUMMARY] CLEAR_DISCOUNT_CODE';
export const CHECK_DISCOUNT_SUCCESS = '[SUMMARY] CHECK_DISCOUNT_SUCCESS';
export const CHECK_DISCOUNT_ERROR = '[SUMMARY] CHECK_DISCOUNT_ERROR';
export const TRANSFER_DISCOUNT_CODE = '[SUMMARY] TRANSFER_DISCOUNT_CODE';
export const TOGGLE_DISCOUNT_WITH_TOP_WRITER = '[SUMMARY] TOGGLE_DISCOUNT_WITH_TOP_WRITER';

// SET DEFAULT VALUE [LOCAL_STORAGE]
export const SET_DEFAULT_VALUE = '[LOCAL STORAGE] SET_DEFAULT_VALUE';

// METRICA [YANDES]
export const TOGGLE_YANDEX_DEBUG_MODE = '[METRICA] TOGGLE_YANDEX_DEBUG_MODE';