/**
 * @module LoginActions
 */
 import {
    SIGN_IN_SUCCESS,
    USER_AUTHORIZED,
    // LOGOUT_SUCCESS,
    SIGN_IN_FAILURE,
  } from 'store/actionTypes';
  
  import { BASE_URL } from 'environmentVars';
  import http from 'services/http';
  import { customerURL, signupURL } from 'store/config';
  import { fetchPreferredWriter } from 'store/orderform/details/preferredwriter/preferredWriterAction';
  import { notify } from 'helpers/notification';
  import { tokenService } from 'services/tokenService';
  import { store } from 'index';
  import { getFingerprint } from 'helpers/utils';
  
  /** */
  export function checkUser() {
    return async function (dispatch) {
      const token = tokenService.getAccessToken();
  
      if (!token) return;
  
      return http
        .get(customerURL)
        .then((user) => {
          if (user?.id) {
            dispatch({
              type: USER_AUTHORIZED,
              payload: user,
            });
  
            fetchPreferredWriter()(dispatch);
          } else {
            console.log(user);
            return tokenService.clearTokens();
          }
        })
        .catch((err) => console.log(err));
    };
  }
  
  export function checkMagicLogin() {
    return function (dispatch) {
      const searchParams = new URLSearchParams(window.location.search);
      const magicLink = searchParams.get('magicLink');
  
      if (!magicLink) return;
  
      console.log(`checkMagicLogin`, magicLink);
  
      return fetch(`${BASE_URL}/api/en/auth/jwt/magic_link/${magicLink}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.accessToken) {
            tokenService.setTokens(data);
  
            // Remove magicLink from search params
            setTimeout(() => {
              try {
                searchParams.delete('magicLink'); // Remove used magicLink from search params
                const qs = searchParams.toString();
                const { pathname } = window.location;
                const url = `${pathname}${qs.length > 1 ? `?${qs}` : ''}`;
                window.history.replaceState(null, null, url);
              } catch (error) {
                console.error(error);
              }
            }, 100);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  }
  
  export function login(credentials) {
    return async function (dispatch) {
      return fetch(`${BASE_URL}/api/en/auth/jwt/sign_in`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.accessToken) {
            dispatch({ type: SIGN_IN_FAILURE });
            throw new Error(data.message || 'failed');
          }
          http.setAccessToken(data.accessToken);
          tokenService.setTokens(data);
          dispatch({ type: SIGN_IN_SUCCESS });
          return credentials;
        })
        .catch(({ message }) => {
          notify.error(message); // show notification
          // * Reject without args, coz this is a main handler
          return Promise.reject();
        });
    };
  }
  
  export function logout() {
    return async (dispatch) => {
      await tokenService.clearTokens();
  
      // <- fix some issues with initial state after logout
      window.location.reload(); // instead dispatch "LOGOUT_SUCCESS"
  
      // dispatch({ type: LOGOUT_SUCCESS }); <- coz refresh page
    };
  }
  
  export async function signUp(payload) {
    const hashDevice = await getFingerprint();
    const request = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...payload,
        hashDevice,
      }),
    };
  
    return fetch(signupURL, request)
      .then((resp) => resp.json())
      .then(async (tokens) => {
        // Check for server errors
        const { errors, message } = tokens;
        if (errors || message) {
          throw new Error(errors ? 'Check form fields' : message);
        }
  
        // Set tokens & update headers (http service)
        tokenService.setTokens(tokens);
        http.setAccessToken(tokens.accessToken);
  
        store.dispatch(checkUser()); // update store dashboard -> profile
  
        return tokens;
      });
  }
  