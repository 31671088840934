import React from 'react';

export default function FondyIcon() {
    return (
        <svg viewBox="0 0 40 31">
            <path d="M36,31H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v23C40,29.2,38.2,31,36,31z" />
            <g>
                <path fillRule="evenodd" clipRule="evenodd" fill="#59C341" d="M17,7.5v2.9c0,0.2,0.1,0.3,0.2,0.4l2.5,1.5
                    c0.1,0.1,0.3,0.1,0.5,0l2.5-1.5c0.1-0.1,0.2-0.2,0.2-0.4V7.5c0-0.2-0.1-0.3-0.2-0.4l-2.5-1.5c-0.1-0.1-0.3-0.1-0.5,0l-2.5,1.5
                    C17.1,7.2,17,7.4,17,7.5z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M17,19v3c0,0.1,0.1,0.2,0.2,0.3l2.6,1.5c0.1,0.1,0.2,0.1,0.3,0
                    l2.6-1.5c0.1-0.1,0.2-0.2,0.2-0.3v-3c0-0.1-0.1-0.2-0.2-0.3l-2.6-1.5c-0.1-0.1-0.2-0.1-0.4,0l-2.6,1.5C17.1,18.8,17,18.9,17,19z"
                />
                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M10.3,19v3c0,0.1,0.1,0.2,0.2,0.3l2.6,1.5c0.1,0.1,0.2,0.1,0.4,0
                    l2.6-1.5c0.1-0.1,0.2-0.2,0.2-0.3V19c0-0.1-0.1-0.2-0.2-0.3l-2.6-1.5c-0.1-0.1-0.2-0.1-0.4,0l-2.6,1.5
                    C10.4,18.8,10.3,18.9,10.3,19z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M23.7,19.1v3c0,0.1,0.1,0.2,0.2,0.3l2.6,1.5
                    c0.1,0.1,0.2,0.1,0.4,0l2.6-1.5c0.1-0.1,0.2-0.2,0.2-0.3v-3c0-0.1-0.1-0.2-0.2-0.3l-2.6-1.5c-0.1-0.1-0.2-0.1-0.4,0l-2.6,1.5
                    C23.8,18.8,23.7,18.9,23.7,19.1z"/>
                <path fill="#FFFFFF" d="M13.7,13.2v3c0,0.1,0.1,0.2,0.2,0.3l2.6,1.5c0.1,0.1,0.2,0.1,0.4,0l2.6-1.5c0.1-0.1,0.2-0.2,0.2-0.3v-3.1
                    c0-0.1-0.1-0.2-0.2-0.3l-2.6-1.5c-0.1-0.1-0.2-0.1-0.4,0l-2.7,1.5C13.7,13,13.7,13.1,13.7,13.2z"/>
                <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M20.4,13.3v3c0,0.1,0.1,0.2,0.2,0.3l2.6,1.5
                    c0.1,0.1,0.2,0.1,0.4,0l2.6-1.5c0.1-0.1,0.2-0.2,0.2-0.3v-3c0-0.1-0.1-0.2-0.2-0.3l-2.6-1.5c-0.1-0.1-0.2-0.1-0.4,0l-2.6,1.5
                    C20.4,13,20.4,13.1,20.4,13.3z"/>
                <path fill="#FFFFFF" d="M20,4.7l3.7,2.1v3.9l0,0l3.3,1.9v3.9l0,0l3.3,1.9v4.3l-3.7,2.1l-3.3-1.9l0,0L20,24.8l-3.3-1.9l0,0
                    l-3.3,1.9l-3.7-2.1v-4.3l3.3-1.9h0v-3.9l3.3-1.9l0,0V6.9L20,4.7 M20,3.9l-4.4,2.5v3.9l-3.3,1.9v3.9l-3.3,1.9v5.1l4.4,2.5l3.3-1.9
                    l3.3,1.9l3.3-1.9l3.3,1.9l4.4-2.5V18l-3.3-1.9v-3.9l-3.3-1.9V6.4L20,3.9L20,3.9z"/>
            </g>
        </svg>
    )
}
