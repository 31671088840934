// TODO 
/**
 * @module MetricaActions
 */

/** */
export function achieveGoals(target_name, paramsGoals = null) {
    // console.log('[achieveGoals]', target_name, paramsGoals)
    return function (dispatch, getState) {
        const { global: { yandexID, yandexDebug }, orderform: { price } } = getState();
        const params = paramsGoals ? paramsGoals : price;

        yandexDebug && console.log('name: ', target_name, 'id: ', yandexID, 'Debug: ', yandexDebug, 'price: ', price);

        if (yandexID && window.reachGoal) {
            try {
                window.reachGoal(target_name, params, yandexDebug);
            } catch (error) {
                console.error('MetricaActions!', error);
            }
        }
    }
}