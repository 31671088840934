import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from '../pages/Login';

/**
 * @component
 * @hideconstructor
 */
function PrivateRoute({ isAuthorized, component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => (
                isAuthorized ? <Component {...props} /> : <Login />
            )}
        />
    )
}

PrivateRoute.propTypes = {
    isAuthorized: PropTypes.bool
}

const mapStateToProps = (state) => ({
    isAuthorized: state.global.isAuthorized
});

export default connect(mapStateToProps)(PrivateRoute);
