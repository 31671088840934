import {
    TOGGLE_NUMBER,
    INCREMENT_NUMBER,
    DECREMENT_NUMBER,
    SET_DEFAULT_NUMBER,
    SET_DEFAULT_VALUE,
    SET_WORK_TYPE
} from 'store/orderform/orderformActionTypes';
import { CLEAR_ORDERFORM } from 'store/actionTypes';

export const initialState = {
    id: 'pages',
    name: 'page',
    label: 'Pages',
    tooltip: 'Select the number of pages of the order you upload. Final order price will be based on our appraisal of the initial document you upload.',
    value: '1',
    error: '',
    max: 100,
    min: 1,
    isRequired: false
}

export default function pagesReducer(state = initialState, { type, payload }) {
    switch (type) {
        case TOGGLE_NUMBER + '_' + state.id:
            return {
                ...state,
                value: payload
            };
        case INCREMENT_NUMBER + '_' + state.id:
            return {
                ...state,
                value: String(payload)
            };
        case DECREMENT_NUMBER + '_' + state.id:
            return {
                ...state,
                value: String(payload)
            };
        case SET_DEFAULT_NUMBER + '_' + state.id:
            if (state.value === '') {
                return {
                    ...state,
                    value: String(state.min)
                }
            } else {
                return state;
            }
        case SET_DEFAULT_VALUE:
            if (payload) {
                return {
                    ...state,
                    value: payload.pages,
                    max: payload.pagesMax || state.max
                }
            } else {
                return state;
            }

        case SET_WORK_TYPE:
            if (payload === '3') {
                return {
                    ...state,
                    label: 'Number of Slides'
                }
            } else {
                return {
                    ...state,
                    label: 'Pages'
                }
            }
        case CLEAR_ORDERFORM:
            return {
                ...state,
                value: payload.pages,
                label: 'Pages'
            };
        default:
            return state;
    }
}
