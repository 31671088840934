/**
 * @module LoyaltyActions
 */
import {
    FETCH_DISCOUNT_RULES,
    FETCH_DISCOUNT_LIST,
    STOP_LOYALTY_FETCHING,
    SET_CURRENT_RULE,
    CREATE_DISCOUNT,
    FETCH_REFERRAL_LINK,
    GET_READY_REFERRALS,
    FETCH_REFERRALS,
    REF_DISCOUNT_GENERATION_SUCCESS,
    SET_REFERRAL_FILTER
} from "store/actionTypes";

import {
    getDiscountRulesURL,
    getDiscountListURL,
    referralLinkURL,
    referralsURL,
    generateRefDiscountURL
} from "store/config";
import { notify } from 'helpers/notification';
import http from 'services/http';

/** */
export function stopLoyaltyFetching() {
    return function (dispatch) {
        dispatch({ type: STOP_LOYALTY_FETCHING });
    }
}

/** */
export function fetchDiscountRules(resolve) {
    return function (dispatch) {
        return http
            .get(getDiscountRulesURL)
            .then(res => {
                if (res.rules) {
                    dispatch({
                        type: FETCH_DISCOUNT_RULES,
                        payload: {
                            points: res.points,
                            rules: res.rules
                        }
                    });

                    res.rules.forEach((rule, index) => {
                        if (res.points >= rule.from && (res.points <= rule.to || rule.to === null)) {
                            dispatch({
                                type: SET_CURRENT_RULE,
                                payload: {
                                    index: index,
                                    rule: rule
                                }
                            });
                        }
                    })
                }

                resolve && resolve();
            })
            .catch(err => console.log(err))

        /*return fetch(getDiscountRulesURL)
            .then(response => response.json())
            .then(res => {

                if (res.rules) {
                    dispatch({
                        type: FETCH_DISCOUNT_RULES,
                        payload: {
                            points: res.points,
                            rules: res.rules
                        }
                    });

                    res.rules.forEach((rule, index) => {
                        if (res.points >= rule.from && (res.points <= rule.to || rule.to === null)) {
                            dispatch({
                                type: SET_CURRENT_RULE,
                                payload: {
                                    index: index,
                                    rule: rule
                                }
                            });
                        }
                    })
                }

                resolve && resolve();

            }).catch(err => console.log(err))*/

    }
}

/** */
export function fetchDiscountList() {
    return function (dispatch) {
        return http
            .get(getDiscountListURL)
            .then(res => {
                if (res.length) {
                    res[0].isActive &&
                        dispatch({
                            type: FETCH_DISCOUNT_LIST,
                            payload: res[0].code
                        });
                }
            })
            .catch(err => console.log(err))

        /*return fetch(getDiscountListURL)
            .then(response => response.json())
            .then(res => {
                if (res.length) {
                    res[0].isActive &&
                        dispatch({
                            type: FETCH_DISCOUNT_LIST,
                            payload: res[0].code
                        });
                }

            }).catch(err => console.log(err))*/
    }
}

/** */
export function createDiscount(e) {
    e.preventDefault();

    return function (dispatch, getState) {
        const { dashboard: { loyalty } } = getState();

        if (loyalty.currentRule.rule.percent > 0) {
            return http
                .post(getDiscountListURL)
                .then(res => {
                    dispatch({
                        type: CREATE_DISCOUNT,
                        payload: res.code
                    });
                })
                .catch(err => console.log(err))

            /*return fetch(getDiscountListURL, { method: 'POST' })
                .then(response => response.json())
                .then(res => {
                    dispatch({
                        type: CREATE_DISCOUNT,
                        payload: res.code
                    });

                }).catch(err => console.log(err))*/

        } else {
            notify.warn("You don't have enough points to generate discount.");
        }
    }

}

/** */
export function fetchReferralLink(resolve) {
    return function (dispatch) {
        return http
            .get(referralLinkURL)
            .then(data => {
                console.log('DATA', data);
                dispatch({
                    type: FETCH_REFERRAL_LINK,
                    payload: data
                })

                resolve();
            })
            .catch(err => console.log(err))

        /*return fetch(referralLinkURL)
            .then(response => response.json())
            .then(data => {
                console.log('DATA', data);
                dispatch({
                    type: FETCH_REFERRAL_LINK,
                    payload: data
                })

                resolve();
            }).catch(err => console.log(err));*/
    }
}

/** */
export function fetchReferrals(resolve, idStatus) {
    return function (dispatch) {
        return http
            .get(referralsURL(idStatus))
            .then(data => {
                dispatch({
                    type: FETCH_REFERRALS,
                    payload: data
                })

                resolve && resolve();
            })
            .catch(err => console.log(err))

        /*return fetch(referralsURL(idStatus))
            .then(response => response.json())
            .then(data => {
                dispatch({
                    type: FETCH_REFERRALS,
                    payload: data
                })

                resolve && resolve();
            }).catch(err => console.log(err))*/
    }
}

/** */
export function getReadyDiscountsAmount(resolve) {
    return function (dispatch) {
        return http
            .get(referralsURL())
            .then(data => {
                if (data.length) {
                    const readyDiscountAmount = data.filter(item => (item.status.id === 3)).length;

                    dispatch({
                        type: GET_READY_REFERRALS,
                        payload: readyDiscountAmount
                    })
                }
                resolve && resolve();
            })
            .catch(err => console.log(err))

        /*return fetch(referralsURL())
            .then(response => response.json())
            .then(data => {

                if (data.length) {
                    const readyDiscountAmount = data.filter(item => (item.status.id === 3)).length;

                    dispatch({
                        type: GET_READY_REFERRALS,
                        payload: readyDiscountAmount
                    })
                }
                resolve && resolve();

            }).catch(err => console.log(err))*/
    }
}

/** */
export function generateRefDiscount(refId, statusId) {
    return function (dispatch) {
        if (statusId === 3) {
            return http
                .post(generateRefDiscountURL(refId))
                .then(data => {
                    if (data.id) {
                        dispatch({
                            type: REF_DISCOUNT_GENERATION_SUCCESS,
                            payload: data
                        })
                    }
                })
                .catch(({ message, body }) => {
                    if (body) {
                        body.errors?.length
                            ? console.error(body.errors.join(' '))
                            : console.log(body);

                    } else {
                        console.log(message);
                    }
                    notify.error('Problem occurred while generating your discount');
                })

            /*return fetch(generateRefDiscountURL(refId), { method: 'POST' })
                .then(response => response.json())
                .then(data => {

                    if (data.id) {
                        dispatch({
                            type: REF_DISCOUNT_GENERATION_SUCCESS,
                            payload: data
                        })

                    } else {
                        notify.error('Problem occurred while generating your discount');
                    }
                })
                .catch(err => console.log(err))*/

        }
    }
}

/** */
export function setReferralFilter(statusId) {
    return function (dispatch) {

        dispatch({
            type: SET_REFERRAL_FILTER,
            payload: +statusId
        });
    }
}