import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';

/**
 * @name -feedbackmodal/FeedbackRating
 * @component
 * @category Dashboard
 * @subcategory Components
 * @hideconstructor
 * @memberof FeedbackModal
 */
export default function FeedbackRating({ rating, config, onClick, onHover, onChange, readonly }) {
    const defaultEmptyElem = (
        <svg className="empty" width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
        </svg>
    );
    const defaultFullElem = (
        <svg className="full" width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z" />
        </svg>
    );

    return (
        <span className="feedback-rating">
            <Rating
                stop={config.stop}
                fractions="2"
                initialRating={rating}
                readonly={readonly}
                fullSymbol={defaultFullElem}
                emptySymbol={defaultEmptyElem}
                onChange={onChange}
                onClick={onClick}
                onHover={onHover}
            />
        </span>
    )
}

FeedbackRating.propTypes = {
    rating: PropTypes.number,
    config: PropTypes.shape({
        stop: PropTypes.number,
        fractions: PropTypes.number
    }),
    readonly: PropTypes.bool,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    onChange: PropTypes.func
}

FeedbackRating.defaultProps = {
    rating: 0,
    config: {
        stop: 5,
        fractions: 2
    }
}
