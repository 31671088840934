import React from 'react';

export default function PaymentwallIcon() {
    return (
        <svg viewBox="0 0 40 31">
            <path d="M36,31H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v23C40,29.2,38.2,31,36,31z" />
            <path fill="#FFC013" d="M24.8,22.4l1.5-4.2H32l1.5,4.2H24.8z M15.6,22.4l1.5-4.2h5.8l1.5,4.2H15.6z M20.2,17l1.5-4.2h5.7l1.5,4.2
                H20.2z M11,17l1.5-4.2h5.7l1.5,4.2H11z M6.4,22.4L8,18.2h5.7l1.5,4.2H6.4z M15.6,11.8l1.5-4.2h5.7l1.5,4.2H15.6z"/>
        </svg>
    )
}
