import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Toggle from './Toggle';
import Collapse from './Collapse';
import Item from './Item';

/**
 * @name Accordion
 * @component
 * @category Dashboard
 * @subcategory Components
 * @hideconstructor
 */
export default class Accordion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accordionItems: React.Children.map(this.props.children, (child, index) => {
                if (child.props.expanded) {
                    return {
                        id: index,
                        expanded: true
                    }

                } else {
                    return {
                        id: index,
                        expanded: false
                    }
                }
            })
        }
    }

    static propTypes = {
        className: PropTypes.string
    }
    static Item = Item;
    static Toggle = Toggle;
    static Collapse = Collapse;

    handleToggle = (itemIndex) => {
        this.setState(prevState => ({
            ...prevState,
            accordionItems: prevState.accordionItems.map((item, index) => {

                if (index === itemIndex) {
                    return {
                        ...item,
                        expanded: !item.expanded
                    }

                } else {
                    return {
                        ...item,
                        expanded: false
                    }
                }
            })
        }))
    }

    render() {
        const { className, children } = this.props;
        const { accordionItems } = this.state

        return (
            <ul
                className={`accordion ${className || ''}`}
                aria-label="Accordion Control Group Buttons"
            >
                {React.Children.map(children, (child, index) => {
                    if (child.type.displayName === 'Item') {
                        return React.cloneElement(child, {
                            index: index,
                            expanded: accordionItems[index].expanded,
                            handleToggle: this.handleToggle
                        })
                    }

                    return child;
                })}
            </ul>
        )
    }
}
