/**
 * @module RestoreActions
 */
import {
    SET_DEFAULT_VALUE,
    FIRST_STEP_VALIDATION,
    SECOND_STEP_VALIDATION,
    SET_WORK_TYPE,
    TRANSFER_DISCOUNT_CODE,
    DISABLE_TOP_WRITER,
    ENABLE_TOP_WRITER,
    DISABLE_SELECT_OPTIONS,
    DISABLE_EXTRA_QUALITY_CHECK,
    ENABLE_EXTRA_QUALITY_CHECK,
    DISABLE_ONE_PAGE_SUMMARY,
    ENABLE_ONE_PAGE_SUMMARY
} from 'store/orderform/orderformActionTypes';
import { validateSecondStep } from 'orderform/utils/orderformUtils';
import { silentDiscountCheck } from 'store/orderform/discount/discountActions';
import { shouldRender as getCategoryConfig } from 'orderform/utils/config';


// localName = legacy naming
const listConfig = {
    'urgency': { type: 'number', restore: true, restoreAlways: false },
    'pages': { type: 'string', restore: true, restoreAlways: false },
    'paperFormat': { type: 'number', restore: true, restoreAlways: false },
    'paperType': { type: 'number', restore: true, restoreAlways: false },
    'sources': { type: 'string', restore: true, restoreAlways: false },
    'spacing': { type: 'number', restore: true, restoreAlways: false, localName: 'id_spacing' },
    'subject': { type: 'number', restore: true, restoreAlways: false },
    'title': { type: 'string', restore: true, restoreAlways: false, localName: 'topic' },
    'email': { type: 'string', restore: true, restoreAlways: true },
    'workType': { type: 'string', restore: true, restoreAlways: false },
    'level': { type: 'number', restore: true, restoreAlways: false },
    'resumeService': { type: 'number', restore: true, restoreAlways: false },
    'discount': { type: 'string', restore: true, restoreAlways: false, localName: 'discountCode' },
    'topWriters': { type: 'number', restore: true, restoreAlways: false },
    'qualityFeature': { type: 'number', restore: true, restoreAlways: false },
    'extraqualityCheck': { type: 'number', restore: true, restoreAlways: false },
    'preferredWriters': { type: 'array', restore: true, restoreAlways: false, localName: 'preferredList' },
    'onePageSummary': { type: 'number', restore: true, restoreAlways: false },
    'anotherWriter': { type: 'string', restore: true, restoreAlways: false },
    'editing': { type: 'boolean', restore: true, restoreAlways: false, },
    'cloning': { type: 'boolean', restore: true, restoreAlways: false, },
    'paperDetails': { type: 'string', restore: true, restoreAlways: false, localName: 'description' },
    'credentials': { type: 'string', restore: true, restoreAlways: false, },
    'editingOrderId': { type: 'number', restore: true, restoreAlways: false, }
};

/** */
export function restoreOrderform(resolve) {
    return function (dispatch, getState) {
        const restoreData = {}

        const setValue = (item) => {
            const element = listConfig[item.element];
            const value = item.value;

            if (element && value && element.restore) {
                if (element.type === 'number') {
                    restoreData[item.element] = Number(value);
                } else if (element.type === 'string') {
                    restoreData[item.element] = decodeURI(value);
                } else if (element.type === 'array') {
                    restoreData[item.element] = value;
                } else if (element.type === 'boolean') {
                    restoreData[item.element] = !!value;
                }
            };

        }

        const transferDiscountCode = (discountCode) => {
            dispatch({
                type: TRANSFER_DISCOUNT_CODE,
                payload: discountCode
            });
            dispatch(silentDiscountCheck());
        }

        const checkValidData = (element, value) => {
            const { global: { siteCategory }, orderform: { workType, level, paperFormat, paperType, subject, resumeService, urgency, pages, sources, preferredWriters } } = getState();

            const checkValidOption = (nameArray) => {
                nameArray && nameArray.forEach(item => {
                    if (item.id === +value) {
                        setValue({ element, value });
                    }
                });
            }

            const checkValidNumberInput = (min, max) => {

                if (+value >= min && +value <= max) {
                    setValue({ element, value });

                } else if (+value < min) {
                    setValue({ element, value: min });

                } else if (+value > max) {
                    setValue({ element, value: max });
                }
            }

            const checkValidToggler = (name = null) => {
                if (+value === 1 || +value === 2) {
                    const nameField = name ? name : element;
                    setValue({ element: nameField, value })
                }
            }

            const checkValidPreferredWriters = (nameArray, name = null) => {
                const nameField = name ? name : element;
                const dinamicValue = typeof value === 'string' && value.split(',')
                let preferredList = [];

                for (let index = 0; index < dinamicValue.length; index++) {
                    const newValue = dinamicValue[index];

                    nameArray && nameArray.forEach(item => {
                        if (item.id === +newValue) {
                            preferredList.push(item.id);
                            setValue({ element: nameField, value: { list: preferredList, value: 2 } });
                        }
                    });
                }
            }

            switch (element) {
                case 'workType':
                    checkValidOption(workType.types);
                    break;
                case 'level':
                    checkValidOption(level.options);
                    break;
                case 'paperFormat':
                    checkValidOption(paperFormat.options);
                    break;
                case 'paperType':
                    checkValidOption(paperType.options);
                    break;
                case 'subject':
                    for (let index = 1; index < subject.options.length; index++) {
                        const arr = subject.options[index].subjects;
                        checkValidOption(arr);
                    }
                    break;
                case 'resumeService':
                    checkValidOption(resumeService.options);
                    break;
                case 'urgency':
                    checkValidOption(urgency.options);
                    break;
                case 'pages':
                    if (siteCategory === 3) {
                        pages.max = 10;
                    }
                    checkValidNumberInput(pages.min, pages.max);
                    break;
                case 'topic':
                    setValue({ element: 'title', value });
                    break;
                case 'description':
                    setValue({ element: 'paperDetails', value });
                    break;
                case 'paperDetails':
                    setValue({ element, value });
                    break;
                case 'sources':
                    checkValidNumberInput(sources.min, sources.max);
                    break;
                case 'topWriters':
                    checkValidToggler();
                    break;
                case 'extraqualityCheck':
                    checkValidToggler();
                    break;
                case 'qualityFeature':
                    checkValidToggler();
                    break;
                case 'preferredList':
                    checkValidPreferredWriters(preferredWriters.options, 'preferredWriters');
                    break;
                case 'onePageSummary':
                    checkValidToggler();
                    break;
                case 'spacing':
                    checkValidToggler();
                    break;
                case 'id_spacing':
                    checkValidToggler('spacing');
                    break;
                case 'discountCode':
                    setValue({ element: 'discount', value });
                    break;
                default:
                    setValue({ element, value })
            }
        }

        for (const key in listConfig) {
            const element = listConfig[key];
            const localKey = element.localName || key;
            let localValue;

            if (localStorage.getItem(localKey) !== null && localStorage.getItem(localKey) !== '') {
                localValue = localStorage.getItem(localKey);

            } else if (key === 'editing' || key === 'cloning' || key === 'editingOrderId') {
                continue;

            } else if (key === 'anotherWriter') {
                localValue = getState().orderform.preferredWriters.anotherWriter;

            } else {
                localValue = getState().global.predefaultState[key];
            }

            checkValidData(localKey, localValue);
        }

        const queryString = window.location.search;

        if (queryString) {
            const urlParams = new URLSearchParams(queryString)

            const entries = urlParams.entries()

            for (const entry of entries) {
                const params = { element: entry[0], value: entry[1] };

                if (params.value) {
                    switch (params.element) {
                        case 'ref_id':
                            restoreData.referralID = params.value;
                            break;
                        case 'discount':
                            restoreData.discount = params.value;
                            transferDiscountCode(params.value);
                            break;
                        case 'discountCode':
                            restoreData.discount = params.value;
                            transferDiscountCode(params.value);
                            break;
                        case '_ym_debug':
                            if (params.value === '1') {

                                console.log('%c Yandex debug', 'color: #fd1a03; font-size: 16px;');
                                restoreData.yandexDebug = true;
                            }
                            break;
                        default:
                            checkValidData(params.element, params.value);
                    }
                }
            }
        }

        const { global: { siteCategory, enableExtraqualityCheck, enableOnePageSummary } } = getState();

        if (siteCategory === 3) {
            dispatch({ type: FIRST_STEP_VALIDATION });
            restoreData.pagesMax = getCategoryConfig(siteCategory).pagesMax;

        } else if (siteCategory === 5) {
            restoreData.paperType = 71;
            dispatch({ type: FIRST_STEP_VALIDATION });
        } else if (!enableExtraqualityCheck) {
            restoreData.extraqualityCheck = 1;
        } else if (!enableOnePageSummary) {
            restoreData.onePageSummary = 1;
        }

        dispatch({
            type: SET_DEFAULT_VALUE,
            payload: restoreData
        })

        if (restoreData.discount) {
            transferDiscountCode(restoreData.discount);
        }

        if (restoreData.preferredWriters) {
            dispatch({ type: DISABLE_TOP_WRITER });

        } else {
            dispatch({ type: ENABLE_TOP_WRITER });
        }

        if (+restoreData.urgency < 24) {
            dispatch({ type: DISABLE_EXTRA_QUALITY_CHECK });

        } else {
            dispatch({ type: ENABLE_EXTRA_QUALITY_CHECK });
        }

        if (restoreData.workType === '3') {
            dispatch({
                type: SET_WORK_TYPE,
                payload: String(restoreData.workType)
            });
            dispatch({ type: DISABLE_ONE_PAGE_SUMMARY });

        } else {
            dispatch({ type: ENABLE_ONE_PAGE_SUMMARY });
        }

        if ([4, 5].includes(restoreData.level)) {
            dispatch({
                type: DISABLE_SELECT_OPTIONS + '_urgency',
                payload: [3, 6]
            });
        }

        validateSecondStep(SECOND_STEP_VALIDATION, dispatch, getState);

        resolve && resolve();
    }
}

/** */
export function clearLocalStorage() {
    for (const key in listConfig) {
        const element = listConfig[key];
        const localKey = element.localName || key;

        if (!element.restoreAlways) {
            localStorage.removeItem(localKey);
        }
    }
}