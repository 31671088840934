import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
// https://github.com/alinemorelli/react-gtm
// https://www.npmjs.com/package/react-gtm-module

export const GTM = () => {
  const gtmId = process.env.REACT_APP_GTM_CODE;

  useEffect(() => {
    if (!gtmId) return;

    TagManager.initialize({ gtmId });
    console.log(`🔴 GTM inited. ID`, gtmId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
