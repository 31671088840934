import { SET_USER_TYPE, USER_MAIL_EXISTS } from 'store/orderform/orderformActionTypes';

export const initialState = {
    name: 'userType',
    value: '11',
    types: [
        { id: 11, name: 'I am a new customer' },
        { id: 22, name: 'I have an account' }
    ],
    label: ''
}

export default function userTypeReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_USER_TYPE:
            return {
                ...state,
                value: payload
            };
        case USER_MAIL_EXISTS:
            return {
                ...state,
                value: '22'
            };

        default:
            return state;
    }
}