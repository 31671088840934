import { BASE_URL } from 'environmentVars';
class HTTP {
    _accessToken = undefined;

    constructor() {
        this._mapRequest = {
            'GET': this.get,
            'POST': this.post,
            'PUT': this.put,
            'DELETE': this.delete,
            'PATCH': this.patch
        }
    }

    get = async (url, params) => {
        return await this._makeFetchCall('GET', url, params);
    }

    post = async (url, params) => {
        return await this._makeFetchCall('POST', url, params);
    }

    put = async (url, params) => {
        return await this._makeFetchCall('PUT', url, params);
    }

    delete = async (url, params) => {
        return await this._makeFetchCall('DELETE', url, params);
    }

    patch = async (url, params) => {
        return await this._makeFetchCall('PATCH', url, params);
    }

    setAccessToken = (token) => {
        this._accessToken = `Bearer ${token}`;
    }

    _makeFetchCall = async (method, url, params = {}) => {
        // Check accessToken existance
        if (!this._accessToken) {
            const token = localStorage.getItem('accessToken');

            if (token) {
                this.setAccessToken(token);

            } else {
                this._logout();
                return Promise.reject(new Error('Access Token is missing')); // Temporary solution
            }
        }

        try {
            const { responseType, ...requestParams } = params;

            const res = await fetch(url, {
                ...requestParams,
                method: method,
                headers: {
                    ...requestParams.headers,
                    'Authorization': this._accessToken
                }
            });

            if ([401].includes(res.status)) {
                return await this._refreshAccessToken(method, url, params);
            }

            if ([400, 403, 404, 422].includes(res.status)) {
                // Error handler
                const errorBody = await res.json();
                const error = new Error();
                error.message = "Bad response from server";
                error.body = errorBody;

                throw error;
            }

            if (res.status >= 400 && res.status < 600) {
                throw new Error("Bad response from server");
            }

            // Process response
            if (responseType === 'blob') {
                return await res.blob();

            } else {
                return await res.json();
            }

        } catch (err) {
            return Promise.reject(err);
        }
    }

    _refreshAccessToken = async (method, url, params) => {
        const refreshUrl = `${BASE_URL}/api/en/auth/jwt/refresh`;
        const refreshToken = localStorage.getItem('refreshToken');

        if ([null, 'undefined'].includes(refreshToken)) return this._logout();

        try {
            const res = await fetch(refreshUrl, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${refreshToken}`
                }
            })

            if ([401].includes(res.status)) return this._logout();

            if ([400, 403, 404, 422].includes(res.status)) {
                // Error handler
                const errorBody = await res.json();
                const error = new Error();
                error.message = "Bad response from server";
                error.body = errorBody;

                throw error;
            }

            if (res.status >= 400 && res.status < 600) {
                throw new Error("Bad response from server");
            }

            const data = await res.json();

            this.setAccessToken(data.accessToken);

            data.accessToken && localStorage.setItem('accessToken', data.accessToken);
            data.refreshToken && localStorage.setItem('refreshToken', data.refreshToken);

            // console.log("%cREFRESH", 'color: violet;', url);

            // Repeat failed request
            return await this._mapRequest[method](url, params);

        } catch (err) {
            return Promise.reject(err);
        }
    }

    _logout = () => {
        // Check for orderform url
        const order = window.location.pathname.startsWith('/order') && !window.location.pathname.startsWith('/orderinfo');

        // Logout
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        if (order) {
            window.location.assign('/order?full');
            return;
        }

        window.location.assign('/login');
    }
}

const http = new HTTP();

export default http;