import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CalendarIcon from '../../../../components/img/CalendarIcon';
import WatchIcon from '../../../../components/img/WatchIcon';

import { setRevisionLevel } from '../../../../../store/dashboard/orderinfo/revisions/revisionActions';
import { getFormatedDate } from '../../../../../helpers/utils';

/**
 * @name -revisions/ThirdStep
 * @component
 * @category Dashboard
 * @subcategory Orderinfo
 * @hideconstructor
 * @memberof Revisions
 */
function ThirdStep({ levelsList, pickedRevisionLevel, setRevisionLevel }) {
    /** */
    const getRequiredTime = (revisionLevelId) => {
        let hours = 0;

        switch (revisionLevelId) {
            case 1:
                hours = 12;
                break;
            case 2:
                hours = 16;
                break;
            case 3:
                hours = 20;
                break;
            case 4:
                hours = 24;
                break;
            default:
                break;
        }

        return hours;
    }

    /** */
    const getETA = () => {
        const now = Date.now();
        const deadline = new Date(now + (getRequiredTime(pickedRevisionLevel) * 3600000));

        return {
            date: getFormatedDate(deadline, { month: 'long', day: 'numeric' }),
            time: getFormatedDate(deadline, { hour: 'numeric', minute: 'numeric', hourCycle: 'h23' })
        }
    }

    return (
        <>
            <h3 className="modal__title">Revision complexity level</h3>
            <p className="modal__text">
                Revision complexity level affects the time required to complete your revision. We Strongly recommended that you select proper revision level to ensure best outcome within the shortest time-frame.
            </p>
            <div className="modal__complexity">
                <div className="modal__complexity-left">
                    <ul className="levels">
                        {levelsList.map(level => (
                            <li className="levels__item" key={level.id}>
                                <input
                                    id={`revision-level-${level.id}`}
                                    className="levels__input"
                                    type="radio"
                                    checked={pickedRevisionLevel === level.id}
                                    name="revision-level"
                                    onChange={setRevisionLevel.bind(null, level.id)}
                                />
                                <label
                                    htmlFor={`revision-level-${level.id}`}
                                    className="levels__label"
                                >
                                    {level.name}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="modal__complexity-right">
                    <div className="complexity__time">
                        <span className="label">
                            <WatchIcon />
                            Time Required:
                        </span>
                        <span className="content">
                            {`${getRequiredTime(pickedRevisionLevel)}:00 hr(s)`}
                        </span>
                    </div>
                    <div className="complexity__eta">
                        <span className="label">
                            <CalendarIcon />
                            ETA:
                        </span>
                        <span className="content">
                            {getETA().date} at {getETA().time}
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

ThirdStep.propTypes = {
    levelsList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    })),
    pickedRevisionLevel: PropTypes.number,
    setRevisionLevel: PropTypes.func
}

const mapStateToProps = ({ dashboard }) => ({
    levelsList: dashboard.revisions.levelsList,
    pickedRevisionLevel: dashboard.revisions.pickedRevisionLevel
});

const mapDispatchToProps = {
    setRevisionLevel
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep);