import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Checkbox from '../../../../../orderform/components/checkbox/Checkbox';
import FormField from '../../../../../orderform/components/formfield/FormField';
import AddFile from '../AddFile';

import { setRevisionCategory, setRevisionDescription } from '../../../../../store/dashboard/orderinfo/revisions/revisionActions';
import { textFieldBlur, textFieldFocus } from '../../../../../store/orderform/textfield/textFieldActions';

/**
 * @name -revisions/SecondStep
 * @component
 * @category Dashboard
 * @subcategory Orderinfo
 * @hideconstructor
 * @memberof Revisions
 */
function SecondStep({ categoriesList, pickedRevisionCategory, revisionDescription, isFocused, setRevisionCategory, setRevisionDescription, textFieldBlur, textFieldFocus }) {
    return (
        <>
            <h3 className="modal__title">Specify the nature of the revision</h3>
            <p className="modal__text">
                To get a better understanding of the problems with your paper we need you to specify one or more revision categories. To do so check the boxes below
            </p>
            <ul className="modal__categories">
                {categoriesList.map(category => (
                    <li key={category.id}>
                        <Checkbox
                            id={`category-${category.id}`}
                            type="checkbox"
                            checked={pickedRevisionCategory.includes(category.id)}
                            toggleCheck={setRevisionCategory.bind(null, category.id)}
                            disabled={false}
                        >
                            <span>{category.name}</span>
                        </Checkbox>
                    </li>
                ))}
            </ul>

            <FormField
                className={`modal__description ${revisionDescription && !isFocused ? 'done' : ''}`}
                id="description"
                type="textarea"
                name="description"
                placeholder="Please provide more information about your case..."
                value={revisionDescription}
                disabled={false}
                handleChange={setRevisionDescription}
                handleBlur={textFieldBlur}
                handleFocus={textFieldFocus}
            />

            <AddFile />
        </>
    )
}

SecondStep.propTypes = {
    categoriesList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    })),
    pickedRevisionCategory: PropTypes.arrayOf(PropTypes.number),
    revisionDescription: PropTypes.string,
    isFocused: PropTypes.bool,
    setRevisionCategory: PropTypes.func,
    setRevisionDescription: PropTypes.func
}

const mapStateToProps = ({ dashboard }) => ({
    categoriesList: dashboard.revisions.categoriesList,
    pickedRevisionCategory: dashboard.revisions.pickedRevisionCategory,
    revisionDescription: dashboard.revisions.revisionDescription,
    isFocused: dashboard.revisions.isFocused
});

const mapDispatchToProps = {
    setRevisionCategory,
    setRevisionDescription,
    textFieldBlur,
    textFieldFocus
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondStep);