import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { addRevisionFile, clearRevisionFile } from '../../../../store/dashboard/orderinfo/revisions/revisionActions';
import FilesIcon from "../../../components/img/FilesIcon";

/**
 * @name -revisions/AddFile
 * @component
 * @category Dashboard
 * @subcategory Orderinfo
 * @hideconstructor
 * @memberof Revisions
 */
function AddFile({ file, validTypes, addRevisionFile, clearRevisionFile }) {
    const inputRef = useRef();

    return (
        <div className="file__wrapper">
            <div className="file__left">
                <button
                    className={`file__button ${file.uploadedFileName ? 'not-empty' : ''}`}
                    onClick={() => inputRef.current.click()}
                >
                    <FilesIcon/>
                    Attach File
                </button>
            </div>
            <div className="file__right">
                <input
                    id="revision-file"
                    className="file__input"
                    type="file"
                    accept={[...validTypes]}
                    ref={inputRef}
                    onChange={addRevisionFile}
                />
                {file && file.uploadedFileName &&
                    <span
                        className="file__name"
                        onClick={clearRevisionFile.bind(null, inputRef.current)}
                    >
                        <span className="file__delete"></span>
                        <span className="file__title">{file.uploadedFileName}</span>
                        &nbsp;-&nbsp;
                        <span>{file.uploadedFileSize}</span>
                    </span>
                }
            </div>
        </div>
    )
}

AddFile.propTypes = {
    file: PropTypes.object,
    validTypes: PropTypes.arrayOf(PropTypes.string),
    addRevisionFile: PropTypes.func,
    clearRevisionFile: PropTypes.func
}

const mapStateToProps = ({ dashboard }) => ({
    file: {
        uploadedFile: dashboard.revisions.uploadedFile,
        uploadedFileSize: dashboard.revisions.uploadedFileSize,
        uploadedFileName: dashboard.revisions.uploadedFileName,
    },
    validTypes: dashboard.revisions.validFileTypes,
});

const mapDispatchToProps = {
    addRevisionFile,
    clearRevisionFile
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFile);