import reducerLogic from 'store/orderform/textfield/textFieldReducer';

const initialState = {
    id: 'password',
    type: 'password',
    value: '',
    error: '',
    placeholder: 'Type in your password here',
    label: 'Password',
    tooltip: '',
    isRequired: true,
    isFocused: false
}

export default function emailReducer(state = initialState, action) {
    return reducerLogic(state, action);
}