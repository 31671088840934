import jwtDecode from 'jwt-decode';
import { axios } from '../helpers/axios';

export const tokenService = {
  setTokens,
  setAuthPageHeader,

  getAccessToken,
  decodeAccessToken,

  getRefreshToken,
  clearTokens,
};

function setAuthPageHeader(token) {
  console.log('Set "Authorization" header');
  axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : null;
  return token;
}

function setTokens({ accessToken, refreshToken }) {
  console.log('Set tokens. Access', !!accessToken, 'Refresh', !!refreshToken);
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    setAuthPageHeader(accessToken);
  }
  if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
}

function getAccessToken() {
  return localStorage.getItem('accessToken');
}

function getRefreshToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  console.log('Requested refresh token');
  // Also update header for refresh accessToken request [once]
  return setAuthPageHeader(refreshToken);
}

function decodeAccessToken() {
  const accessToken = getAccessToken();
  if (!accessToken) return; // check exists
  try {
    const decodedToken = jwtDecode(accessToken);
    const { user_claims, identity } = decodedToken;

    // Adapt data
    const result = { ...user_claims, identity };
    return result;
  } catch (error) {
    console.error(error);
  }
  return {};
}

function clearTokens() {
  setAuthPageHeader();
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
}
