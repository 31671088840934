import {
    SET_TEXTFIELD_VALUE,
    SET_TEXTFIELD_BLUR,
    SET_TEXTFIELD_FOCUS,
    SECOND_STEP_VALIDATION,
    SET_WORK_TYPE
} from 'store/orderform/orderformActionTypes';

export default function reducerLogic(state = {}, { type, payload }) {
    switch (type) {
        case SET_TEXTFIELD_VALUE + '_' + state.id:
            return {
                ...state,
                value: payload
            };
        case SET_TEXTFIELD_BLUR + '_' + state.id:
            return {
                ...state,
                isFocused: false,
                error: payload
            };
        case SET_TEXTFIELD_FOCUS + '_' + state.id:
            return {
                ...state,
                isFocused: true
            };

        case SET_WORK_TYPE:
            return {
                ...state,
                error: payload === '2' ? '' : state.error
            }

        case SECOND_STEP_VALIDATION:
            return {
                ...state,
                error: payload ? '' : state.error
            }

        default:
            return state;
    }
}