/**
 * @module OrderMessengerActions
 */
import {
    FETCH_MESSAGE_HISTORY,
    SET_UNREAD_COUNT,
    TOGGLE_CURRENT_CHAT,
    SET_ORDER_MESSAGE_VALUE,
    PUSH_MESSAGE,
    GO_NEW_LINE,
    SET_CURRENT_CHAT,
    RESET_CHAT,
    CLEAR_SEND_QUEUE_FILES,
    ADD_FILE_TO_SEND_QUEUE,
    CLICK_ON_SEND_QUEUE_FILE_INPUT,
    UPLOAD_SEND_QUEUE_FILE,
    UPLOAD_SEND_QUEUE_FILE_ERROR,
    DELETE_SEND_QUEUE_FILE,
    SET_TOTAL_UNREAD_COUNT,
    READ_MESSAGES,
    UPDATE_CHAT_HISTORY,
    UPDATE_CHAT,
    APPEND_MESSAGE,
    UPDATE_UNREAD,
    SET_UNREAD_LIST,
    LOCK,
    CLEAR_BEFORE_HISTORY_FETCH,
    SET_ONLINE_STATUS,
    SET_EMOJI,
    TOGGLE_TYPE
} from 'store/actionTypes';
import { notify } from 'helpers/notification';
import http from 'services/http';
import { orderMessengerURL, orderMessengerUnreadURL, orderMessengerReadURL, orderMessengerUnreadListURL } from 'store/config';
import { UNLOCK } from '../../../actionTypes';
import WS from 'services/ws';
import { emojiList } from 'store/config';

export const DIRECTION = {
    SUPPORT_TO_CUSTOMER: 6,
    WRITER_TO_CUSTOMER: 3,
    CUSTOMER_TO_SUPPORT: 2,
    CUSTOMER_TO_WRITER: 1,
}

export const MESSAGE_STATE = {
    UNREAD: 1,
    READ: 2
}

export const MESSAGE_STATUS = {
    NEW: 1,
    APPROVED: 2,
    EDITED: 3,
    DELETED: 4,
    FORWARDED: 5
}

/** */
export function scrollToLast(currentChat, elem) {
    return function (dispatch) {
        const container = document.getElementById(`order-messenger-chat-${currentChat}`);

        if (!container) return;

        let top;

        if (elem) {
            const margin = 15;
            top = elem.offsetTop - margin; // Parent must have 'position: relative'

        } else {
            top = container.scrollHeight;
        }

        setTimeout(() => {
            container && container.scrollTo({ top: top, behavior: "smooth" });
        }, 0);

        if (container.scrollHeight > container.offsetHeight) {
            return true;
        }
    }
}

/** Get total unread by order */
export function fetchUnreadCount(orderId) {
    return function (dispatch, getState) {
        const { global: { orderMessengerApiURL } } = getState();

        return http
            .get(orderMessengerUnreadURL(orderMessengerApiURL, orderId))
            .then(data => {
                // console.log('Unread count', data);
                dispatch({
                    type: SET_TOTAL_UNREAD_COUNT,
                    payload: data.total
                })
            })
            .catch(err => console.log(err))
    }
}

/** Get all unread counters */
export function fetchUnreadCountList() {
    return function (dispatch, getState) {
        const { global: { orderMessengerApiURL } } = getState();

        return http
            .get(orderMessengerUnreadListURL(orderMessengerApiURL))
            .then(data => {
                // console.log('Unread List count', data);
                dispatch({
                    type: SET_UNREAD_LIST,
                    payload: data
                })
            })
            .catch(err => console.log(err))
    }
}

/** */
export function updateUnreadCount(data) {
    return function (dispatch, getState) {
        const { dashboard: { orderinfo } } = getState();

        if (data[orderinfo.order.id]) {
            dispatch({
                type: UPDATE_UNREAD,
                payload: data[orderinfo.order.id]
            });
        }

        dispatch({
            type: SET_UNREAD_LIST,
            payload: data
        })
    }
}

/** Helper function */
const chooseCurrentChat = (order, unreadMessagesObj, dispatch) => {
    let chatName;

    if (!order.idWriter) {
        chatName = 'support';

    } else if (unreadMessagesObj.support && !unreadMessagesObj.writer) {
        chatName = 'support';

    } else if (window.location.search) {
        const urlParam = new URLSearchParams(window.location.search);

        urlParam.get('panel') === 'support' && (chatName = 'support');
    }

    chatName === 'support' && exportFunctions.setCurrentChat(chatName)(dispatch);
}

/** */
export function fetchMessageHistory(orderId) {
    return function (dispatch, getState) {
        const { dashboard: { orderinfo }, global: { orderMessengerApiURL } } = getState();

        return http
            .get(orderMessengerURL(orderMessengerApiURL, orderId))
            .then(messagesList => {
                // console.log('messagesList', messagesList);

                const preparedChat = {
                    support: [],
                    writer: []
                }
                const unreadCount = {
                    support: 0,
                    writer: 0
                }

                const sortChatByDate = ((a, b) => {
                    return new Date(a.dateCreated.replace(/-/g, '/')) - new Date(b.dateCreated.replace(/-/g, '/'))
                });

                const checkFirstUnread = (arr) => {
                    const unreadIndex = arr.findIndex(item => {
                        // Check writer/support unread messages 
                        return [DIRECTION.SUPPORT_TO_CUSTOMER, DIRECTION.WRITER_TO_CUSTOMER].includes(item.direction.id) && item.state.id === MESSAGE_STATE.UNREAD;
                    })

                    if (unreadIndex !== -1) {
                        // Add divider
                        arr.splice(unreadIndex, 0, { divider: true });
                    }
                }

                messagesList.length && messagesList.forEach(message => {
                    if ([DIRECTION.CUSTOMER_TO_SUPPORT, DIRECTION.SUPPORT_TO_CUSTOMER].includes(message.direction.id)) {
                        preparedChat['support'].push(message);
                        message.state.id === MESSAGE_STATE.UNREAD
                            && message.direction.id === DIRECTION.SUPPORT_TO_CUSTOMER
                            && ++unreadCount['support'];
                    }

                    if ([DIRECTION.CUSTOMER_TO_WRITER, DIRECTION.WRITER_TO_CUSTOMER].includes(message.direction.id)) {
                        preparedChat['writer'].push(message);
                        message.state.id === MESSAGE_STATE.UNREAD
                            && message.direction.id === DIRECTION.WRITER_TO_CUSTOMER
                            && ++unreadCount['writer'];
                    }
                })

                // Preset current chat before render
                exportFunctions.chooseCurrentChat(orderinfo.order, unreadCount, dispatch);

                // Add divider before unread messages
                for (const key in preparedChat) {
                    if (preparedChat.hasOwnProperty(key)) {
                        preparedChat[key] = preparedChat[key].sort(sortChatByDate);
                        checkFirstUnread(preparedChat[key]);
                    }
                }

                dispatch({
                    type: SET_UNREAD_COUNT,
                    payload: {
                        supportCount: unreadCount.support,
                        writerCount: unreadCount.writer
                    }
                })

                dispatch({ type: CLEAR_BEFORE_HISTORY_FETCH })

                dispatch({
                    type: FETCH_MESSAGE_HISTORY,
                    payload: {
                        supportChat: preparedChat.support,
                        writerChat: preparedChat.writer
                    }
                })
            })
            .catch(err => console.log(err))
    }
}

/** */
export function toggleCurrentChat() {
    return function (dispatch) {
        dispatch({ type: TOGGLE_CURRENT_CHAT });
    }
}

/** */
export function setCurrentChat(label) {
    return function (dispatch) {
        dispatch({
            type: SET_CURRENT_CHAT,
            payload: label
        })
    }
}

/** */
export function setOnlineStatus(isOnline) {
    return function (dispatch) {
        dispatch({
            type: SET_ONLINE_STATUS,
            payload: isOnline
        })
    }
}

/** */
export function setMessageValue(e) {
    return function (dispatch) {
        if (e.target.value.length > 1500) {
            notify.warn('Message cannot be more than 1500 characters long.');
            return;
        }

        dispatch({
            type: SET_ORDER_MESSAGE_VALUE,
            payload: e.target.value
        })
    }
}

/** */
export function updateMessage(message) {
    return function (dispatch, getState) {
        const { dashboard: { orderinfo, ordermessenger } } = getState();

        const incommingOrderId = message.order.id;
        const currentOrderId = orderinfo.order.id;
        const incommingMessageId = message.id;
        const incommingChatName = [DIRECTION.CUSTOMER_TO_SUPPORT, DIRECTION.SUPPORT_TO_CUSTOMER].includes(message.direction.id) ? 'support' : 'writer';

        if (incommingOrderId !== currentOrderId) return;

        const checkChat = () => {
            const chat = [...ordermessenger.chat.support, ...ordermessenger.chat.writer];
            // console.log('chat', chat)
            const match = chat.find(item => item.id === incommingMessageId);

            if (match) {
                dispatch({
                    type: UPDATE_CHAT,
                    payload: {
                        message,
                        incommingMessageId,
                        incommingChatName
                    }
                })

            } else {
                dispatch({
                    type: APPEND_MESSAGE,
                    payload: {
                        message,
                        incommingChatName
                    }
                })
            }
        }

        const checkChatHistory = () => {
            const chat = [...ordermessenger.chatHistory.support, ...ordermessenger.chatHistory.writer];
            // console.log('HISTORY chat', chat)
            const match = chat.find(item => item.id === incommingMessageId);

            if (match) {
                dispatch({
                    type: UPDATE_CHAT_HISTORY,
                    payload: {
                        message,
                        incommingMessageId,
                        incommingChatName
                    }
                })

                return;
            }

            checkChat();
        }

        checkChatHistory();

        exportFunctions.toggleType(currentOrderId, false)(dispatch, getState);

        if (message.direction.id === DIRECTION.WRITER_TO_CUSTOMER) exportFunctions.setOnlineStatus(true)(dispatch);

        if (ordermessenger?.currentChat === incommingChatName) {
            dispatch(exportFunctions.scrollToLast(ordermessenger?.currentChat));
        }
    }
}

/** */
export function pushMessage() {
    return function (dispatch, getState) {
        const { dashboard: { ordermessenger, orderinfo }, global: { orderMessengerApiURL } } = getState();

        // emojiList

        const orderId = orderinfo.order?.id;
        const messageValue = ordermessenger.messageValue;
        const fileObjectsList = ordermessenger.fileObjects;

        const hasSingleEmoji = emojiList.some(item => item.img.trim() === messageValue.trim());
        // console.log('SingleEmoji', hasSingleEmoji)

        if (hasSingleEmoji && !fileObjectsList.length) {
            exportFunctions.pushSticker('emoji', { currentTarget: { textContent: messageValue } })(dispatch, getState);
            dispatch({
                type: PUSH_MESSAGE
            });

        } else {
            const messageBody = messageValue.split('\n').filter(item => item?.trim() !== '').join('\n');
            const formData = new FormData();

            formData.append('direction', ordermessenger?.currentChat === 'writer'
                ? DIRECTION.CUSTOMER_TO_WRITER
                : DIRECTION.CUSTOMER_TO_SUPPORT);
            formData.append('message', JSON.stringify({ body: messageBody.replace(/(<([^>]+)>)/ig, "") }));

            ordermessenger.fileObjects.forEach(item => {
                formData.append('files', item.fileObj);
            })

            if (!messageValue?.trim() && !fileObjectsList.length) return;

            // ordermessenger.fileObjects.length && notify.wait('Uploading...');
            dispatch({ type: LOCK });

            dispatch(exportFunctions.scrollToLast(ordermessenger?.currentChat));

            return http
                .post(orderMessengerURL(orderMessengerApiURL, orderId), { body: formData })
                .then(message => {
                    // console.log(message);

                    exportFunctions.updateMessage(message)(dispatch, getState);

                    dispatch({
                        type: PUSH_MESSAGE,
                        // payload: message
                    });
                    // dispatch(exportFunctions.scrollToLast(ordermessenger?.currentChat));
                })
                .catch(({ message, body }) => {
                    if (body) {
                        console.log(body);
                        notify.error(body.details);

                    } else {
                        console.log(message);
                        notify.error(message);
                    }
                })
                .finally(() => {
                    // ordermessenger.fileObjects.length && notify.hide();
                    dispatch({ type: UNLOCK });
                })
        }



        // const now = new Date().toISOString().replace(/T/, ' ').substring(0, 19);

        // const messageObject = {
        //     dateCreated: now,
        //     direction: {
        //         id: ordermessenger?.currentChat === 'writer' ? 1 : 2,
        //     },
        //     state: {
        //         id: 1,
        //         name: "Unread"
        //     },
        //     order: {
        //         id: orderId,
        //     },
        // uniqueId : fhjasdopu17ga;lskdj
        //     data: {
        //         'body': ordermessenger?.messageValue.split('\n').filter(item => item.trim() !== '').join('\n'),
        //         'sticker': null,
        //         'emoji': null,
        //         'files': null
        //     },
        // }
    }
}

export function setEmoji({ currentTarget }) {
    return function (dispatch) {
        dispatch({
            type: SET_EMOJI,
            payload: currentTarget.textContent.trim()
        });

        const textarea = document.querySelector('.sender__textarea');

        textarea && textarea.focus();
    }
}

/** */
export function pushSticker(type, { currentTarget }) {
    return function (dispatch, getState) {
        const { dashboard: { ordermessenger, orderinfo }, global: { orderMessengerApiURL } } = getState();

        const orderId = orderinfo?.order?.id;
        const formData = new FormData();

        formData.append('direction', ordermessenger?.currentChat === 'writer' ? DIRECTION.CUSTOMER_TO_WRITER : DIRECTION.CUSTOMER_TO_SUPPORT);
        formData.append('message', JSON.stringify({
            [type]: type === 'sticker' ? currentTarget.dataset.id : currentTarget.textContent
        }))

        dispatch({ type: LOCK });
        dispatch(exportFunctions.scrollToLast(ordermessenger?.currentChat));

        return http
            .post(orderMessengerURL(orderMessengerApiURL, orderId), { body: formData })
            .then(message => {
                // console.log(message);

                exportFunctions.updateMessage(message)(dispatch, getState);

                // dispatch({
                //     type: PUSH_STICKER,
                //     payload: message
                // });
                // dispatch(exportFunctions.scrollToLast(ordermessenger?.currentChat));
            })
            .catch(err => console.log(err))
            .finally(() => dispatch({ type: UNLOCK }))


        // const now = new Date().toISOString().replace(/T/, ' ').substring(0, 19);

        // const messageObject = {
        //     dateCreated: now,
        //     direction: {
        //         id: ordermessenger?.currentChat === 'writer' ? 1 : 2,
        //     },
        //     state: {
        //         id: 1,
        //         name: "Unread"
        //     },
        //     order: {
        //         id: orderId,
        //     },
        //     data: {
        //         'body': '',
        //         'sticker': type === 'sticker' ? +currentTarget.dataset.id : null,
        //         'emoji': type === 'emoji' ? currentTarget.textContent : null,
        //         'files': null
        //     },
        // }
    }
}

/** */
export function sendReadState(readMessagesId, unreadListId, unreadList, sender, observer) {
    return function (dispatch, getState) {
        const { dashboard: { orderinfo }, global: { orderMessengerApiURL } } = getState();

        const orderId = orderinfo.order?.id;

        // Handle missed values on IntersectionObserver fail
        const lastRead = readMessagesId.sort((a, b) => a - b)[readMessagesId.length - 1];
        const lastIndex = unreadListId.findIndex(item => item === lastRead)
        const readList = unreadListId.splice(0, lastIndex + 1);

        unreadList.forEach(item => {
            if (readList.includes(+item.dataset.messageId)) {
                item.classList.remove('unread');
                observer.unobserve(item);
            }
        })

        return http
            .put(orderMessengerReadURL(orderMessengerApiURL, orderId), {
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ messages: readList.join() })
            })
            .then(data => {
                dispatch({
                    type: READ_MESSAGES,
                    payload: {
                        readMessagesId: data.messages.split(',').map(i => +i),
                        count: data.unread,
                        sender
                    }
                })
            })
            .catch(err => console.log(err))
    }
}

/** */
export function handleKeyPress(mobile, e) {
    e.persist();

    return function (dispatch, getState) {
        const { global } = getState();

        if (global.locked) return;

        if (mobile) return;

        if ((e.keyCode === 10 || e.keyCode === 13) && e.shiftKey) {
            return;
        }

        // Go curson new line
        if (((e.keyCode === 10 || e.keyCode === 13) && e.ctrlKey) ||
            // ((e.keyCode === 10 || e.keyCode === 13) && e.metaKey) || // Does not work for now
            ((e.keyCode === 10 || e.keyCode === 13) && e.altKey)) {
            dispatch({ type: GO_NEW_LINE });

            return;
        }
        // Send message
        if ((e.keyCode === 10 || e.keyCode === 13)) {
            e.preventDefault();
            dispatch(exportFunctions.pushMessage());
        }
    }
}

/** */
export function typing(state, setIsTyping) {
    return function (dispatch, getState) {
        const { dashboard } = getState();

        const currentChat = dashboard.ordermessenger.currentChat;
        const orderId = dashboard.orderinfo.order?.id;
        const customerId = dashboard.profile.customer?.id;

        WS.typing(currentChat, orderId, customerId, state);
        setIsTyping && setIsTyping(false);
    }
}

/** */
export function toggleType(orderId, state) {
    return function (dispatch, getState) {
        const { dashboard: { orderinfo, ordermessenger } } = getState();

        const currentOrderId = orderinfo.order?.id;

        if (currentOrderId !== orderId) return;

        dispatch({
            type: TOGGLE_TYPE,
            payload: {
                name: ordermessenger.currentChat,
                state
            }
        })
    }
}

/** */
export function resetChat() {
    return function (dispatch) {
        dispatch({ type: RESET_CHAT });
    }
}

// =============== Files =====================

/** */
export function addFileToSendQueue(e) {
    return function (dispatch) {
        e.preventDefault();
        e.target.blur();

        dispatch({
            type: CLEAR_SEND_QUEUE_FILES
        })

        dispatch({
            type: ADD_FILE_TO_SEND_QUEUE,
            payload: {
                id: `id_${Date.now()}`
            }
        });
    }
}

/** */
export function clickOnSendQueueFile(id) {
    return function (dispatch) {
        dispatch({
            type: CLICK_ON_SEND_QUEUE_FILE_INPUT,
            payload: {
                id: id
            }
        });
    }
}

/** */
export function uploadSendQueueFile(e) {
    return function (dispatch, getState) {
        const { dashboard: { ordermessenger: { validTypes } } } = getState();
        let fileSize = e.target.files[0].size,
            calcSize;

        function formatSize(size, unit) {
            return (
                calcSize = ((fileSize / size) % 1 === 0) ?
                    `${fileSize / size}${unit}` :
                    `${(fileSize / size).toFixed(1)}${unit}`
            )
        }

        function fileValidation(validTypes, fileName) {
            const extention = fileName && fileName.toLowerCase().substring(fileName.lastIndexOf('.'), fileName.length);
            const result = extention && validTypes.some((item) => item === extention);
            return !!result;
        }

        if (!fileValidation(validTypes, e.target.files[0].name)) {
            dispatch({
                type: UPLOAD_SEND_QUEUE_FILE_ERROR,
                payload: { id: e.target.id }
            })

            notify.warn(`${validTypes.join(', ')}`, 'Invalid file format. Acceptable file formats:');
            return;
        }

        if (fileSize > 15728640) {
            dispatch({
                type: UPLOAD_SEND_QUEUE_FILE_ERROR,
                payload: { id: e.target.id }
            })

            notify.warn('Maximum file size - 15MB')
            return;
        }

        if (fileSize < 1024) {
            calcSize = `${fileSize}b`;

        } else if (fileSize < 1048576) {
            formatSize(1024, 'kb');

        } else {
            formatSize(1048576, 'mb');
        }

        dispatch({
            type: UPLOAD_SEND_QUEUE_FILE,
            payload: {
                id: e.target.id,
                fileObj: e.target.files[0],
                fileValue: e.target.value,
                fileName: e.target.files[0].name,
                fileSize: calcSize
            }
        });
    }
}

/** */
export function deleteSendQueueFile(id, e) {
    return function (dispatch) {
        dispatch({
            type: DELETE_SEND_QUEUE_FILE,
            payload: { id }
        });
    }
}

// ===========================================

// This solution is specially for testing
const exportFunctions = {
    scrollToLast,
    fetchUnreadCount,
    fetchUnreadCountList,
    updateUnreadCount,
    chooseCurrentChat,
    fetchMessageHistory,
    toggleCurrentChat,
    setCurrentChat,
    setOnlineStatus,
    setMessageValue,
    updateMessage,
    pushMessage,
    pushSticker,
    sendReadState,
    setEmoji,
    handleKeyPress,
    typing,
    toggleType,
    resetChat,
    addFileToSendQueue,
    clickOnSendQueueFile,
    uploadSendQueueFile,
    deleteSendQueueFile
}

export default exportFunctions;