import { combineReducers } from 'redux';

import dashboardReducer from './dashboard/dashboardReducer';
import globalReducer from './global/globalReducer';
import orderformReducer from './orderform/orderformReducer';

const rootReducer = combineReducers({
    global: globalReducer,
    dashboard: dashboardReducer,
    orderform: orderformReducer
});

export default rootReducer;