// Documentation of "react-notifications-component"
// Here: https://www.npmjs.com/package/react-notifications-component

import ReactNotification, { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const defaults = {
  title: '',
  message: '',
  type: 'info',
  insert: 'bottom',
  container: 'top-right',
  animationIn: ['notifyShow'],
  animationOut: ['notifyHide'],
  // width: 200,
  dismiss: {
    duration: 3000,
    onScreen: true,
    pauseOnHover: true,
  },
  touchSlidingExit: {
    swipe: {
      duration: 1000,
      timingFunction: 'ease-out',
      delay: 0,
    },
    fade: {
      duration: 1000,
      timingFunction: 'ease-out',
      delay: 0,
    },
  },
};

const notify = (function() {
  let _lastId;

  const show = (customs) => {
    _lastId = store.addNotification({
      ...defaults,
      ...customs,
    });
  };

  const success = (customs) => {
    _lastId = store.addNotification({
      ...defaults,
      ...customs,
      type: 'success',
    });
  };
  const error = (customs) => {
    _lastId = store.addNotification({
      ...defaults,
      ...customs,
      type: 'danger',
    });
  };
  const warning = (customs) => {
    _lastId = store.addNotification({
      ...defaults,
      ...customs,
      type: 'warning',
    });
  };

  const hide = () => {
    if (_lastId) store.removeNotification(_lastId);
  };
  return {
    hide,
    show,
    success,
    error,
    warning,
  };
})();

export { ReactNotification as Notification, notify }