import React from 'react';

export default function PaycoreIcon() {
    return (
        <svg viewBox="0 0 40 31">
            <path fill="#0047BB" d="M36,31H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h32c2.2,0,4,1.8,4,4v23C40,29.2,38.2,31,36,31z" />
            <g>
                <path fill="#F0B000" d="M23.9,10.5c0.6,0,1.1,0.5,1.1,1.1v7.7c0,0.6-0.5,1.1-1.1,1.1h-7.7c-0.6,0-1.1-0.5-1.1-1.1v-7.7
                    c0-0.6,0.5-1.1,1.1-1.1H23.9 M23.9,8.5h-7.7c-1.7,0-3.1,1.4-3.1,3.1v7.7c0,1.7,1.4,3.1,3.1,3.1h7.7c1.7,0,3.1-1.4,3.1-3.1v-7.7
                    C27,9.9,25.6,8.5,23.9,8.5L23.9,8.5z"/>
                <rect x="18.9" y="14.4" fill="#FFFFFF" width="2.1" height="2.1" />
            </g>
        </svg>
    )
}
