import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * @name -accordion/Collapse
 * @component
 * @category Dashboard
 * @subcategory Components
 * @hideconstructor
 * @memberof Accordion
 */
export default function Collapse({ index, className, expanded, children }) {
    const collapseRef = useRef();
    const hasContent = expanded && collapseRef?.current?.offsetHeight > 0;
    const [height, setHeight] = useState();

    useEffect(() => {
        if (expanded && collapseRef.current.offsetHeight > 0) {
            setHeight(collapseRef.current.offsetHeight);

        } else {
            setHeight(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasContent])

    useLayoutEffect(() => {
        function updateHeight() {
            if (expanded) {
                setHeight(collapseRef.current.offsetHeight);

            } else {
                setHeight(0);
            }
        }

        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    })

    return (
        <div
            id={`content-${index + 1}`}
            className={`accordion__collapse ${className || ''} ${expanded ? 'expanded' : ''}`}
            aria-hidden={!expanded}
            style={{
                height: `${height}px`,
                overflow: 'hidden',
                transition: '0.25s'
            }}
        >
            <div
                ref={collapseRef}
                className="accordion__collapse-container"
            >
                {children}
            </div>
        </div>
    )
}

Collapse.propTypes = {
    index: PropTypes.number,
    className: PropTypes.string,
    expanded: PropTypes.bool
}

Collapse.displayName = "Collapse";